import { Box, Divider, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  fetchPlaceDetails,
  updateMailingAddressInFormValues,
} from 'src/Components/Application/CommonAddress'
import { Name, ZipcodeField } from 'src/Components/Fields'
import { ContactInformationType } from 'src/Redux/Services/Types/applicationType'
import Style from '../../index.module.scss'
import { HasAssistant } from '../../..'

interface Place {
  id: string
  description: string
}
const itemStyle = {
  xs: 12,
  sm: 12,
  md: 12,
}

const marginStyle = {
  mr: { md: 2, xs: 2 },
}

const MailingAddress = ({
  formValues,
  setFormValues,
  errors,
  handleFieldChange,
}: {
  formValues: ContactInformationType
  setFormValues: React.Dispatch<React.SetStateAction<ContactInformationType>>
  errors: Record<string, string>
  handleFieldChange: ({ name, value }: { name: string; value: string }) => void
}) => {
  const [predictions, setPredictions] = useState<Place[]>([])

  const autocomplete = (query: string) => {
    if (query.length === 0) {
      setPredictions([])
      return
    }

    const autocompleteService = new google.maps.places.AutocompleteService()

    autocompleteService.getPlacePredictions(
      { input: query },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const placePredictions: Place[] = predictions.map((prediction) => {
            return {
              description: prediction.description,
              id: prediction.place_id,
            }
          })
          setPredictions(placePredictions)
        }
      },
    )
  }

  const handlePredictionClick = (prediction: Place) => {
    fetchPlaceDetails(
      prediction.id,
      formValues.mailingAddress,
      (mailingAddressData) => {
        updateMailingAddressInFormValues(
          formValues,
          mailingAddressData,
          setFormValues,
        )
      },
    )
    setPredictions([])
  }

  const handleAddressChange = (data: {
    name: string
    value: string | number
  }) => {
    const { name, value } = data
    if (typeof value === 'string' && value.length) {
      autocomplete(value) // Ensure this function is not causing issues
    } else {
      // setFormValues(initialObj);
      setPredictions([])
    }
    setFormValues({
      ...formValues,
      mailingAddress: {
        ...formValues?.mailingAddress,
        [name]: value,
      },
    })
  }

  useEffect(() => {
    if (!formValues?.mailingAddress?.streetAddress) {
      setPredictions([])
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        mailingAddress: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
      }))
    }
  }, [formValues.mailingAddress?.streetAddress])

  return (
    <Box>
      <Typography className={Style.label}>
        We completely understand! Let’s keep
        {HasAssistant() ? " applicant's" : ' your'} communications private.
        What's
        {HasAssistant() ? " applicant's" : ' your'} preferred mailing address?
      </Typography>
      <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
        <Grid width={'100%'}>
          <Name
            title='Street Address'
            name='streetAddress'
            value={formValues?.mailingAddress?.streetAddress}
            handleChange={handleAddressChange}
            error={errors.address2}
          />
          {predictions.length ? (
            <Grid item md={12} xs={12} className='form-row-item'>
              <Grid
                item
                {...itemStyle}
                {...marginStyle}
                className={Style.listBox}
              >
                {predictions.map((prediction, index) => (
                  <>
                    <div
                      key={index}
                      className={Style.listItem}
                      onClick={() => {
                        handlePredictionClick(prediction)
                      }}
                    >
                      {prediction.description}
                    </div>
                    <Divider />
                  </>
                ))}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <Name
          title='City/Town'
          name='city'
          value={formValues?.mailingAddress?.city}
          handleChange={handleAddressChange}
          error={errors.city2}
        />
      </Grid>
      <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
        <Name
          title='State'
          name='state'
          value={formValues?.mailingAddress?.state}
          handleChange={handleAddressChange}
          error={errors.state2}
        />
        <ZipcodeField
          postalCode={formValues.mailingAddress?.postalCode ?? ''}
          handleChange={handleFieldChange}
          error={errors?.postalCode2}
        />
        <Name
          title='Country'
          name='country'
          value={formValues?.mailingAddress?.country}
          handleChange={handleAddressChange}
          error={errors.country2}
        />
      </Grid>
    </Box>
  )
}

export default MailingAddress
