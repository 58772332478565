import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import Styles from './index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { hasEmpty } from 'src/Utils/Constants'
interface ViewScreenProps {
  onEditClick: () => void
  ref: React.RefObject<HTMLDivElement>
}

const ReceivingDisability: React.FC<ViewScreenProps> = ({
  onEditClick,
  ref: receivingDisabilityRef,
}) => {
  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.LegalDetails?.ReceivingDisability,
  )
  const receivingDisabilityBenefitPaymentsSectionId = useAppSelector(
    (state) =>
      state?.steps?.LegalDocApplicationSteps?.find(
        (step: { label: string }) =>
          step.label === 'Receiving Disability Benefit Payments',
      )?.sectionId,
  )
  return (
    <div
      id={receivingDisabilityBenefitPaymentsSectionId}
      ref={receivingDisabilityRef}
    >
      {/* Receiving Disability Benefit Payments */}
      <Box className={Styles.MainBox}>
        <Box className={Styles.title}>
          <Box className={Styles.main}>
            <Typography variant='h6' className={Styles.textWrapper}>
              Receiving Disability Benefit Payments
            </Typography>
            <Button
              onClick={onEditClick}
              disabled={hasEmpty(data)}
              className={Styles.button}
            >
              <Typography className={Styles.div}>Edit</Typography>
            </Button>
          </Box>
          <div className={Styles.dottedLine} />
        </Box>
        {!hasEmpty(data) ? (
          <>
            <Box className={Styles.div2}>
              <Box className={Styles.element}>
                <Box className={Styles.question}>
                  <Typography className={Styles.p}>
                    have a bank account that applicant can use to receive direct
                    deposit payments
                  </Typography>
                </Box>
                <Box className={Styles.ans}>
                  <Typography className={Styles.textWrapperAns}>
                    {data?.isHavingBankAccount ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              </Box>
              {data?.isHavingBankAccount ? (
                <>
                  <Box className={Styles.element2}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.textWrapper3}>
                        Account Type
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapperAns}>
                        {data?.accountType}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={Styles.element2}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.textWrapper3}>
                        Routing Number
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapperAns}>
                        {data?.routingNumber}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.textWrapper3}>
                        Account Number
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapperAns}>
                        {data?.accountNumber}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : null}
            </Box>
          </>
        ) : (
          <CustomAlert message='no data available.' />
        )}
      </Box>
    </div>
  )
}

export default ReceivingDisability
