import { Box, Button, Grid, InputLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import {
  ApplicationIntroBox,
  Calander,
  CommonDropdown,
  Name,
  PresentBox,
} from 'src/Components/Fields'
import Address from 'src/Components/Fields/Address'
import { TradeAndTrainingType } from 'src/Redux/Services/Types/applicationType'
import { EditTradeAndTraining } from 'src/Redux/Slices/ApplicationSlices/EducationAndEmploymentSlice'
import {
  haveSpecializedTraining,
  setTradeAndTraining,
} from 'src/Redux/Slices/ApplicationStore/EducationAndEmployement'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import { JobList } from '../../applicationConst'
import Style from '../../index.module.scss'
const TradeAndTraining = ({
  handleEditToggle,
  chatForm,
}: {
  chatForm: any
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  const storeValues = useAppSelector(
    (state) =>
      state?.applicationStore?.EducationAndEmployement?.TradeAndTraining,
  )
  const educationId = useAppSelector(
    (state) =>
      state?.applicationStore?.ApplicationId?.EducationAndEmployementId,
  )
  const [formValues, setFormValues] = useState<TradeAndTrainingType>({
    educationId,
    haveSpecializedTraining: undefined,
    typeOfSpecializedTraining: '',
    schoolName: '',
    startDate: '',
    endDate: '',
    city: '',
    state: '',
    country: '',
  })
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )

  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            haveSpecializedTraining:
              formValues?.haveSpecializedTraining === undefined
                ? 'Boolean'
                : formValues?.haveSpecializedTraining,
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  const [isWorking, setIsWorking] = useState(false)
  const [errors, setErrors] = useState<Record<string, string>>({})

  useEffect(() => {
    setFormValues({ ...storeValues, educationId })
    setIsWorking(!storeValues.endDate)
    setErrors({})
  }, [])
  useEffect(() => {
    setErrors({ ...errors, state: '', country: '' })
  }, [formValues.state, formValues.country])

  // useEffect(() => {
  //   if (isWorking) {
  //     handleChange({
  //       name: 'endDate',
  //       value: '',
  //     })
  //   }
  // }, [isWorking])
  // useEffect(() => {
  //   if (formValues?.endDate?.length === 0) {
  //     setIsWorking(true)
  //   }
  // }, [formValues.endDate])

  useEffect(() => {
    if (formValues?.haveSpecializedTraining === false) {
      setFormValues((prevValues) => ({
        ...prevValues,
        typeOfSpecializedTraining: '',
        schoolName: '',
        startDate: '',
        endDate: '',
        city: '',
        state: '',
        country: '',
      }))
    }
  }, [formValues?.haveSpecializedTraining])

  const handleChange = (data: { name: string; value?: string | boolean }) => {
    setFormValues({ ...formValues, [data.name]: data.value })
    setErrors({ ...errors, [data.name]: '' })
  }

  const validation = () => {
    let isValid = true
    const newErrors: Record<string, string> = {}
    const currentDate = new Date().toISOString().split('T')[0]
    if (formValues.haveSpecializedTraining === undefined) {
      newErrors.haveSpecializedTraining = 'Please select an option'
      isValid = false
    }
    if (formValues.haveSpecializedTraining) {
      if (!formValues.typeOfSpecializedTraining) {
        newErrors.typeOfSpecializedTraining = 'Please select an option'
        isValid = false
      }
      if (!formValues.schoolName) {
        newErrors.schoolName = 'Please enter school name'
        isValid = false
      }
      if (!formValues.startDate) {
        newErrors.startDate = 'Please select Date'
        isValid = false
      }
      if (formValues.startDate > currentDate) {
        newErrors.startDate = 'Please select valid Date'
        isValid = false
      }
      if (!formValues.city) {
        newErrors.city = 'Please enter city'
        isValid = false
      }
      if (!formValues.state) {
        newErrors.state = 'Please enter state'
        isValid = false
      }
      if (!formValues.country) {
        newErrors.country = 'Please enter country'
        isValid = false
      }
      if (!isWorking) {
        if (!formValues.endDate) {
          newErrors.endDate = 'Please select Date'
          isValid = false
        }
        if (formValues.endDate > currentDate) {
          newErrors.endDate = 'Please select valid Date'
          isValid = false
        }
      }
    }

    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (validation()) {
      const payload: TradeAndTrainingType = {}
      payload.educationId = formValues.educationId
      payload.haveSpecializedTraining = formValues.haveSpecializedTraining
      if (formValues.haveSpecializedTraining) {
        payload.typeOfSpecializedTraining = formValues.typeOfSpecializedTraining
        payload.schoolName = formValues.schoolName
        payload.startDate = formValues.startDate
        payload.city = formValues.city
        payload.state = formValues.state
        payload.country = formValues.country
        if (!isWorking) {
          payload.endDate = formValues.endDate
        }
      }
      dispatch(EditTradeAndTraining(payload)).then((res) => {
        if (res?.payload?.success) {
          dispatch(setTradeAndTraining(formValues))
          dispatch(haveSpecializedTraining(formValues.haveSpecializedTraining))
          handleEditToggle()
        }
      })
    }
  }

  return (
    <>
      <Box className={Style.DialogContentBox}>
        <Box className={Style.GridBox}>
          <form className={Style.Form} onSubmit={handleSubmit}>
            <ApplicationIntroBox heading="Specialized education is quite popular, and we'd like to understand if this applies to applicant." />
            <CommonRadioBox
              title='Has applicant completed any type of specialized job training, including trade or vocational school?'
              YesTitle='I have completed specialized job training, including trade or vocational school'
              NoTitle='I have not completed any type of specialized job training, including trade or vocational school'
              commonState={formValues.haveSpecializedTraining}
              setcommonState={(value) => {
                handleChange({ name: 'haveSpecializedTraining', value })
              }}
              error={errors.haveSpecializedTraining}
            />
            {formValues.haveSpecializedTraining && (
              <>
                <Box height={'40px'} width={'100%'}></Box>
                <InputLabel className={Style.label}>
                  Wonderful! Please share the type of specialized training,
                  trade, or vocational school.
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <CommonDropdown
                  menulist={JobList}
                  handleChange={handleChange}
                  label='Type of Specialized Training or Education:'
                  name='typeOfSpecializedTraining'
                  value={formValues.typeOfSpecializedTraining ?? ''}
                  error={errors.typeOfSpecializedTraining}
                />
                <PresentBox
                  Checked={isWorking}
                  handleChange={() => {
                    setIsWorking(!isWorking)
                    handleChange({
                      name: 'endDate',
                      value: '',
                    })
                  }}
                />
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Calander
                    name='startDate'
                    value={formValues.startDate}
                    handleChange={handleChange}
                    error={errors.startDate}
                    title='Start Date'
                  />
                  <Calander
                    name='endDate'
                    value={formValues.endDate}
                    handleChange={handleChange}
                    error={errors.endDate}
                    title='End Date'
                    minDate={formValues.startDate}
                    disabled={isWorking}
                  />
                </Grid>
                <Name
                  title='School Name'
                  value={formValues.schoolName}
                  name='schoolName'
                  handleChange={handleChange}
                  error={errors?.schoolName}
                />
                <Address
                  errors={errors}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  handleChange={handleChange}
                />
              </>
            )}
            <Box className={Style.AddButton}>
              <Button
                variant='contained'
                className={Style.ButtonOne}
                onClick={() => {
                  handleEditToggle()
                }}
              >
                Discard
              </Button>
              <Button
                variant='contained'
                type='submit'
                className={Style.ButtonTwo}
                // onClick={handleNext}
              >
                Save & Update
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default TradeAndTraining
