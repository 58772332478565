import React, { useRef, useState } from 'react'
import LegalName from './LegalName/ViewDetails'
import EditLegalName from './LegalName/EditDetails'
import EditIdentification from './Identification/EditDetails'
import Identification from './Identification/ViewDetails'
import EditReceivingDisability from './ReceivingDisability/EditDetails'
import ReceivingDisability from './ReceivingDisability/ViewDetails'
const LegalDetails: React.FunctionComponent = ({
  chatForm,
}: {
  chatForm?: any
}) => {
  const [isLegalEditing, setIsLegalEditing] = useState(false)
  const [isIdentificationEditing, setIsIdentificationEditing] = useState(false)
  const [isReceivingDisabilityEditing, setIsReceivingDisabilityEditing] =
    useState(false)
  // const [isEditing, setIsEditing] = useState(false);
  const legalRef = useRef<HTMLDivElement>(null)
  const identificationRef = useRef<HTMLDivElement>(null)
  const receivingDisabilityRef = useRef<HTMLDivElement>(null)

  // Legal
  const handleLegalEditClick = () => {
    setIsLegalEditing(true)
    legalRef?.current?.scrollIntoView()
  }

  const handleLegalSaveClick = () => {
    setIsLegalEditing(false)
    legalRef?.current?.scrollIntoView()
  }
  // Identification
  const handleIdentificationEditClick = () => {
    setIsIdentificationEditing(true)
    identificationRef?.current?.scrollIntoView()
  }

  const handleIdentificationSaveClick = () => {
    setIsIdentificationEditing(false)
    identificationRef?.current?.scrollIntoView()
  }
  // ReceivingDisability
  const handleReceivingDisabilityEditClick = () => {
    setIsReceivingDisabilityEditing(true)
    receivingDisabilityRef?.current?.scrollIntoView()
  }

  const handleReceivingDisabilitySaveClick = () => {
    setIsReceivingDisabilityEditing(false)
    receivingDisabilityRef?.current?.scrollIntoView()
  }
  return (
    <>
      {/* Legal Names */}
      {isLegalEditing ? (
        <EditLegalName chatForm={chatForm} onSaveClick={handleLegalSaveClick} />
      ) : (
        // <EditLegalName onSaveClick={handleLegalSaveClick} />
        <LegalName ref={legalRef} onEditClick={handleLegalEditClick} />
      )}

      {/* Identification Numbers */}
      {isIdentificationEditing ? (
        <EditIdentification
          chatForm={chatForm}
          onSaveClick={handleIdentificationSaveClick}
        />
      ) : (
        <Identification
          ref={identificationRef}
          onEditClick={handleIdentificationEditClick}
        />
      )}

      {/* Receiving Disability Benefit Payments */}
      {isReceivingDisabilityEditing ? (
        <EditReceivingDisability
          chatForm={chatForm}
          onSaveClick={handleReceivingDisabilitySaveClick}
        />
      ) : (
        <ReceivingDisability
          ref={receivingDisabilityRef}
          onEditClick={handleReceivingDisabilityEditClick}
        />
      )}
    </>
  )
}

export default LegalDetails
