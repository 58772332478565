import CurrenySignificant from './CurrentSignificant'
import PastSignificant from './PastSignificant'
import Dependents from './Dependent'
import Parents from './Parents'

const FamilyContent = ({ chatForm }: { chatForm?: any }) => {
  return (
    <>
      {/* =================================Current Significant Other ======================================== */}
      <CurrenySignificant chatForm={chatForm} />
      {/* ====================== Past Significant Other(s) start ================= */}
      <PastSignificant chatForm={chatForm} />
      {/* =================== Dependents starts ==================================== */}
      <Dependents chatForm={chatForm} />
      {/* =========================== Parents starts ============================== */}
      <Parents chatForm={chatForm} />
      {/* ==========================Parents over ============================= */}
    </>
  )
}
export default FamilyContent
