import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import {
  Button,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import Style from './index.module.scss'
import Address from 'src/Components/Fields/Address'

import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import star from 'src/Assets/Icons/star.svg'
import quote from 'src/Assets/Icons/quots.svg'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import {
  AddCurrentSignificant,
  EditCurrentSignificant,
} from 'src/Redux/Slices/ApplicationSlices/FamilyDetailsSlice'
import { CurrentSignificantType } from 'src/Redux/Services/Types/applicationType'
import { setCurrentSignificant } from 'src/Redux/Slices/ApplicationStore/FamilyDetailsStore'
import { setFamilyId } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { DynamicObj } from 'src/Utils/Constants'

const StyledSelect = styled(Select)({
  borderRadius: '10px', // Set the border radius to your desired value
  // Add any other custom styles here
})

const CurrentSignificant = ({
  handleEditToggle,
  chatForm,
}: {
  chatForm: any
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  const storeValues = useAppSelector(
    (state) => state?.applicationStore?.FamilyDetails?.CurrentSignificant,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const familyId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.familyId,
  )
  const [SSN, setSSN] = useState<string[]>(['', '', ''])
  const [currentSignificantOther, setCurrentSignificantOther] = useState<
    boolean | undefined
  >(storeValues.isLegallyMarried ?? undefined)

  const handleSSNChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.match(/^\d*$/)) {
        const newSSN = [...SSN]
        newSSN[index] = event.target.value
        setSSN(newSSN)
      }
    }

  const [formValues, setFormValues] = useState<CurrentSignificantType>({
    havePriorMarriages: false,
    applicationId,
    isLegallyMarried: currentSignificantOther,
    spouseFirstName: storeValues.spouseFirstName ?? '',
    spouseLastName: storeValues.spouseLastName ?? '',
    spouseDateOfBirth: storeValues.spouseDateOfBirth ?? '',
    spouseSSN: SSN.join(''),
    typeOfMarriage: storeValues.typeOfMarriage ?? '',
    dateOfMarriage: storeValues.dateOfMarriage ?? '',
    placeOfMarriage: {
      city: storeValues.placeOfMarriage?.city ?? '',
      state: storeValues.placeOfMarriage?.state ?? '',
      country: storeValues.placeOfMarriage?.country ?? '',
      other: storeValues.placeOfMarriage?.other ?? '',
    },
  })
  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )

  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            havePriorMarriages:
              formValues?.havePriorMarriages === undefined
                ? 'Boolean'
                : formValues?.havePriorMarriages,
            isLegallyMarried:
              formValues?.isLegallyMarried === undefined
                ? 'Boolean'
                : formValues?.isLegallyMarried,
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  const [addressValues, setAddressValues] = useState<DynamicObj>({
    city: storeValues.placeOfMarriage?.city ?? '',
    state: storeValues.placeOfMarriage?.state ?? '',
    country: storeValues.placeOfMarriage?.country ?? '',
  })

  const [dateOfBirth, setDateOfBirth] = useState<Date | null | string>(
    storeValues?.spouseDateOfBirth,
  )
  const [dateOfMarriage, setDateOfMarriage] = useState<Date | null | string>(
    storeValues?.dateOfMarriage,
  )

  useEffect(() => {
    setFormValues({ ...storeValues })
    if (storeValues.spouseSSN) {
      setSSN([
        storeValues?.spouseSSN.slice(0, 3),
        storeValues.spouseSSN.slice(3, 5),
        storeValues.spouseSSN.slice(5),
      ])
    }
  }, [])

  useEffect(() => {}, [formValues])
  useEffect(() => {
    if (!currentSignificantOther) {
      setFormValues({
        applicationId,
        isLegallyMarried: currentSignificantOther,
      })
      setDateOfBirth('')
      setDateOfMarriage('')
      setSSN(['', '', ''])
    }
  }, [currentSignificantOther])

  useEffect(() => {
    if (currentSignificantOther) {
      setFormValues((prevState) => ({
        ...prevState,
        applicationId,
        isLegallyMarried: currentSignificantOther,
        spouseSSN: SSN.join(''),
        spouseDateOfBirth: dateOfBirth
          ? dayjs(dateOfBirth).format('YYYY-MM-DD').toString()
          : '', // Convert to string if dateOfBirth is not null
        dateOfMarriage: dateOfMarriage
          ? dayjs(dateOfMarriage).format('YYYY-MM-DD').toString()
          : '',
      }))
    }
  }, [applicationId, SSN, dateOfBirth, dateOfMarriage])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleChange = (data: { name: string; value?: string | number }) => {
    setAddressValues({ ...addressValues, [data.name]: data.value })
    setErrors({ ...errors, [data.name]: '' })
  }

  useEffect(() => {
    setFormValues({
      ...formValues,
      placeOfMarriage: {
        state: addressValues?.state,
        city: addressValues?.city,
        country: addressValues?.country,
        other: formValues?.placeOfMarriage?.other ?? '',
      },
    })
  }, [addressValues])
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const updatedFormValues: CurrentSignificantType = { ...formValues }

    // If it's a nested field like physicalAddress or currentAddress
    if (name.includes('.')) {
      const [parent, child] = name.split('.')
      updatedFormValues[parent] = {
        ...updatedFormValues[parent],
        [child]: value,
      }
    } else {
      updatedFormValues[name] = value
    }
    setFormValues(updatedFormValues)
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    if (formValues?.isLegallyMarried === undefined) {
      newErrors.isLegallyMarried = 'Please select an option'
      valid = false
    }

    if (currentSignificantOther) {
      // Validation logic for Full Name
      if (!formValues?.spouseFirstName?.trim()) {
        newErrors.spouseFirstName = 'First Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.spouseFirstName?.trim())) {
        newErrors.spouseFirstName = 'First Name must contain only letters'
        valid = false
      }

      if (!formValues?.spouseLastName?.trim()) {
        newErrors.spouseLastName = 'Last Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.spouseLastName?.trim())) {
        newErrors.spouseLastName = 'Last Name must contain only letters'
        valid = false
      }

      if (!formValues.spouseDateOfBirth.trim()) {
        newErrors.spouseDateOfBirth = 'Date is required'
        valid = false
      }

      if (!formValues?.spouseSSN?.trim()) {
        newErrors.spouseSSN = 'Social Security Number is required'
        valid = false
      }

      // if (!formValues?.typeOfMarriage?.trim()) {
      //   newErrors.typeOfMarriage = 'Type is required'
      //   valid = false
      // }

      if (!formValues?.typeOfMarriage?.trim()) {
        newErrors.typeOfMarriage = 'Type is required'
        valid = false
      }

      if (!formValues?.placeOfMarriage?.city?.trim()) {
        newErrors.city = 'city is required'
        valid = false
      }
      if (!formValues?.placeOfMarriage?.state?.trim()) {
        newErrors.state = 'state is required'
        valid = false
      }

      if (!formValues?.placeOfMarriage.country?.trim()) {
        newErrors.country = 'country is required'
        valid = false
      }

      if (
        formValues?.dateOfMarriage &&
        typeof formValues.dateOfMarriage === 'string' &&
        !formValues.dateOfMarriage.trim()
      ) {
        newErrors.dateOfMarriage = 'Date is required'
        valid = false
      }
      if (
        formValues?.spouseDateOfBirth &&
        typeof formValues.spouseDateOfBirth === 'string' &&
        !formValues.spouseDateOfBirth.trim()
      ) {
        newErrors.spouseDateOfBirth = 'Date is required'
        valid = false
      }

      if (!formValues?.placeOfMarriage.other?.trim()) {
        newErrors.other = 'Description is required'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      // dispatch(setContactInformation(formValues))
      if (!familyId) {
        const formValuesToSubmit = formValues.isLegallyMarried
          ? formValues
          : {
              applicationId,
              isLegallyMarried: currentSignificantOther,
            }
        dispatch(AddCurrentSignificant(formValuesToSubmit)).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            const data = res?.payload?.data
            if (res?.payload?.success) {
              dispatch(setCurrentSignificant(formValues))
              dispatch(setFamilyId(data?.familyId))
              handleEditToggle()
            }
          },
        )
      } else {
        const formValuesToSubmit = formValues.isLegallyMarried
          ? formValues
          : {
              familyId,
              isLegallyMarried: currentSignificantOther,
            }
        dispatch(
          EditCurrentSignificant({ ...formValuesToSubmit, familyId }),
        ).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            if (res?.payload?.success) {
              dispatch(setCurrentSignificant(formValues))
              handleEditToggle()
            }
          },
        )
      }
    }
  }
  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              py: 2,
              my: 3,
              borderRadius: '10px',
            }}
          >
            <img src={quote} className={Style.star} alt='' />
            <Typography className={Style.Heading}>
              Hello again! In this section, we'll work through questions related
              to current and past marriages, children, and other dependents who
              you may be supporting, such as parents.
            </Typography>
          </Box>
          <InputLabel className={Style.label}>
            Marriage Documentation<span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <CommonRadioBox
            Style={Style}
            title='Is applicant currently legally married?'
            commonState={currentSignificantOther}
            setcommonState={setCurrentSignificantOther}
            YesTitle='Yes, I am'
            NoTitle='No, I am not'
          />
          <FormHelperText>{errors?.isLegallyMarried}</FormHelperText>

          {currentSignificantOther && (
            <>
              <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                <Grid width={'100%'}>
                  <InputLabel className={Style.label}>
                    Current Spouse's First Name{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='text'
                    id='spouseFirstName'
                    name='spouseFirstName'
                    className={Style.AnsTxt}
                    placeholder='First Name'
                    value={formValues?.spouseFirstName}
                    onChange={handleFieldChange}
                    error={!!errors.spouseFirstName}
                    helperText={errors.spouseFirstName}
                  />
                </Grid>
                <Grid width={'100%'}>
                  <InputLabel className={Style.label}>
                    Current Spouse's Last Name{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='text'
                    id='spouseLastName'
                    name='spouseLastName'
                    className={Style.AnsTxt}
                    placeholder='Last Name'
                    value={formValues?.spouseLastName}
                    onChange={handleFieldChange}
                    error={!!errors.spouseLastName}
                    helperText={errors.spouseLastName}
                  />
                </Grid>
              </Grid>

              <Box width={'100%'} mt={3}>
                <InputLabel className={Style.label}>
                  Current Spouse's Date of Birth (estimate if unknown){' '}
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '100%' }}
                    value={dateOfBirth ? dayjs(dateOfBirth) : null} // Convert to Dayjs object
                    onChange={(date) => {
                      setDateOfBirth(date)
                    }}
                    maxDate={dayjs()} // Use Dayjs for maxDate as well
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        fullWidth
                        style={{ width: '100%' }}
                        id='spouseDateOfBirth'
                        name='spouseDateOfBirth'
                        placeholder='mm-DD-YYYY'
                        className={Style.AnsTxt}
                        InputProps={{
                          inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText sx={{ mx: 1.5 }}>
                  {errors.spouseDateOfBirth}
                </FormHelperText>
              </Box>

              <Box
                width={'100%'}
                display={'flex'}
                alignItems={'flex-start'}
                sx={{
                  p: 2,
                  my: 2,
                  backgroundColor: '#ECF6FF',
                  borderRadius: '10px',
                }}
              >
                <img src={star} className={Style.star} alt='' />
                <Typography>
                  <i>
                    This information will not be shared and will be safeguarded
                    24/7.
                  </i>
                </Typography>
              </Box>

              {/* address */}
              <Box>
                <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Current Spouse's Social Security Number (SSN)
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <Grid gap={1} display={'flex'} flexDirection={'row'}>
                      <TextField
                        fullWidth
                        className={Style.AnsTxt}
                        value={SSN[0]}
                        onChange={handleSSNChange(0)}
                        placeholder='###'
                        inputProps={{ maxLength: 3 }}
                        error={!!errors.spouseSSN}
                      />
                      <TextField
                        fullWidth
                        className={Style.AnsTxt}
                        value={SSN[1]}
                        onChange={handleSSNChange(1)}
                        placeholder='##'
                        inputProps={{ maxLength: 2 }}
                        error={!!errors.spouseSSN}
                      />
                      <TextField
                        fullWidth
                        className={Style.AnsTxt}
                        value={SSN[2]}
                        onChange={handleSSNChange(2)}
                        placeholder='####'
                        inputProps={{ maxLength: 4 }}
                        error={!!errors.spouseSSN}
                      />
                    </Grid>
                    <FormHelperText sx={{ mx: 1.5 }}>
                      {errors.spouseSSN}
                    </FormHelperText>
                  </Grid>

                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Type of Marriage
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <StyledSelect
                      onChange={(e) => {
                        handleFieldChange(e)
                      }}
                      className={Style.AnsTxt}
                      IconComponent={(props) => (
                        <IconButton {...props}>
                          <img src={IconChevronDown} alt='IconChevronDown' />
                        </IconButton>
                      )}
                      displayEmpty
                      renderValue={
                        formValues.typeOfMarriage !== ''
                          ? () => formValues.typeOfMarriage
                          : () => 'Select'
                      }
                      value={formValues.typeOfMarriage}
                      fullWidth
                      id='typeOfMarriage'
                      name='typeOfMarriage'
                      error={!!errors.typeOfMarriage}
                    >
                      <MenuItem value=''>
                        <em>Select an Answer</em>
                      </MenuItem>
                      <MenuItem value='Marriage by clergy or public official'>
                        Marriage by clergy or public official
                      </MenuItem>
                      <MenuItem value='Other ceremonial marriage'>
                        Other ceremonial marriage
                      </MenuItem>
                      <MenuItem value='Common law marriage'>
                        Common law marriage
                      </MenuItem>
                      <MenuItem value='I do not know, or this information is not  available'>
                        I do not know, or this information is not available
                      </MenuItem>
                    </StyledSelect>
                    <FormHelperText sx={{ mx: 1.5 }}>
                      {errors.typeOfMarriage}
                    </FormHelperText>
                  </Grid>
                </Grid>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Date of Marriage<span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: '100%' }}
                        value={dateOfMarriage ? dayjs(dateOfMarriage) : null} // Convert to Dayjs object
                        onChange={(date) => {
                          setDateOfMarriage(date)
                        }}
                        maxDate={dayjs()} // Use Dayjs for maxDate as well
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            style={{ width: '100%' }}
                            id='dateOfMarriage'
                            name='dateOfMarriage'
                            placeholder='mm-DD-YYYY'
                            className={Style.AnsTxt}
                            InputProps={{
                              inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <FormHelperText sx={{ mx: 1.5 }}>
                      {errors.dateOfMarriage}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Box>

              {/* address */}
              <Box my={'10px'}>
                <Typography className={Style.label}>
                  Place of Marriage
                </Typography>

                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  {/* <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      City <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='placeOfMarriage.city'
                      name='placeOfMarriage.city'
                      inputProps={{ maxLength: 10 }}
                      className={Style.AnsTxt}
                      placeholder='State'
                      value={formValues?.placeOfMarriage?.city}
                      onChange={handleFieldChange}
                      error={!!errors.city}
                      helperText={errors.city}
                    />
                  </Grid>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      State/Territory <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='placeOfMarriage.state'
                      name='placeOfMarriage.state'
                      inputProps={{ maxLength: 10 }}
                      className={Style.AnsTxt}
                      placeholder='State'
                      value={formValues?.placeOfMarriage?.state}
                      onChange={handleFieldChange}
                      error={!!errors.state}
                      helperText={errors.state}
                    />
                  </Grid>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Country <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='placeOfMarriage.country'
                      name='placeOfMarriage.country'
                      inputProps={{ maxLength: 20 }}
                      className={Style.AnsTxt}
                      placeholder='Country'
                      value={formValues?.placeOfMarriage?.country}
                      onChange={handleFieldChange}
                      error={!!errors.country}
                      helperText={errors.country}
                    />
                  </Grid> */}
                  <Address
                    errors={errors}
                    formValues={addressValues}
                    setFormValues={setAddressValues}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Other<span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='placeOfMarriage.other'
                      name='placeOfMarriage.other'
                      multiline
                      rows={4}
                      className={Style.AnsTxt}
                      placeholder='Other'
                      value={formValues?.placeOfMarriage?.other}
                      onChange={handleFieldChange}
                      // error={!!errors.other}
                      // helperText={errors.other}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                // setIsSubmitted(false)
                handleEditToggle()
              }}
              // sx={{ visibility: 'hidden' }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
              // onClick={handleNext}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default CurrentSignificant
