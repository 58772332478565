import {
  Button,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import quote from 'src/Assets/Icons/quots.svg'
import star from 'src/Assets/Icons/star.svg'
import {
  AddAssistant,
  EditAssistant,
} from 'src/Redux/Slices/ApplicationSlices/assistantFormSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ROUTES } from 'src/Utils/Routes'
import Style from './index.module.scss'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { ZipcodeField } from 'src/Components/Fields'
import { getApplicationDetail } from 'src/Redux/Slices/ApplicationSlices/allApplicationSlice'
import {
  BasicDetailsType,
  FamilyDetailsType,
  DisabilityDetailsType,
  EducationAndEmployementType,
  MedicalDetailsType,
} from 'src/Redux/Services/Types/applicationType'
import {
  setApplicationId,
  setBasicId,
  setFamilyId,
  setLegalId,
  setDisabilityId,
  setEducationAndEmployementId,
  setMedicalDetailId,
} from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { setBasicDetails } from 'src/Redux/Slices/ApplicationStore/BasicDetailsStore'
import { setDisabilityDetails } from 'src/Redux/Slices/ApplicationStore/DisabilityDetailsStore'
import { setEducationDetails } from 'src/Redux/Slices/ApplicationStore/EducationAndEmployement'
import { setFamilyDetails } from 'src/Redux/Slices/ApplicationStore/FamilyDetailsStore'
import { setLegalDetails } from 'src/Redux/Slices/ApplicationStore/LegalDetailsStore'
import { setMedicalDetails } from 'src/Redux/Slices/ApplicationStore/MedicalDetailsStore'
import {
  extractDetailedApplicationMedicalData,
  extractDetailedApplicationLegalData,
  extractDetailedApplicationBasicData,
  extractDetailedApplicationFamilyData,
  extractDetailedApplicationDisabledData,
  extractDetailedApplicationEducationData,
} from 'src/Utils/Constants'
import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'

interface AssistantFormType {
  firstName: string
  lastName: string
  relation: string
  phone: string
  email: string
  organizationName: string
  streetAddress: string
  city: string
  state: string
  postalCode: string
  country: string
}
interface Place {
  id: string
  description: string
}
const itemStyle = {
  xs: 12,
  sm: 12,
  md: 12,
}

const marginStyle = {
  mr: { md: 2, xs: 2 },
}

const AssistantForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  function goBack() {
    navigate(-1)
  }
  const StyledSelect = styled(Select)({
    borderRadius: '10px',
  })

  const assistantData = useAppSelector(
    (state) => state?.applicationStore?.BasicDetails?.assistancedata,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const [formValues, setFormValues] = useState<AssistantFormType>({
    firstName: assistantData?.firstName ?? '',
    lastName: assistantData?.lastName ?? '',
    relation: assistantData?.relationToApplicant ?? '',
    phone: assistantData?.phoneNumber ?? '',
    email: assistantData?.emailId ?? '',
    streetAddress: assistantData?.address?.streetAddress ?? '',
    city: assistantData?.address?.city ?? '',
    state: assistantData?.address?.state ?? '',
    postalCode: assistantData?.address?.postalCode ?? '',
    country: assistantData?.address?.country ?? '',
    organizationName: assistantData?.organizationName ?? '',
  })

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    // You may perform validation here and update errors state accordingly
    const inputName = e.target.name
    const inputValue = e.target.value

    // Only allow alphabetic characters
    if (inputName === 'firstName' && !/^[A-Za-z ]*$/.test(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: 'Only characters are allowed',
      }))
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: '',
      }))
    }
    if (inputName === 'lastName' && !/^[A-Za-z ]*$/.test(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: 'Only characters are allowed',
      }))
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: '',
      }))
    }
    if (inputName === 'organizationName' && !/^[A-Za-z ]*$/.test(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: 'Only characters are allowed',
      }))
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: '',
      }))
    }
    if (inputName === 'phone' && !/^\d{10}$/.test(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: 'Phone number must be 10 digits',
      }))
    }
  }
  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}
    // Validation logic for Full Name
    if (!formValues.firstName?.trim()) {
      newErrors.firstName = 'First Name required'
      valid = false
    } else if (!/^[A-Za-z ]+$/.test(formValues.firstName?.trim())) {
      newErrors.firstName = 'Only characters are allowed'
      valid = false
    }

    if (!formValues.lastName?.trim()) {
      newErrors.lastName = 'Last Name required'
      valid = false
    } else if (!/^[A-Za-z ]+$/.test(formValues.firstName?.trim())) {
      newErrors.firstName = 'Only characters are allowed'
      valid = false
    }

    if (!formValues.relation?.trim()) {
      newErrors.relation = 'Relation is required'
      valid = false
    }

    // Validation logic for Phone number
    if (!formValues.phone?.trim()) {
      newErrors.phone = 'Phone number is required'
      valid = false
    } else if (!/^[0-9]{10}$/.test(formValues.phone?.trim())) {
      newErrors.phone = 'Phone number must be 10 digits'
      valid = false
    }

    if (!formValues.email?.trim()) {
      newErrors.email = 'Email Name is required'
      valid = false
    } else if (
      !/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(formValues.email?.trim())
    ) {
      newErrors.email = 'Please enter a valid email address'
      valid = false
    }

    // if (!formValues.organizationName?.trim()) {
    //   newErrors.organizationName = 'Organization is required'
    //   valid = false
    // } else
    if (!/^[A-Za-z ]+$/.test(formValues.firstName?.trim())) {
      newErrors.firstName = 'Only characters are allowed'
      valid = false
    }

    if (!formValues.streetAddress?.trim()) {
      newErrors.streetAddress = 'Address is required'
      valid = false
    }
    if (!formValues.city?.trim()) {
      newErrors.city = 'City is required'
      valid = false
    }
    if (!formValues.state?.trim()) {
      newErrors.state = 'State is required'
      valid = false
    }
    if (!formValues.postalCode?.trim()) {
      newErrors.postalCode = 'Zip Code is required'
      valid = false
    }
    if (!formValues.country?.trim()) {
      newErrors.country = 'Country Code is required'
      valid = false
    }
    setErrors(newErrors)
    return valid
  }

  useEffect(() => {
    setFormValues({
      ...formValues,
      firstName: assistantData?.firstName ?? '',
      lastName: assistantData?.lastName ?? '',
      relation: assistantData?.relationToApplicant ?? '',
      phone: assistantData?.phoneNumber ?? '',
      email: assistantData?.emailId ?? '',
      organizationName: assistantData?.organizationName ?? '',
      streetAddress: assistantData?.address?.streetAddress ?? '',
      city: assistantData?.address?.city ?? '',
      state: assistantData?.address?.state ?? '',
      postalCode: assistantData?.address?.postalCode ?? '',
      country: assistantData?.address?.country ?? '',
    })
  }, [assistantData])

  useEffect(() => {
    dispatch(getApplicationDetail(applicationId)).then((res) => {
      setApplicationId(applicationId)

      // Check if response contains data
      if (res?.payload?.data) {
        const jsonData = res?.payload?.data
        const basicdata = jsonData?.basicdata[0]
        const familydata = jsonData?.familydata[0]
        const legalData = jsonData?.legalData[0]
        const disablitydata = jsonData?.disabilitydetails[0]
        const educationdata = jsonData?.educationdetails[0]
        const assistancedata = jsonData?.assistancedata[0]
        const medicaldetails = jsonData?.medicaldetails[0]

        const MedicalDetails = extractDetailedApplicationMedicalData(
          medicaldetails,
          applicationId,
        )
        const LegalDetails = extractDetailedApplicationLegalData(
          legalData,
          applicationId,
        )

        const basicDetails = extractDetailedApplicationBasicData(
          basicdata,
          assistancedata,
        )

        const familyDetails = extractDetailedApplicationFamilyData(
          familydata,
          applicationId,
        )
        const disabilityDetails = extractDetailedApplicationDisabledData(
          disablitydata,
          applicationId,
        )

        const educationDetail =
          extractDetailedApplicationEducationData(educationdata)

        if (basicDetails) {
          dispatch(setBasicId(jsonData?.basicdata[0]?.basicId as string))
          dispatch(setBasicDetails(basicDetails as BasicDetailsType))
        }
        if (familyDetails) {
          dispatch(setFamilyId(jsonData?.familydata[0]?.familyId as string))

          dispatch(setFamilyDetails(familyDetails as FamilyDetailsType))
        }

        if (LegalDetails) {
          dispatch(setLegalId(jsonData?.legalData[0]?.legalId as string))

          dispatch(setLegalDetails(LegalDetails))
        }
        if (disabilityDetails) {
          dispatch(
            setDisabilityId(jsonData?.disabilitydetails[0]?.disabilityId),
          )
          dispatch(
            setDisabilityDetails(disabilityDetails as DisabilityDetailsType),
          )
        }
        if (educationDetail) {
          dispatch(
            setEducationAndEmployementId(
              jsonData?.educationdetails[0]?.educationId,
            ),
          )

          dispatch(
            setEducationDetails(educationDetail as EducationAndEmployementType),
          )
        }
        if (MedicalDetails) {
          dispatch(setMedicalDetailId(jsonData?.medicaldetails[0]?.medicalId))
          dispatch(setMedicalDetails(MedicalDetails as MedicalDetailsType))
        }
      }
    })
  }, [applicationId])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      if (assistantData?.uuid) {
        dispatch(
          EditAssistant({ ...formValues, assistanceId: assistantData?.uuid }),
        ).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            const data = res?.payload?.data
            if (res?.payload?.success) {
              navigate(ROUTES.BASIC_DETAILS, {
                state: { data: data?.uuid },
              })
            }
          },
        )
      } else {
        dispatch(AddAssistant({ ...formValues, applicationId })).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            const data = res?.payload?.data
            if (res?.payload?.success) {
              navigate(ROUTES.BASIC_DETAILS, {
                state: { data: data?.assistanceId },
              })
            }
          },
        )
      }
    }
    // You can also perform any validation here before submitting
  }

  const inputRef = useRef()
  const [predictions, setPredictions] = useState<Place[]>([])

  const autocomplete = (query: string) => {
    if (query.length === 0) {
      setPredictions([])
      return
    }

    const autocompleteService = new google.maps.places.AutocompleteService()

    autocompleteService.getPlacePredictions(
      { input: query },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const placePredictions: Place[] = predictions.map((prediction) => {
            return {
              description: prediction.description,
              id: prediction.place_id,
            }
          })
          setPredictions(placePredictions)
        }
      },
    )
  }

  function fetchPlaceDetails(placeId: string) {
    const placesService = new google.maps.places.PlacesService(
      document.createElement('div'),
    )
    placesService.getDetails({ placeId }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        // Access address components
        const addressComponents = place.address_components

        if (addressComponents && addressComponents.length > 0) {
          if (place?.address_components) {
            const addressComponents = place.address_components
            const updatedFormValues = { ...formValues }

            addressComponents.forEach(
              (component: { types: any; long_name: string }) => {
                const types = component.types
                if (types.includes('street_number')) {
                  updatedFormValues.streetAddress = component.long_name
                } else if (types.includes('route')) {
                  updatedFormValues.streetAddress += ` ${component.long_name}`
                } else if (types.includes('locality')) {
                  updatedFormValues.city = component.long_name
                } else if (types.includes('administrative_area_level_1')) {
                  updatedFormValues.state = component.long_name
                } else if (types.includes('postal_code')) {
                  updatedFormValues.postalCode = component.long_name
                } else if (types.includes('country')) {
                  updatedFormValues.country = component.long_name
                }
              },
            )

            setFormValues({
              ...formValues,
              streetAddress: updatedFormValues.streetAddress,
              state: updatedFormValues.state,
              city: updatedFormValues.city,
              country: updatedFormValues.country,
              postalCode: updatedFormValues.postalCode,
            })
          } else {
            console.error('Invalid place or missing address components')
          }
        }
      }
    })
  }

  const handlePredictionClick = (prediction: Place) => {
    fetchPlaceDetails(prediction.id)
    // You can do additional things here, like setting the selected prediction as the input value
  }

  // const initialObj: {
  //   streetAddress: string
  //   city: string
  //   state: string
  //   postalCode: string
  //   country: string
  // } = {
  //   streetAddress: '',
  //   city: '',
  //   state: '',
  //   postalCode: '',
  //   country: '',
  // }

  const handleAddressChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target
    if (value.length) {
      setFormValues({
        ...formValues,
        [name]: value,
      })
      autocomplete(value)
    } else {
      setFormValues({
        ...formValues,
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      })
      setPredictions([])
    }
  }

  useEffect(() => {
    if (formValues.streetAddress.length) {
      setPredictions([])
    }
  }, [formValues])

  return (
    <Grid lg={12}>
      <Box className={Style.WelcomeBox}>
        <Grid
          my={1}
          mx={20}
          justifyContent='center'
          width={'100%'}
          height={'100%'}
        >
          <Box className={Style.DialogContentBox}>
            <Box className={Style.GridBox}>
              <form className={Style.Form} onSubmit={handleSubmit}>
                <Box
                  width={'100%'}
                  display={'flex'}
                  alignItems={'center'}
                  sx={{
                    py: 2,
                    my: 3,
                    borderRadius: '10px',
                  }}
                >
                  <img src={quote} className={Style.star} alt='' />
                  <Typography className={Style.Heading}>
                    Let's start by providing basic details for the assistant.
                  </Typography>
                </Box>
                <Typography className={Style.label}>Basic Info</Typography>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      First Name <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='firstName'
                      name='firstName'
                      className={Style.AnsTxt}
                      placeholder='First Name'
                      value={formValues?.firstName}
                      onChange={handleFieldChange}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                    />
                  </Grid>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Last Name <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='lastName'
                      name='lastName'
                      className={Style.AnsTxt}
                      placeholder='Last Name'
                      value={formValues?.lastName}
                      onChange={handleFieldChange}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                    />
                  </Grid>
                </Grid>

                <Box width={'100%'} mt={3}>
                  <InputLabel className={Style.label} htmlFor='scope'>
                    Relation with applicant
                    <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <StyledSelect
                    onChange={(e) => {
                      handleFieldChange(
                        e as React.ChangeEvent<HTMLInputElement>,
                      )
                    }}
                    className={Style.AnsTxt}
                    IconComponent={(props) => (
                      <IconButton {...props}>
                        <img src={IconChevronDown} alt='IconChevronDown' />
                      </IconButton>
                    )}
                    displayEmpty
                    renderValue={
                      formValues.relation !== ''
                        ? () => formValues.relation
                        : () => 'Select'
                    }
                    value={formValues.relation}
                    fullWidth
                    id='relation'
                    name='relation'
                    placeholder='Relation with applicant'
                    error={!!errors.relation}
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value='Mother'>Mother</MenuItem>
                    <MenuItem value='Father'>Father</MenuItem>
                    <MenuItem value='Child'>Child</MenuItem>
                    <MenuItem value='Brother'>Brother</MenuItem>
                    <MenuItem value='Sister'>Sister</MenuItem>
                    <MenuItem value='Spouse'>Spouse</MenuItem>
                    <MenuItem value='Friend'>Friend</MenuItem>
                    <MenuItem value='Charitable Organization'>
                      Charitable Organization
                    </MenuItem>
                  </StyledSelect>
                  <FormHelperText sx={{ mx: 1.5 }}>
                    {errors.relation}
                  </FormHelperText>
                </Box>

                <Box width={'100%'} mt={3}>
                  <InputLabel className={Style.label}>
                    Phone number<span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='number'
                    id='phone'
                    name='phone'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>(+1)</InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 10 }}
                    className={Style.AnsTxt}
                    placeholder='Phone number'
                    value={formValues?.phone}
                    onKeyDown={(evt) => {
                      ;['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                    }}
                    onChange={(e) => {
                      if (
                        e.target.value.length <= 10 &&
                        /^[0-9]*$/.test(e.target.value)
                      ) {
                        handleFieldChange(
                          e as React.ChangeEvent<HTMLInputElement>,
                        )
                      }
                    }}
                    error={!!errors.phone}
                    helperText={errors.phone}
                  />
                </Box>
                <Box width={'100%'} mt={3}>
                  <InputLabel className={Style.label}>
                    Email<span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='email'
                    id='email'
                    name='email'
                    className={Style.AnsTxt}
                    placeholder='Email'
                    value={formValues?.email}
                    onChange={handleFieldChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Box>
                <Box width={'100%'} mt={3}>
                  <InputLabel className={Style.label}>
                    Organization Name.
                    {/* <span style={{ color: 'red' }}>*</span> */}
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='text'
                    id='organizationName'
                    name='organizationName'
                    className={Style.AnsTxt}
                    placeholder='Organization name'
                    value={formValues?.organizationName}
                    onChange={handleFieldChange}
                    error={!!errors.organizationName}
                    helperText={errors.organizationName}
                  />
                </Box>

                <Box
                  width={'100%'}
                  display={'flex'}
                  alignItems={'flex-start'}
                  sx={{
                    p: 2,
                    my: 3,
                    backgroundColor: '#ECF6FF',
                    borderRadius: '10px',
                  }}
                >
                  <img src={star} className={Style.star} alt='' />
                  <Typography>
                    Please indicate the name of the organization if it aids and
                    advocates for individuals with disabilities.
                  </Typography>
                </Box>

                <Typography className={Style.label}>Address</Typography>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Street Address <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='streetAddress'
                      name='streetAddress'
                      className={Style.AnsTxt}
                      placeholder='Street Address'
                      value={formValues?.streetAddress}
                      onChange={handleAddressChange}
                      error={!!errors.streetAddress}
                      helperText={errors.streetAddress}
                      inputRef={inputRef}
                    />
                    {predictions.length ? (
                      <Grid item md={12} xs={12} className='form-row-item'>
                        <Grid
                          item
                          {...itemStyle}
                          {...marginStyle}
                          className={Style.listBox}
                        >
                          {predictions.map((prediction, index) => (
                            <>
                              <div
                                key={index}
                                className={Style.listItem}
                                onClick={() => {
                                  handlePredictionClick(prediction)
                                }}
                              >
                                {prediction.description}
                              </div>
                              <Divider />
                            </>
                          ))}
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>

                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      City/Town <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='city'
                      name='city'
                      className={Style.AnsTxt}
                      placeholder='City/Town'
                      value={formValues?.city}
                      onChange={(e) => {
                        if (e.target.value.match(/^[a-zA-Z\s]*$/)) {
                          handleFieldChange(
                            e as React.ChangeEvent<HTMLInputElement>,
                          )
                        }
                      }}
                      error={!!errors.city}
                      helperText={errors.city}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      State <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='state'
                      name='state'
                      className={Style.AnsTxt}
                      placeholder='State'
                      value={formValues?.state}
                      onChange={(e) => {
                        if (e.target.value.match(/^[a-zA-Z\s]*$/)) {
                          handleFieldChange(
                            e as React.ChangeEvent<HTMLInputElement>,
                          )
                        }
                      }}
                      error={!!errors.state}
                      helperText={errors.state}
                    />
                  </Grid>
                  <ZipcodeField
                    postalCode={formValues?.postalCode}
                    handleChange={({ name, value }) => {
                      setFormValues({ ...formValues, [name]: value })
                    }}
                    error={errors?.postalCode}
                  />
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Country <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='country'
                      name='country'
                      className={Style.AnsTxt}
                      placeholder='Country'
                      value={formValues?.country}
                      onChange={(e) => {
                        if (e.target.value.match(/^[a-zA-Z\s]*$/)) {
                          handleFieldChange(
                            e as React.ChangeEvent<HTMLInputElement>,
                          )
                        }
                      }}
                      error={!!errors.country}
                      helperText={errors.country}
                    />
                  </Grid>
                </Grid>

                <Box className={Style.AddButton}>
                  <Button
                    variant='contained'
                    className={Style.ButtonOne}
                    onClick={goBack}
                  >
                    Previous
                  </Button>
                  <Button
                    variant='contained'
                    type='submit'
                    className={Style.ButtonTwo}
                  >
                    Save and start the applicant's application
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  )
}
export default AssistantForm
