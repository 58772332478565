import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import React, { useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { convertISOToDate, hasEmpty } from 'src/Utils/Constants'
import MilitaryService from '../../EditForms/BasicDetails/MilitaryService/index'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: '16px',
  },
}))

const MillataryService: React.FC = ({ chatForm }: { chatForm?: any }) => {
  const [editMode, setEditMode] = useState(false)
  const milataryRef = useRef<HTMLDivElement>(null)

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.BasicDetails?.MilitaryService,
  )
  const sectionId = useAppSelector(
    (state) =>
      state?.steps?.BasicApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Military Service',
      )?.sectionId,
  )

  const handleEditToggle = () => {
    setEditMode(!editMode)
    milataryRef?.current?.scrollIntoView()
  }

  return (
    <div id={sectionId} ref={milataryRef}>
      {!editMode ? (
        <>
          <Grid container m={2}>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Military Service
              </Typography>
            </Grid>
            <Grid lg={2} sx={{ float: 'right' }}>
              <Box
                sx={{
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#6f737a',
                  padding: '6px 15px',
                  fontSize: '16px',
                  lineHeight: 1.5,
                }}
              >
                <Button
                  className='EditBtnStyle '
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  Edit
                </Button>
              </Box>
            </Grid>
            <Divider sx={{ width: '97%' }} />
          </Grid>
          {!hasEmpty(data) ? (
            <>
              <Grid container m={2}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        width: '50%',
                      },
                    }}
                  >
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Did applicant serve or is applicant serving in the U.S.
                        military?
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.isServedInMilitary ? 'Yes' : 'No'}
                      </StyledTableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
              {data?.isServedInMilitary ? (
                <>
                  <Grid m={2}>
                    <Grid container m={2}>
                      <>
                        {data?.serviceInformation?.map(
                          (
                            item: {
                              typeOfDuty:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<
                                    any,
                                    string | React.JSXElementConstructor<any>
                                  >
                                | Iterable<React.ReactNode>
                                | React.ReactPortal
                                | null
                                | undefined
                              branchOfService:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<
                                    any,
                                    string | React.JSXElementConstructor<any>
                                  >
                                | Iterable<React.ReactNode>
                                | React.ReactPortal
                                | null
                                | undefined
                              startDate: string | number | Date
                              endDate: string | number | Date
                            },
                            index: any,
                          ) => (
                            <>
                              <Grid lg={6} pr={2} mb={2}>
                                <Paper
                                  key={index}
                                  sx={{
                                    p: 2,
                                    bgcolor: 'transparent',
                                    border: '1px solid #E1E4EB',
                                    boxShadow: 0,
                                  }}
                                >
                                  <Grid container>
                                    <Grid lg={6}>
                                      <Typography
                                        variant='h3'
                                        className='grayFonts'
                                      >
                                        Type of Duty
                                      </Typography>
                                      <Typography variant='h3'>
                                        {item.typeOfDuty}
                                      </Typography>
                                    </Grid>
                                    <Grid lg={6}>
                                      <Typography
                                        variant='h3'
                                        className='grayFonts'
                                      >
                                        Branch of Service
                                      </Typography>
                                      <Typography variant='h3'>
                                        {item.branchOfService}
                                      </Typography>
                                    </Grid>
                                    <Grid pt={2} container>
                                      <Grid lg={6}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Start Date
                                        </Typography>
                                        <Typography variant='h3'>
                                          {' '}
                                          {convertISOToDate(item.startDate)}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={6}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          End Date
                                        </Typography>
                                        <Typography variant='h3'>
                                          {' '}
                                          {item?.endDate?.toString().length !==
                                          0
                                            ? convertISOToDate(item?.endDate)
                                            : 'Presently Working'}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </Grid>
                            </>
                          ),
                        )}
                      </>
                    </Grid>
                  </Grid>
                  <Grid container m={2}>
                    <TableContainer>
                      <Table
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: 'none',
                            width: '50%',
                          },
                        }}
                      >
                        <TableRow>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Receiving or eligible to receive a military or
                            civilian federal agency benefit
                          </StyledTableCell>
                          <StyledTableCell className='MediumBlackFonts'>
                            {data?.isReceivingFederalAgencyBenefit
                              ? 'Yes'
                              : 'No'}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          {data?.isReceivingFederalAgencyBenefit ? (
                            <>
                              <StyledTableCell
                                variant='head'
                                className='grayFonts'
                              >
                                Benefit
                              </StyledTableCell>
                              <StyledTableCell className='MediumBlackFonts'>
                                {data?.typeOfBenefit}
                              </StyledTableCell>
                            </>
                          ) : null}
                        </TableRow>
                      </Table>
                    </TableContainer>
                  </Grid>
                </>
              ) : null}
            </>
          ) : (
            <CustomAlert message='no data available.' />
          )}
        </>
      ) : (
        <Grid m={2}>
          <Grid container m={2}>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Military Service
              </Typography>
            </Grid>
            <Divider
              sx={{ width: '95%', marginTop: '8px', marginBottom: '8px' }}
            />
          </Grid>
          <MilitaryService
            chatForm={chatForm}
            handleEditToggle={handleEditToggle}
          />
        </Grid>
      )}
    </div>
  )
}

export default MillataryService
