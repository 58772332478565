import { Box, Grid, Typography } from '@mui/material'
import remove from 'src/Assets/Icons/remove.svg'
import { SmallRadioBox } from 'src/Components/Application/CommonRadioBox'
import {
  Calander,
  CommonDropdown,
  Name,
  PresentBox,
} from 'src/Components/Fields'
import { FullAddress } from 'src/Components/Fields/Address'
import {
  AddressType,
  EmployerDetailsType,
  EmployerFormType,
} from 'src/Redux/Services/Types/applicationType'
import Style from '../../index.module.scss'
import { useEffect } from 'react'
import { HasAssistant } from '../../..'

const EmployerForm = ({
  formValues,
  values,
  setFormValues,
  handleChange,
  handleRemove,
  index,
  errors,
  isPresent,
  setIsPresent,
}: {
  formValues: EmployerDetailsType
  values: EmployerFormType
  setFormValues: React.Dispatch<React.SetStateAction<EmployerDetailsType>>
  handleChange: (data: {
    name: string
    value: string | boolean | number | AddressType
  }) => void
  handleRemove: () => void
  index: number
  errors: Record<string, string>
  isPresent: boolean
  setIsPresent: (value: boolean) => void
}) => {
  useEffect(() => {
    if (values?.endDate?.length === 0) {
      setIsPresent(true)
    }
  }, [values?.endDate])
  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.checkBoxLabel}>
            Employer {index + 1}
          </Typography>
          {index > 0 && (
            <img
              src={remove}
              alt=''
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleRemove()
              }}
            />
          )}
        </Grid>
        <PresentBox
          Checked={isPresent}
          handleChange={() => {
            setIsPresent(!isPresent)
            handleChange({ name: 'endDate', value: '' })
            handleChange({ name: 'lastPhysicallyWorkedDate', value: '' })
          }}
        />
        <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
          <Name
            handleChange={handleChange}
            name='companyName'
            value={values?.companyName}
            title='Company Name'
            error={errors?.companyName}
          />
          <Name
            handleChange={handleChange}
            name='typeOfBusiness'
            value={values?.typeOfBusiness}
            title='Type of Business'
            error={errors?.typeOfBusiness}
          />
        </Grid>
        <FullAddress
          arrayName='employerDetail'
          formValues={values}
          realValues={formValues}
          setFormValues={setFormValues}
          handleFieldChange={handleChange}
          errors={errors}
          index={index}
        />
        <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
          <Calander
            name='startDate'
            value={values.startDate}
            handleChange={handleChange}
            error={errors.startDate}
            title='Employment Start Date'
          />
          <Calander
            name='endDate'
            value={values.endDate}
            handleChange={handleChange}
            error={errors.endDate}
            title='Employment End Date'
            minDate={values.startDate}
            disabled={isPresent}
          />
        </Grid>
        <SmallRadioBox
          name='isphysicallyWorkedLastDate'
          value={values.isphysicallyWorkedLastDate}
          onClick={(value) => {
            handleChange({ name: 'isphysicallyWorkedLastDate', value })
          }}
          title={
            HasAssistant()
              ? 'Is this end date the last day that applicant physically worked for this company?'
              : 'Is this end date the last day that you physically worked for this company?'
          }
          error={errors.isphysicallyWorkedLastDate}
        />
        {values.isphysicallyWorkedLastDate === false ? (
          <>
            <Calander
              name='lastPhysicallyWorkedDate'
              value={values.lastPhysicallyWorkedDate}
              handleChange={handleChange}
              error={errors.lastPhysicallyWorkedDate}
              title={
                HasAssistant()
                  ? 'What was the last date applicant physically worked?'
                  : 'What was the last date you physically worked?'
              }
              minDate={values.startDate}
              disabled={isPresent}
            />
          </>
        ) : (
          <></>
        )}
        <Name
          handleChange={handleChange}
          name='jobTitle'
          value={values?.jobTitle}
          title='Job Title'
          error={errors?.jobTitle}
        />
        <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
          <CommonDropdown
            handleChange={handleChange}
            label='Average Hours Per Day Worked'
            menulist={Array(24)
              .fill(0)
              .map((_, i) => {
                return { name: `${i + 1}`, value: i + 1 }
              })}
            name='averageHourPerDay'
            value={values?.averageHourPerDay ?? 0}
            error={errors?.averageHourPerDay}
          />
          <CommonDropdown
            handleChange={handleChange}
            label='Average Days Per Week Worked'
            menulist={Array(7)
              .fill(0)
              .map((_, i) => {
                return { name: `${i + 1}`, value: i + 1 }
              })}
            name='averageDaysPerWeek'
            value={values?.averageDaysPerWeek ?? 0}
            error={errors?.averageDaysPerWeek}
          />
        </Grid>
        <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
          <Name
            handleChange={handleChange}
            name='payAmountPerPayPeriod'
            value={values?.payAmountPerPayPeriod}
            title='Pay Amount Per Pay Period (US dollars)'
            error={errors?.payAmountPerPayPeriod}
            preInput='$'
            number
          />
          <CommonDropdown
            handleChange={handleChange}
            menulist={[
              { name: 'Hourly', value: 'Hourly' },
              { name: 'Daily', value: 'Daily' },
              { name: 'Weekly', value: 'Weekly' },
              { name: 'Bi-Monthly', value: 'Bi-Monthly' },
              { name: 'Monthly', value: 'Monthly' },
              { name: 'Quarterly', value: 'Quarterly' },
              { name: 'Annually', value: 'Annually' },
            ]}
            label='Pay Frequency'
            name='payFrequency'
            value={values?.payFrequency ?? ''}
            error={errors?.payFrequency}
          />
        </Grid>
      </Box>
    </>
  )
}

export default EmployerForm
