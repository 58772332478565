import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider } from '@mui/material'
import Styles from '../index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import MedicalProfessionals from '../EditForms/MedicalProfessionals'
import { convertISOToDate, hasEmpty } from 'src/Utils/Constants'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'

const MedicalDetails: React.FunctionComponent = ({
  chatForm,
}: {
  chatForm?: any
}) => {
  const [editMode, setEditMode] = useState(false)
  const medicalProfessionRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    medicalProfessionRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.MedicalDetails?.MedicalProfessionalDetail,
  )

  console.log('data', data)

  const medicalProfessionalsSectionId = useAppSelector(
    (state) =>
      state?.steps?.MedicalApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Medical Professionals',
      )?.sectionId,
  )
  interface MedicalProfessional {
    suffix: string
    doctorFirstName: string
    doctorLastName: string
    doctorMedicalSpecialty: string
    officeOrClinicName: string
    address: {
      streetAddress: string
      city: string
      state: string
      postalCode: string
      country: string
    }
    phoneNumber: string
    firstAppointmentDate: string
    lastAppointmentDate: string
    nextAppointmentDate: string
    treatmentType: string[]
    typeOfTherapyDoctorProvided: string[]
    otherTreatment: string
  }

  return (
    <div id={medicalProfessionalsSectionId} ref={medicalProfessionRef}>
      {!editMode ? (
        <>
          <Box className={Styles.MainBox}>
            <Box className={Styles.title}>
              <Box className={Styles.main}>
                <Typography variant='h6' className={Styles.textWrapper}>
                  Medical Professionals
                </Typography>
                <Button
                  className={Styles.button}
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  <Typography className={Styles.div}>Edit</Typography>
                </Button>
              </Box>
              <div className={Styles.dottedLine} />
            </Box>

            {!hasEmpty(data) ? (
              <>
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Seen a medical professional or been treated for any
                        mental illnesses, injuries, or conditions since
                        applicant has been unable to work
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.isMentalIllness ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                  {data?.isMentalIllness ? (
                    <>
                      <Box className={Styles.div2}>
                        {/* <Typography variant='h6' className={Styles.SubTitle}>
                        Added medical professionals
                      </Typography> */}
                      </Box>
                      {/* card-1 */}
                      {data?.mentalIllnessMedicalProfessionals?.map(
                        (item: MedicalProfessional, index: number) => (
                          <>
                            <Box className={Styles.card} key={index}>
                              <Box className={Styles.cardDiv}>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Medical Professional's Name
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {` ${item?.suffix}  ${item?.doctorFirstName} ${item?.doctorLastName} `}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Medical Specialty
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.doctorMedicalSpecialty}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      ></Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      ></Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Office or clinic name
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.officeOrClinicName}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Street Address
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.streetAddress}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        City/Town
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.city}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        State
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.state}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Zip Code
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.postalCode}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Country
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.address?.country}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Phone number
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {item?.phoneNumber}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated First Appointment Date
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(
                                          item?.firstAppointmentDate,
                                        )}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Estimated Last Appointment Date
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(
                                          item?.lastAppointmentDate,
                                        )}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Date (or estimate) of Next Appointment
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper3}
                                      >
                                        {convertISOToDate(
                                          item?.nextAppointmentDate,
                                        )}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <div className={Styles.dottedLine} />
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Treatment that applicant received from
                                        this medical professional
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      {item?.treatmentType?.map(
                                        (item: string) => (
                                          <>
                                            <Typography
                                              variant='body1'
                                              className={Styles.textWrapper4}
                                            >
                                              {item}
                                            </Typography>
                                          </>
                                        ),
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                                {item?.typeOfTherapyDoctorProvided?.length >
                                  0 && (
                                  <Grid container className={Styles.cardFrame}>
                                    <Grid
                                      item
                                      xs={4}
                                      className={Styles.cardElement}
                                    >
                                      <Box className={Styles.cardQuestion}>
                                        <Typography
                                          variant='body1'
                                          className={Styles.cardTextWrapper}
                                        >
                                          Type of therapy this medical
                                          professional provide or order
                                        </Typography>
                                      </Box>
                                      <Box className={Styles.cardAns}>
                                        {item?.typeOfTherapyDoctorProvided?.map(
                                          (item: string) => (
                                            <>
                                              <Typography
                                                variant='body1'
                                                className={Styles.textWrapper4}
                                              >
                                                {item}
                                              </Typography>
                                            </>
                                          ),
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )}
                                {item.typeOfTherapyDoctorProvided?.find(
                                  (i) =>
                                    i === 'Other' && (
                                      <Grid
                                        container
                                        className={Styles.cardFrame}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          className={Styles.cardElement}
                                        >
                                          <Box className={Styles.cardQuestion}>
                                            <Typography
                                              variant='body1'
                                              className={Styles.cardTextWrapper}
                                            >
                                              Other type of therapy
                                            </Typography>
                                          </Box>
                                          <Box className={Styles.cardAns}>
                                            <Typography
                                              variant='body1'
                                              className={
                                                Styles.cardTextWrapper2
                                              }
                                            >
                                              {item?.otherTreatment}
                                            </Typography>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    ),
                                )}
                              </Box>
                            </Box>
                          </>
                        ),
                      )}
                    </>
                  ) : null}
                  <Box className={Styles.element2}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.textWrapper3}>
                        Seen a medical professional or been treated for any
                        physical illnesses, injuries, or conditions since
                        applicant has been unable to work
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.isPhysicalIllness ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {data?.isPhysicalIllness ? (
                  <>
                    <Box className={Styles.div2}>
                      {/* <Typography variant='h6' className={Styles.SubTitle}>
                        Added medical professionals
                      </Typography> */}
                    </Box>
                    {/* card-1 */}
                    {data?.physicalIllnessMedicalProfessionals?.map(
                      (item: MedicalProfessional, index: number) => (
                        <>
                          <Box className={Styles.card} key={index}>
                            <Box className={Styles.cardDiv}>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Medical Professional's Name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {` ${item?.suffix}  ${item?.doctorFirstName} ${item?.doctorLastName} `}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Medical Specialty
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.doctorMedicalSpecialty}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Office or clinic name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.officeOrClinicName}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Street Address
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.streetAddress}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      City/Town
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.city}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      State
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.state}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Zip Code
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.postalCode}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Country
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.address?.country}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Phone number
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.phoneNumber}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <div className={Styles.dottedLine} />
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Estimated First Appointment Date
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(
                                        item?.firstAppointmentDate,
                                      )}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Estimated Last Appointment Date
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(
                                        item?.lastAppointmentDate,
                                      )}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Date (or estimate) of Next Appointment
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(
                                        item?.nextAppointmentDate,
                                      )}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <div className={Styles.dottedLine} />
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Treatment that applicant received from
                                      this medical professional
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    {item?.treatmentType?.map(
                                      (item: string) => (
                                        <>
                                          <Typography
                                            variant='body1'
                                            className={Styles.textWrapper4}
                                          >
                                            {item}
                                          </Typography>
                                        </>
                                      ),
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                              {item?.typeOfTherapyDoctorProvided?.length >
                                0 && (
                                <Grid container className={Styles.cardFrame}>
                                  <Grid
                                    item
                                    xs={4}
                                    className={Styles.cardElement}
                                  >
                                    <Box className={Styles.cardQuestion}>
                                      <Typography
                                        variant='body1'
                                        className={Styles.cardTextWrapper}
                                      >
                                        Type of therapy this medical
                                        professional provide or order
                                      </Typography>
                                    </Box>
                                    <Box className={Styles.cardAns}>
                                      {item?.typeOfTherapyDoctorProvided?.map(
                                        (item: string) => (
                                          <>
                                            <Typography
                                              variant='body1'
                                              className={Styles.textWrapper4}
                                            >
                                              {item}
                                            </Typography>
                                          </>
                                        ),
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                              )}
                              {item.typeOfTherapyDoctorProvided?.find(
                                (i) =>
                                  i === 'Other' && (
                                    <Grid
                                      container
                                      className={Styles.cardFrame}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={Styles.cardElement}
                                      >
                                        <Box className={Styles.cardQuestion}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper}
                                          >
                                            Other type of therapy
                                          </Typography>
                                        </Box>
                                        <Box className={Styles.cardAns}>
                                          <Typography
                                            variant='body1'
                                            className={Styles.cardTextWrapper2}
                                          >
                                            {item?.otherTreatment}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  ),
                              )}
                            </Box>
                          </Box>
                        </>
                      ),
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Medical Professionals
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <MedicalProfessionals
                    chatForm={chatForm}
                    handleEditToggle={handleEditToggle}
                  />
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default MedicalDetails
