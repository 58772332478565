import {
  Box,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import {
  fetchPlaceDetails,
  updateMailingAddressInFormValues,
} from 'src/Components/Application/CommonAddress'
import { ContactInformationType } from 'src/Redux/Services/Types/applicationType'
import Style from '../index.module.scss'
import { ZipcodeField } from 'src/Components/Fields'

interface Place {
  id: string
  description: string
}
const itemStyle = {
  xs: 12,
  sm: 12,
  md: 12,
}

const marginStyle = {
  mr: { md: 2, xs: 2 },
}

const MailingAddress = ({
  formValues,
  setFormValues,
  errors,
  handleFieldChange,
}: {
  formValues: ContactInformationType
  setFormValues: React.Dispatch<React.SetStateAction<ContactInformationType>>
  errors: Record<string, string>
  handleFieldChange: ({ name, value }: { name: string; value: string }) => void
}) => {
  const physicalAddressinputRef: React.MutableRefObject<undefined> = useRef()

  const [predictions, setPredictions] = useState<Place[]>([])

  const autocomplete = (query: string) => {
    if (query.length === 0) {
      setPredictions([])
      return
    }

    const autocompleteService = new google.maps.places.AutocompleteService()

    autocompleteService.getPlacePredictions(
      { input: query },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const placePredictions: Place[] = predictions.map((prediction) => {
            return {
              description: prediction.description,
              id: prediction.place_id,
            }
          })
          setPredictions(placePredictions)
        }
      },
    )
  }

  const handlePredictionClick = (prediction: Place) => {
    fetchPlaceDetails(
      prediction.id,
      formValues.mailingAddress,
      (mailingAddressData) => {
        updateMailingAddressInFormValues(
          formValues,
          mailingAddressData,
          setFormValues,
        )
      },
    )
    setPredictions([])
  }

  const handleAddressChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target
    if (value.length) {
      autocomplete(value) // Ensure this function is not causing issues
    } else {
      // setFormValues(initialObj);
      setPredictions([])
    }
    setFormValues({
      ...formValues,
      mailingAddress: {
        ...formValues?.mailingAddress,
        [name]: value,
      },
    })
  }

  useEffect(() => {
    if (!formValues?.mailingAddress?.streetAddress) {
      setPredictions([])
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        mailingAddress: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
      }))
    }
  }, [formValues.mailingAddress?.streetAddress])

  return (
    <Box my={'10px'}>
      <Typography className={Style.label}>
        We completely understand! Let’s keep applicant's communications private.
        What's applicant's preferred mailing address?
      </Typography>
      <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
        <Grid width={'100%'}>
          <InputLabel className={Style.label}>
            Street Address <span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <TextField
            inputRef={physicalAddressinputRef}
            fullWidth
            type='text'
            id='streetAddress'
            name='streetAddress'
            className={Style.AnsTxt}
            placeholder='Street Address'
            value={formValues?.mailingAddress?.streetAddress}
            // onChange={(e) => {handleFieldChange({name:e.target.name, value:e.target.value})}}
            onChange={handleAddressChange}
            error={!!errors.address}
            helperText={errors.address}
          />
          {predictions.length ? (
            <Grid item md={12} xs={12} className='form-row-item'>
              <Grid
                item
                {...itemStyle}
                {...marginStyle}
                className={Style.listBox}
              >
                {predictions.map((prediction, index) => (
                  <>
                    <div
                      key={index}
                      className={Style.listItem}
                      onClick={() => {
                        handlePredictionClick(prediction)
                      }}
                    >
                      {prediction.description}
                    </div>
                    <Divider />
                  </>
                ))}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <Grid width={'100%'}>
          <InputLabel className={Style.label}>
            City/Town <span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <TextField
            fullWidth
            type='text'
            id='city'
            name='city'
            className={Style.AnsTxt}
            placeholder='City/Town'
            value={formValues?.mailingAddress?.city}
            onChange={(e) => {
              handleFieldChange({ name: e.target.name, value: e.target.value })
            }}
            error={!!errors.city}
            helperText={errors.city}
          />
        </Grid>
      </Grid>
      <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
        <Grid width={'100%'}>
          <InputLabel className={Style.label}>
            State <span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <TextField
            fullWidth
            type='text'
            id='state'
            name='state'
            className={Style.AnsTxt}
            placeholder='State'
            value={formValues?.mailingAddress?.state}
            onChange={(e) => {
              handleFieldChange({ name: e.target.name, value: e.target.value })
            }}
            error={!!errors.state}
            helperText={errors.state}
          />
        </Grid>
        <ZipcodeField
          postalCode={formValues.mailingAddress?.postalCode}
          handleChange={handleFieldChange}
          error={errors?.postalCode}
        />
        <Grid width={'100%'}>
          <InputLabel className={Style.label}>
            Country <span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <TextField
            fullWidth
            type='text'
            id='country'
            name='country'
            className={Style.AnsTxt}
            placeholder='Country'
            value={formValues?.mailingAddress?.country}
            onChange={(e) => {
              handleFieldChange({ name: e.target.name, value: e.target.value })
            }}
            error={!!errors.country}
            helperText={errors.country}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default MailingAddress
