import {
  Autocomplete,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import remove from 'src/Assets/Icons/remove.svg'
import { PassApplicationType } from 'src/Redux/Services/Types/applicationType'
import { IllnessesInjuriesCondition } from 'src/Utils/Constants'
import { medicationArray } from 'src/Utils/Constants/medications'
import styled from 'styled-components'
import Style from './index.module.scss'
import { Name } from 'src/Components/Fields'

interface medicalProfessionalsType {
  suffix: string
  medicationName: string
  doctorFirstName: string
  doctorLastName: string
  medicationReason: string
  medicationOther: string
  sideEffects: string
}

const StyledSelect = styled(Select)({
  borderRadius: '10px',
})

const DetailBox = ({
  formValues,
  realValue,
  setFormValues,
  changeHandler,
  handleRemove,
  index,
  errors,
}: {
  formValues: medicalProfessionalsType
  realValue: PassApplicationType
  setFormValues: React.Dispatch<React.SetStateAction<PassApplicationType>>
  changeHandler: (data: { name: string; value: string }) => void
  handleRemove: () => void
  index: number
  errors: Record<string, string>
}) => {
  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.label}>Profile {index + 1}</Typography>
          <img
            src={remove}
            alt=''
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleRemove()
            }}
          />
        </Grid>

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Medication<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <Autocomplete
              disablePortal
              fullWidth
              value={formValues?.medicationName}
              id='combo-box-demo'
              onChange={(e, value) => {
                changeHandler({ name: 'medicationName', value: value ?? '' })
              }}
              options={medicationArray.map((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors?.medicationName}
                  helperText={errors?.medicationName}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Prefix<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <StyledSelect
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              className={Style.AnsTxt}
              IconComponent={(props) => (
                <IconButton {...props}>
                  <img src={IconChevronDown} alt='IconChevronDown' />
                </IconButton>
              )}
              displayEmpty
              renderValue={
                formValues?.suffix !== ''
                  ? () => formValues?.suffix
                  : () => 'Select'
              }
              value={formValues?.suffix}
              fullWidth
              id='suffix'
              name='suffix'
              error={!!errors?.suffix}
            >
              <MenuItem value=''>
                <em>Select an Answer</em>
              </MenuItem>
              <MenuItem value='Dr.'>Dr.</MenuItem>
              <MenuItem value='Ms.'>Ms.</MenuItem>
              <MenuItem value='Mrs.'>Mrs.</MenuItem>
              <MenuItem value='Mr.'>Mr.</MenuItem>
            </StyledSelect>
            <FormHelperText sx={{ mx: 1.5 }}>{errors?.suffix}</FormHelperText>
          </Grid>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Medical Professional's First Name
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='doctorFirstName'
              name='doctorFirstName'
              placeholder='Enter First Name'
              className={Style.AnsTxt}
              value={formValues?.doctorFirstName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.doctorFirstName}
              helperText={errors?.doctorFirstName}
            />
          </Grid>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Medical Professional's Last Name
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='doctorLastName'
              name='doctorLastName'
              placeholder='Enter Last Name'
              className={Style.AnsTxt}
              value={formValues?.doctorLastName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.doctorLastName}
              helperText={errors?.doctorLastName}
            />
          </Grid>
        </Grid>
        <Grid width={'100%'}>
          <InputLabel className={Style.label}>
            Select the illness, injury, or condition for which the medicine is
            taken<span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <StyledSelect
            onChange={(e) => {
              changeHandler({ name: e.target.name, value: e.target.value })
            }}
            className={Style.AnsTxt}
            IconComponent={(props) => (
              <IconButton {...props}>
                <img src={IconChevronDown} alt='IconChevronDown' />
              </IconButton>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Adjust the value as needed
                },
              },
            }}
            renderValue={
              formValues?.medicationReason !== ''
                ? () => formValues?.medicationReason
                : () => 'Select'
            }
            value={formValues?.medicationReason}
            fullWidth
            id='medicationReason'
            name='medicationReason'
            error={!!errors?.medicationReason}
          >
            <MenuItem value=''>
              <em>Select an Answer</em>
            </MenuItem>
            {IllnessesInjuriesCondition.map((item) => (
              <MenuItem key={item.value} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </StyledSelect>
          <FormHelperText sx={{ mx: 1.5 }}>
            {errors?.medicationReason}
          </FormHelperText>
        </Grid>

        {formValues?.medicationReason === 'Other' && (
          <>
            <Name
              name='medicationOther'
              value={formValues?.medicationOther}
              title='Enter Other Illness, Injury, or Condition'
              handleChange={changeHandler}
              error={errors?.medicationOther}
              multiline
            />
          </>
        )}

        <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              If applicant experience any side effects due to this medication,
              please describe those below:
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='sideEffects'
              name='sideEffects'
              multiline
              rows={4}
              className={Style.AnsTxt}
              placeholder='Other'
              value={formValues?.sideEffects}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.sideEffects}
              helperText={errors?.sideEffects}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
export default DetailBox
