export enum TAB_DATA {
  BASIC = 'Basic',
  FAMILY = 'Family',
  LEGAL_NAMES_DOCUMENTATION = 'Legal Names & Documentation',
  DISABLILLIY = 'Disability',
  EDUCATION_EMPLOYMENT = 'Education & Employment',
  MEDICAL = 'Medical',
  UPLOADED_DOCUMENTATION = 'Uploaded Documentation',
  SIGNED_CONSENT_FORMS = 'Signed Consent Forms',
  SUBMIT = 'Scan',
}

export enum FLAG_TAB_DATA {
  BASIC = 'BasicDetail',
  FAMILY = 'FamilyDetail',
  LEGAL_NAMES_DOCUMENTATION = '',
  DISABLILLIY = '',
  EDUCATION_EMPLOYMENT = 'EducationAndEmploymentDetail',
  MEDICAL = 'MedicalDetail',
  UPLOADED_DOCUMENTATION = '',
  SIGNED_CONSENT_FORMS = '',
  SUBMIT = '',
}

export function getTabData(sectionName: string): TAB_DATA {
  switch (sectionName) {
    case FLAG_TAB_DATA.BASIC:
      return TAB_DATA.BASIC
    case FLAG_TAB_DATA.FAMILY:
      return TAB_DATA.FAMILY
    case FLAG_TAB_DATA.LEGAL_NAMES_DOCUMENTATION:
      return TAB_DATA.LEGAL_NAMES_DOCUMENTATION
    case FLAG_TAB_DATA.DISABLILLIY:
      return TAB_DATA.DISABLILLIY
    case FLAG_TAB_DATA.EDUCATION_EMPLOYMENT:
      return TAB_DATA.EDUCATION_EMPLOYMENT
    case FLAG_TAB_DATA.MEDICAL:
      return TAB_DATA.MEDICAL
    case FLAG_TAB_DATA.UPLOADED_DOCUMENTATION:
      return TAB_DATA.UPLOADED_DOCUMENTATION
    case FLAG_TAB_DATA.SIGNED_CONSENT_FORMS:
      return TAB_DATA.SIGNED_CONSENT_FORMS
    case FLAG_TAB_DATA.SUBMIT:
      return TAB_DATA.SUBMIT
    default:
      return TAB_DATA.BASIC // Handle default case accordingly
  }
}
