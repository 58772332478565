import React, { useState } from 'react'
import { Box, Typography, Button, Grid } from '@mui/material'
import Styles from './index.module.scss'
import star from 'src/Assets/Icons/star.svg'
import DownloadPopUp from 'src/Components/DownloadPopUp'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { AddFinalSubmit } from 'src/Redux/Slices/ApplicationSlices/FinalSubmitFormsSlice'
import { getApplicationDetail } from 'src/Redux/Slices/ApplicationSlices/allApplicationSlice'
import download from 'src/Assets/Icons/download.svg'
import ApplicationData from '../ApplicationData'
import { RootState } from 'src/Redux/store'
import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { DynamicObj } from 'src/Utils/Constants'

const FinalSubmit: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const applicationId: string = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.applicationId,
  )

  const clintName = useAppSelector(
    (state) => state.applicationStore?.BasicDetails?.ContactInformation,
  )

  const disqualification = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.disqualification,
  )

  const flagdata = useAppSelector(
    (state: RootState) => state?.applicationStore?.BasicDetails?.flagdata,
  )

  const isDisabled =
    disqualification &&
    Object.values(disqualification).some((value) => value === true)

  const headerData = useAppSelector(
    (state) =>
      state.applicationStore?.AllApplications?.data?.find(
        (record: DynamicObj) => record.uuid === applicationId,
      ) || {},
  )

  const applicationComplete = parseFloat(headerData?.applicationComplete || '0')

  // [
  //   {
  //     flag: 'green',
  //     remark: [],
  //   }
  // ]

  const handleSubmit = () => {
    // Convert applicationComplete to a number and check if it's 100
    if (applicationComplete === 100) {
      dispatch(AddFinalSubmit({ applicationId }))
    } else {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Application is not complete. Please complete it before submitting.',
        }),
      )
    }
  }

  const handleOpen = () => {
    // setOpen(true)
    if (applicationComplete === 100) {
      if (
        flagdata.flag === 'green' &&
        Array.isArray(flagdata.remark) &&
        flagdata.remark.length === 0
      ) {
        setOpen(true)
      } else {
        dispatch(
          showNotification({
            type: NotificationType.Error,
            message:
              'You have a flag or remark in applicant‘s application. Please clear it before downloading.',
          }),
        )
      }
    } else {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Application is not complete. Please complete it before downloading.',
        }),
      )
    }
  }

  const [question, setQuestion] = useState(ApplicationData)

  const getNestedValue = (obj: any, path: string) => {
    const keys = path.split('.')
    let current = obj
    for (const key of keys) {
      if (key.includes('[')) {
        const arrayKey = key.slice(0, key.indexOf('['))
        const index = key.slice(key.indexOf('[') + 1, key.indexOf(']'))
        current = current?.[arrayKey]?.[index]
      } else {
        current = current?.[key]
      }
      if (current === undefined || current === null || current === '') {
        return 'N/A'
      }
    }
    return current
  }

  const updateAnswers = (sections: any, apiResponse: any) => {
    // return sections.map(section => {
    return Object.entries(sections).reduce((acc, [sectionName, questions]) => {
      console.log(sectionName, questions)

      const updatedQuestions = questions.map((q: any) => ({
        ...q,
        answer: getNestedValue(apiResponse, q.answerPath),
      }))
      return { ...acc, [sectionName]: updatedQuestions }
    }, {})
    // });
  }

  const newArray = (response: any) => {
    const keys = Object.keys(question)
    const updatedQuestions = keys.reduce((acc, curr) => {
      const updatedSection = updateAnswers(question[curr], response)
      return { ...acc, [curr]: updatedSection }
    }, {})
    setQuestion((prevState) => ({ ...prevState, ...updatedQuestions }))
  }

  const handleDownloadSubmit = () => {
    if (applicationComplete === 100) {
      if (
        flagdata.flag === 'green' &&
        Array.isArray(flagdata.remark) &&
        flagdata.remark.length === 0
      ) {
        dispatch(getApplicationDetail(applicationId)).then((res) => {
          const response = res?.payload?.data
          if (response) {
            newArray(response)
          }
        })
      }
    }
  }

  return (
    <>
      <Box className={Styles.right}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={Styles.list}>
              <Typography variant='body1' className={Styles.textWrapper}>
                Congratulations on completing the initial disability benefits
                application! We encourage you to run a BenefitsBoost AI-powered
                scan to identify any areas for potential improvement. Carefully
                review any flagged sections to ensure maximum accuracy and
                comprehension. Once you're confident in the application,
                download the finalized document to seamlessly proceed with the
                SSA submission and advance your client's case with confidence.
              </Typography>
              {/* <Box className={Styles.alert}>
                <Box className={Styles.frame}>
                  <img src={star} alt='star' className={Styles.image} />
                </Box>
                <Typography
                  variant='body1'
                  className={Styles.readyToSubmitYour}
                >
                  Please reach out to your representative should you have any
                  questions beyond this point. Thank you! step.
                </Typography>
              </Box> */}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={Styles.main}>
              <Box className={Styles.action}>
                <Button
                  variant='contained'
                  onClick={handleSubmit}
                  disabled={isDisabled}
                  className={Styles.divWrapper}
                >
                  <Typography variant='button' className={Styles.textWrapper2}>
                    Run BenefitsBoost AI scan
                  </Typography>
                </Button>
                <Button
                  onClick={() => {
                    handleOpen()
                    handleDownloadSubmit()
                  }}
                  disabled={isDisabled}
                  variant='contained'
                  className={Styles.button2}
                >
                  <Typography
                    variant='button'
                    sx={{ cursor: 'pointer' }}
                    className={Styles.textWrapper3}
                  >
                    Download & Review
                  </Typography>
                  <img src={download} alt='star' className={Styles.image} />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <DownloadPopUp
        open={open}
        setOpen={setOpen}
        handleOpen={handleOpen}
        applicationId={applicationId}
        question={question}
        clintName={clintName}
      />
    </>
  )
}

export default FinalSubmit
