import {
  Box,
  type LinearProgressProps,
  LinearProgress,
  styled,
} from '@mui/material'
import React from 'react'
import Location from 'src/Assets/Icons/location.svg'
import Users from 'src/Assets/Icons/Users.svg'
import mail from 'src/Assets/Icons/email.svg'
import phone from 'src/Assets/Icons/phone.svg'
import Pending from 'src/Assets/Icons/Pending.svg'
import styles from './index.module.scss'
import { DynamicObj } from 'src/Utils/Constants'
const Cards: React.FunctionComponent<{
  row: {
    name: string
    applicant: string
    ssnNumber: {
      socialSecurityNumber: string
    }
    applicationComplete: number
    basicInfo: {
      emailId: string
      phoneNumber: string
      firstName: string
      address: {
        streetAddress: string
      }
      mailAddress?: {
        streetAddress: string
      }
    }
    status: string
  }
}> = ({ row }) => {
  const CustomLinearProgress = styled(LinearProgress)(() => ({
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#7752FE', // Change the background color as needed
    },
  }))
  const LinearProgressWithLabel = (
    props: LinearProgressProps & { value: number },
  ) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <CustomLinearProgress variant='determinate' {...props} />
        </Box>
      </Box>
    )
  }

  function renderContactInfoPart(
    part: string | null,
    separator: string = ' ',
  ): string | null {
    return part ? `${part}${separator}` : ' ' // Concatenate the part with the separator
  }

  function renderContactInfo(data: DynamicObj) {
    const { firstName, middleName, lastName } = data

    const fullName = `${renderContactInfoPart(firstName)}${renderContactInfoPart(middleName)}${renderContactInfoPart(lastName)}`

    return fullName
  }

  function renderAddress(address: Record<string, string>) {
    const { streetAddress, city, state, country, postalCode } = address

    const formattedAddress = `${renderContactInfoPart(streetAddress, ',')}${renderContactInfoPart(city, ',')}${renderContactInfoPart(state, ',')}${renderContactInfoPart(country, ',')}${renderContactInfoPart(postalCode)}`

    return formattedAddress
  }

  return (
    <>
      <div className={styles.manageEmployeeCard}>
        <div className={styles.frame}>
          <div className={styles.div2}>
            <div className={styles.title}>
              <div className={styles.textWrapper}>{row?.applicant}</div>
              {row?.ssnNumber?.socialSecurityNumber && (
                <>
                  <div className={styles.rectangle} />
                  <div className={styles.textWrapper2}>
                    SSN : {row?.ssnNumber?.socialSecurityNumber}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.div3}>
              <img src={Users} className={styles.iconInstanceNode} />
              <div className={styles.textWrapper3}>
                {renderContactInfo(row?.basicInfo)}
              </div>
            </div>
            {(row?.basicInfo?.address ?? row?.basicInfo?.mailAddress) && (
              <div className={styles.div3}>
                <img src={Location} className={styles.iconInstanceNode} />
                <p className={styles.textWrapper3}>
                  {renderAddress(
                    row?.basicInfo?.address
                      ? row?.basicInfo?.address
                      : row?.basicInfo?.mailAddress,
                  )}
                </p>
              </div>
            )}
            <div className={styles.frame2}>
              {row?.basicInfo?.phoneNumber && (
                <div className={styles.email}>
                  <img src={phone} className={styles.iconInstanceNode} />
                  <div className={styles.textWrapper3}>
                    ({row?.basicInfo?.phoneNumber.slice(0, 3)}){' '}
                    {row?.basicInfo?.phoneNumber.slice(3, 6)}-
                    {row?.basicInfo?.phoneNumber.slice(6)}
                  </div>
                </div>
              )}
              {row?.basicInfo?.emailId && (
                <div className={styles.email}>
                  <img src={mail} className={styles.iconInstanceNode} />
                  <div className={styles.textWrapper3}>
                    {row?.basicInfo?.emailId}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.rectangle2} />
          <Box className={styles.div2} gap={1}>
            {/* <img className={styles.user} alt='User' src={employee} /> */}
            <div className={styles.info2}>
              <div className={styles.divWrapper}>
                <div className={styles.textWrapper4}>
                  Application Completion
                </div>
              </div>
              <div className={styles.bar}>
                <div className={styles.holder} />
              </div>
            </div>
            <Box
              className={styles.textWrapper5}
              sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
            >
              <div className={styles.frame4}>
                {row?.applicationComplete ?? 0}%
              </div>
              <Box className={styles.frame3}>
                <img src={Pending} alt='' />
                {row?.status === 'InProgress' ? 'In Progress' : row?.status}
              </Box>
            </Box>
            <LinearProgressWithLabel value={row?.applicationComplete ?? 0} />
          </Box>
        </div>
      </div>
    </>
  )
}
export default Cards
