import React from 'react'
import GeneralHelth from './GeneralHelth'
import VisionAndHearing from './VisionAndHearing'
import IllnessAndInjuries from './IllnessAndInjuries'
import MedicalProfessional from './MedicalProfessional'
import MedicalFacilityCare from './MedicalFacilityCare'
import MedicalTest from './MedicalTest'
import Medicine from './Medicine'
import AdditionalInfo from './AdditionalInfo'

const MedicalDetails: React.FunctionComponent = ({
  chatForm,
}: {
  chatForm?: any
}) => {
  return (
    <>
      <GeneralHelth chatForm={chatForm} />
      <VisionAndHearing chatForm={chatForm} />
      <IllnessAndInjuries chatForm={chatForm} />
      <MedicalProfessional chatForm={chatForm} />
      <MedicalFacilityCare chatForm={chatForm} />
      <MedicalTest chatForm={chatForm} />
      <Medicine chatForm={chatForm} />
      <AdditionalInfo chatForm={chatForm} />
    </>
  )
}

export default MedicalDetails
