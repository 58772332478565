import SideSteps from 'src/Components/Application/Sidebar'
import BasicDetails from 'src/Components/Application/CustomApplication/BasicDetails'
import FamilyDetails from 'src/Components/Application/CustomApplication/FamilyDetails'
import LegalDetails from 'src/Components/Application/CustomApplication/LegalDetails'
import DisabilityDetails from 'src/Components/Application/CustomApplication/DisabilityDetails'
import EducationDetails from 'src/Components/Application/CustomApplication/EducationEmployment'
import MedicalDetails from 'src/Components/Application/CustomApplication/MedicalDetails'
import UploadDocument from 'src/Components/Application/CustomApplication/UploadDocuments'
import SignConsentForm from 'src/Components/Application/CustomApplication/SignConsentForms'
import Submit from 'src/Components/Application/CustomApplication/Submit'
import { useAppSelector } from 'src/Redux/hooks'

function HasAssistant() {
  return Boolean(
    useAppSelector(
      (state) => state?.applicationStore?.BasicDetails?.assistancedata.uuid,
    ),
  )
}
export {
  SideSteps,
  BasicDetails,
  FamilyDetails,
  LegalDetails,
  DisabilityDetails,
  EducationDetails,
  MedicalDetails,
  UploadDocument,
  SignConsentForm,
  Submit,
  HasAssistant,
}
