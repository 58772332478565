import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import ActiveRadio from 'src/Assets/Icons/activeRadio.svg'
import InactiveRadio from 'src/Assets/Icons/inactiveRadio.svg'
import remove from 'src/Assets/Icons/remove.svg'
import { FullAddress } from 'src/Components/Fields/Address'
import { PassApplicationType } from 'src/Redux/Services/Types/applicationType'
import Style from './index.module.scss'

interface participateProgramDetailsType {
  organizationName: string
  description: string
  address: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  }
  contactName: string
  phone: string
  estimatedDate: string
  stillParticipatedInProgram: boolean | undefined
}

const DetailBox = ({
  formValues,
  realValue,
  setFormValues,
  changeHandler,
  handleRemove,
  index,
  participatedProgram,
  setparticipatedProgram,
  errors,
}: {
  formValues: participateProgramDetailsType
  realValue: PassApplicationType
  setFormValues: React.Dispatch<React.SetStateAction<PassApplicationType>>
  changeHandler: (data: { name: string; value: string }) => void
  handleRemove: () => void
  index: number
  participatedProgram: boolean | undefined
  setparticipatedProgram: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
  errors: Record<string, string>
}) => {
  const [commonStateFake, setcommonStateFake] = useState<boolean | undefined>()
  useEffect(() => {
    if (typeof participatedProgram === 'boolean') {
      setcommonStateFake(participatedProgram)
    }
  }, [participatedProgram])
  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.label}>Profile {index + 1}</Typography>
          {index > 0 && (
            <img
              src={remove}
              alt=''
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleRemove()
              }}
            />
          )}
        </Grid>

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Organization name<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='organizationName'
              name='organizationName'
              placeholder='Organization name'
              className={Style.AnsTxt}
              value={formValues?.organizationName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.organizationName}
              helperText={errors?.organizationName}
            />
          </Grid>
        </Grid>

        <FullAddress
          arrayName='participateProgramDetails'
          realValues={realValue}
          formValues={formValues}
          handleFieldChange={changeHandler}
          index={index}
          setFormValues={setFormValues}
          errors={errors}
        />

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Contact Person's Name
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='contactName'
              name='contactName'
              placeholder='Contact person name'
              className={Style.AnsTxt}
              value={formValues?.contactName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.contactName}
              helperText={errors?.contactName}
            />
          </Grid>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Phone number<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              id='phone'
              name='phone'
              placeholder='Phone number'
              inputProps={{ maxLength: 10 }}
              className={Style.AnsTxt}
              value={formValues?.phone}
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  changeHandler({ name: e.target.name, value: e.target.value })
                }
              }}
              error={!!errors?.phone}
              helperText={errors?.phone}
            />
          </Grid>
        </Grid>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Estimated date that applicant started the plan or program
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: '100%' }}
                value={
                  formValues?.estimatedDate
                    ? dayjs(formValues?.estimatedDate)
                    : null
                } // Convert to Dayjs object
                onChange={(e) => {
                  changeHandler({
                    name: 'estimatedDate',
                    value: dayjs(e).format('YYYY-MM-DD'),
                  })
                }}
                maxDate={dayjs()} // Use Dayjs for maxDate as well
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ width: '100%' }}
                    id='endDate'
                    name='endDate'
                    placeholder='mm-DD-YYYY'
                    className={Style.AnsTxt}
                    InputProps={{
                      inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
                    }}
                    error={!!errors?.estimatedDate}
                    helperText={errors?.estimatedDate}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <FormHelperText sx={{ mt: '-6px', ml: '10px' }}>
          {errors?.estimatedDate}
        </FormHelperText>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              List the services, tests, or evaluations that applicant has
              received from this organization
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='description'
              name='description'
              placeholder='Description'
              multiline
              rows={4}
              className={Style.AnsTxt}
              value={formValues?.description}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.description}
              helperText={errors?.description}
            />
          </Grid>
        </Grid>

        <Grid xs={12} py={1} width={'100%'}>
          <InputLabel className={Style.label}>
            Is applicant still participating in this plan or program?
            <span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <FormControl>
            <RadioGroup
              aria-label='participatedProgram'
              className={Style.element}
              id='participatedProgram'
              name='participatedProgram'
              value={commonStateFake}
            >
              <FormControlLabel
                className={
                  participatedProgram
                    ? Style.radioSelectionBoxSelect
                    : Style.radioSelectionBoxes
                }
                label=''
                value={true}
                control={
                  <Box display={'flex'} alignItems={'center'}>
                    <Radio
                      checked={participatedProgram === true}
                      checkedIcon={<img src={ActiveRadio} alt='On' />}
                      icon={<img src={InactiveRadio} alt='Off' />}
                      value={true}
                      onClick={() => {
                        setparticipatedProgram(true)
                      }}
                    />
                    <Box className={Style.radioText}>
                      <Typography variant='body1' className={Style.TxtQ}>
                        Yes
                      </Typography>
                    </Box>
                  </Box>
                }
              />
              <FormControlLabel
                className={
                  participatedProgram === false
                    ? Style.radioSelectionBoxSelect
                    : Style.radioSelectionBoxes
                }
                label=''
                value={false}
                control={
                  <Box display={'flex'} alignItems={'center'}>
                    <Radio
                      checked={participatedProgram === false}
                      checkedIcon={<img src={ActiveRadio} alt='On' />}
                      icon={<img src={InactiveRadio} alt='Off' />}
                      value={false}
                      onClick={() => {
                        setparticipatedProgram(false)
                      }}
                    />
                    <Box className={Style.radioText}>
                      <Typography variant='body1' className={Style.TxtQ}>
                        No
                      </Typography>
                    </Box>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
          <FormHelperText>{errors?.stillParticipatedInProgram}</FormHelperText>
        </Grid>
      </Box>
    </>
  )
}
export default DetailBox
