import { InputLabel, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Style from './index.module.scss'
import remove from 'src/Assets/Icons/remove.svg'

interface legalNames {
  firstName: string
  middleName: string
  lastName: string
}

const ProfileBox = ({
  errors,
  formValues,
  changeHandler,
  handleRemove,
  index,
}: {
  errors: Record<string, string>
  formValues: legalNames
  changeHandler: (data: { name: string; value: string }) => void
  handleRemove: () => void
  index: number
}) => {
  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.label}>Profile {index + 1}</Typography>
          <img
            src={remove}
            alt=''
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleRemove()
            }}
          />
        </Grid>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              First Name<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='firstName'
              name='firstName'
              placeholder='Enter applicant‘s first name'
              className={Style.AnsTxt}
              value={formValues.firstName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors[`firstName[${index}]`]} // Access error for firstName using dynamic key
              helperText={errors[`firstName[${index}]`] || ''} // Display error message or empty string
            />
          </Grid>
          <Grid width={'100%'}>
            <InputLabel className={Style.label} htmlFor='BillingName'>
              Middle Name<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='middleName'
              name='middleName'
              placeholder='Enter applicant‘s middle name'
              className={Style.AnsTxt}
              value={formValues?.middleName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors[`middleName[${index}]`]} // Access error for middleName using dynamic key
              helperText={errors[`middleName[${index}]`] || ''} // Display error message or empty string
            />
          </Grid>
          <Grid width={'100%'}>
            <InputLabel className={Style.label} htmlFor='BillingName'>
              Last Name<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='lastName'
              name='lastName'
              placeholder='Enter applicant‘s last name'
              className={Style.AnsTxt}
              value={formValues?.lastName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors[`lastName[${index}]`]} // Access error for lastName using dynamic key
              helperText={errors[`lastName[${index}]`] || ''} // Display error message or empty string
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
export default ProfileBox
