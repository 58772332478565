import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider } from '@mui/material'
import Styles from '../index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import Medicine from '../EditForms/Medicine'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { hasEmpty } from 'src/Utils/Constants'
const MedicalDetails: React.FunctionComponent = ({
  chatForm,
}: {
  chatForm?: any
}) => {
  const [editMode, setEditMode] = useState(false)
  const medicineRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    medicineRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) => state?.applicationStore?.MedicalDetails?.Medicine,
  )

  const medicineSectionId = useAppSelector(
    (state) =>
      state?.steps?.MedicalApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Medicine',
      )?.sectionId,
  )

  interface Medication {
    suffix: string
    doctorFirstName: string
    doctorLastName: string
    medicationReason: string
    medicationOther: string
    medicationName: string
    sideEffects: string
  }

  return (
    <div id={medicineSectionId} ref={medicineRef}>
      {!editMode ? (
        <>
          <Box className={Styles.MainBox}>
            <Box className={Styles.title}>
              <Box className={Styles.main}>
                <Typography variant='h6' className={Styles.textWrapper}>
                  Medicine
                </Typography>
                <Button
                  className={Styles.button}
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  <Typography className={Styles.div}>Edit</Typography>
                </Button>
              </Box>
              <div className={Styles.dottedLine} />
            </Box>

            {!hasEmpty(data) ? (
              <>
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Any medication (i.e., prescribed or over-the-counter)
                        for applicant’s illnesses, injuries, or conditions
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.isMedication ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {data?.isMedication ? (
                  <>
                    <Box className={Styles.div2}>
                      <Typography variant='h6' className={Styles.SubTitle}>
                        Added Medications
                      </Typography>
                    </Box>
                    {/* card-1 */}
                    {data?.medication?.map(
                      (item: Medication, index: number) => (
                        <>
                          <Box className={Styles.card} key={index}>
                            <Box className={Styles.cardDiv}>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={6}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Medical professional’s Name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {` ${item?.suffix}  ${item?.doctorFirstName} ${item?.doctorLastName} `}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid item xs={6} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Illness, injury, or condition for which the
                                    medicine is taken
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper3}
                                  >
                                    {item?.medicationReason}
                                  </Typography>
                                </Box>
                              </Grid>
                              {item?.medicationReason === 'Other' && (
                                <Grid
                                  item
                                  xs={6}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Other Illness, Injury, or Condition
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.medicationOther}
                                    </Typography>
                                  </Box>
                                </Grid>
                              )}
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={12}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Medication
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.medicationName}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={12}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Side effects due to this medication
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.sideEffects}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </>
                      ),
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Medicine
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <Medicine
                    chatForm={chatForm}
                    handleEditToggle={handleEditToggle}
                  />
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default MedicalDetails
