interface QuestionType {
  question: string
  answerPath: string | string[]
  iterable?: string // Optional iterable property
}

export interface ApplicationPDFType {
  Assistance_Data: {
    Assistance_Programs: QuestionType[]
    assigneeData: QuestionType[]
  }
  Basic_Details: {
    contactInformation: QuestionType[]
    communication: QuestionType[]
    citizenship: QuestionType[]
    Military_Service: QuestionType[]
    Pass_Application: QuestionType[]
  }
  Family_Details: {
    Current_Significant: QuestionType[]
    Past_Significant: QuestionType[]
    Dependents: QuestionType[]
    Parents: QuestionType[]
  }
  Legal_Details: {
    legalNames: QuestionType[]
    identificationNumber: QuestionType[]
    receivingDisabilityBenefitPayment: QuestionType[]
  }
  Disability_Details: {
    disabilityBackground: QuestionType[]
    disabilityEmployment: QuestionType[]
    payoutsAndSettlements: QuestionType[]
  }
  Education_Employment_Details: {
    education: QuestionType[]
    specialEducation: QuestionType[]
    specializedTradeEducationAndTraining: QuestionType[]
    employerDetail: QuestionType[]
    jobDetail: QuestionType[]
    childcare: QuestionType[]
    selfEmployment: QuestionType[]
    foreignWork: QuestionType[]
    workTaxDetail: QuestionType[]
  }
  Medical_Details: {
    GeneralHealth: QuestionType[]
    VisionAndHearing: QuestionType[]
    IllnessesInjuriesAndConditions: QuestionType[]
    MedicalProfessionals: QuestionType[]
    Hospital_Or_Medical_Facility_Care: QuestionType[]
    Medical_Test: QuestionType[]
    Medicine: QuestionType[]
    AdditionalInformation: QuestionType[]
  }
}

const ApplicationData: ApplicationPDFType = {
  Assistance_Data: {
    Assistance_Programs: [
      {
        question: 'Assistant full name?',
        answerPath: 'assistancedata[0]',
      },
      {
        question: 'What is the name of the assistance organization?',
        answerPath: 'assistancedata[0].organizationName',
      },
      {
        question: 'What is the relationship to the you?',
        answerPath: 'assistancedata[0].relationToYou',
      },
      {
        question: 'What is the address of the assistance organization?',
        answerPath: 'assistancedata[0].address',
      },
      {
        question: 'What is the email ID of the assistance organization?',
        answerPath: 'assistancedata[0].emailId',
      },
      {
        question: 'What is the phone number of the assistance organization?',
        answerPath: 'assistancedata[0].phoneNumber',
      },
    ],
    assigneeData: [
      {
        question: 'What is the first name of the assignee?',
        answerPath: 'assigneedata[0].firstName',
      },
      {
        question: 'What is the last name of the assignee?',
        answerPath: 'assigneedata[0].lastName',
      },
    ],
  },
  Basic_Details: {
    contactInformation: [
      {
        question: 'What is your full name?',
        answerPath: 'basicdata[0].contactInformation',
      },
      // {
      //   question: 'What is your middle name?',
      //   answerPath: 'basicdata[0].contactInformation.middleName',
      // },
      // {
      //   question: 'What is your last name?',
      //   answerPath: 'basicdata[0].contactInformation.lastName',
      // },
      {
        question: 'What is your physical address?',
        answerPath: 'basicdata[0].contactInformation.physicalAddress',
      },
      {
        question: 'do you live at this physical address?',
        answerPath: 'basicdata[0].contactInformation.isLiveAtPhysicalAddress',
      },
      {
        question:
          'Please provide the address where you currently reside. What is your reside address?',
        answerPath: 'basicdata[0].contactInformation.resideAddress',
      },
      {
        question: 'do you want to receive mail at this physical address?',
        answerPath:
          'basicdata[0].contactInformation.wantToReceiveMailAtPhysicalAddress',
      },
      {
        question: "What's your preferred mailing address?",
        answerPath: 'basicdata[0].contactInformation.mailAddress',
      },
      {
        question: 'Is your phone number a USA number?',
        answerPath: 'basicdata[0].contactInformation.isUSAPhoneNumber',
      },
      {
        question: 'What is your phone number?',
        answerPath: 'basicdata[0].contactInformation.phoneNumber',
      },
      {
        question: 'What is your email ID?',
        answerPath: 'basicdata[0].contactInformation.emailId',
      },
    ],
    communication: [
      {
        question:
          'Is your preferred language for reading, writing, and speaking English?',
        answerPath: 'basicdata[0].communication.isPreferredLanguageEnglish',
      },
      {
        question: 'What is your preferred reading language?',
        answerPath: 'basicdata[0].communication.preferredReadingLanguage',
      },
      {
        question: 'What is your preferred writing language?',
        answerPath: 'basicdata[0].communication.preferredWritingLanguage',
      },
      {
        question: 'What is your preferred speaking language?',
        answerPath: 'basicdata[0].communication.preferredSpeakingLanguage',
      },
      {
        question: 'Can you read English?',
        answerPath: 'basicdata[0].communication.canReadEnglish',
      },
      {
        question: 'Can you write in English?',
        answerPath: 'basicdata[0].communication.canWriteEnglish',
      },
      {
        question: 'Can you speak English?',
        answerPath: 'basicdata[0].communication.canSpeakEnglish',
      },
      {
        question: 'Are you able to read and write more than your name?',
        answerPath: 'basicdata[0].communication.isAbleToReadWriteMoreThenName',
      },
    ],
    citizenship: [
      {
        question: 'Were you born in the U.S. or a U.S. territory?',
        answerPath: 'basicdata[0].citizenship.wereBornInUSA',
      },
      {
        question: 'What city were you born in?',
        answerPath: 'basicdata[0].citizenship.bornUSACityName',
      },
      {
        question: 'What state were you born in?',
        answerPath: 'basicdata[0].citizenship.bornUSAStateName',
      },
      {
        question: 'What country were you born in?',
        answerPath: 'basicdata[0].citizenship.bornCountry',
      },
      {
        question: 'Are you a confirmed USA citizen?',
        answerPath: 'basicdata[0].citizenship.confirmUSACitizen',
      },
      {
        question: 'What is your qualified USA citizenship status?',
        answerPath: 'basicdata[0].citizenship.qualifiedUSACitizenship',
      },
      {
        question: 'What is the date of your citizenship?',
        answerPath: 'basicdata[0].citizenship.dateOfCitizenship',
      },
      {
        question: 'What countries are you a citizen of?',
        answerPath: 'basicdata[0].citizenship.citizenshipCountryName',
      },
      {
        question: 'Are you going through the process of USA citizenship?',
        answerPath:
          'basicdata[0].citizenship.isGoingThroughProcessOfUSACitizenship',
      },
      {
        question: 'Are you a legal resident of the USA?',
        answerPath: 'basicdata[0].citizenship.isLegalResidentOfUSA',
      },
      {
        question: 'What is your resident card number?',
        answerPath: 'basicdata[0].citizenship.residentCardNumber',
      },
    ],
    Military_Service: [
      {
        question: 'Do you serve or are you serving in the U.S. military?',
        answerPath: 'basicdata[0].militaryService.isServedInMilitary',
      },
      // Array
      {
        iterable: 'MilitaryServiceInformation',
        question: 'Military Service Information',
        answerPath: 'basicdata[0].militaryService.serviceInformation',
      },
      {
        question:
          'Are you receiving or eligible to receive a military or civilian federal agency benefit?',
        answerPath:
          'basicdata[0].militaryService.isReceivingFederalAgencyBenefit',
      },
      {
        question: 'What type of benefit are you receiving?',
        answerPath: 'basicdata[0].militaryService.typeOfBenefit',
      },
    ],
    Pass_Application: [
      {
        question: 'Are you currently receiving Medicare benefits?',
        answerPath: 'basicdata[0].pastApplication.isReceivingMedicareBenefits',
      },
      {
        question: 'Have you applied for Medicare benefits?',
        answerPath:
          'basicdata[0].pastApplication.haveAppliedForMedicareBenefits',
      },
      {
        question: 'What is the status of your previous Medicare benefit?',
        answerPath:
          'basicdata[0].pastApplication.statusOfPreviousMedicareBenefit',
      },
      {
        question:
          'Are you currently receiving Social Security benefits (SSDI)?',
        answerPath: 'basicdata[0].pastApplication.isReceivingSSDI',
      },
      {
        question:
          'Have you previously applied for Social Security benefits (SSDI)?',
        answerPath: 'basicdata[0].pastApplication.haveAppliedForSSDI',
      },
      {
        question:
          "What's the status of the Social Security benefits program for which you previously applied (SSDI)?",
        answerPath: 'basicdata[0].pastApplication.statusOfPreviousSSDI',
      },
      {
        question: 'What is the SSDI approved end date (estimate if unknown)?',
        answerPath: 'basicdata[0].pastApplication.SSDIApprovedEndDate',
      },
      {
        question: 'Have you been denied SSDI within 60 days?',
        answerPath: 'basicdata[0].pastApplication.isSSDIDenial60Days',
      },
      {
        question: 'What is the denial date for SSDI?',
        answerPath: 'basicdata[0].pastApplication.denialDateSSDI',
      },
      {
        question:
          'Are you currently receiving Supplemental Security Income (SSI)?',
        answerPath: 'basicdata[0].pastApplication.isReceivingSSI',
      },
      {
        question:
          'Have you previously applied for Supplemental Security Income (SSI)?',
        answerPath: 'basicdata[0].pastApplication.haveAppliedForSSI',
      },
      {
        question:
          "What's the status of the Supplemental Security Income (SSI) benefits program for which you previously applied?",
        answerPath: 'basicdata[0].pastApplication.statusOfSSI',
      },
      {
        question: 'What is the SSI approved end date?',
        answerPath: 'basicdata[0].pastApplication.SSIApprovedEndDate',
      },
      {
        question: 'Have you been denied SSI within 60 days?',
        answerPath: 'basicdata[0].pastApplication.isSSIDenial60Days',
      },
      {
        question: 'What is the denial date for SSI?',
        answerPath: 'basicdata[0].pastApplication.denialDateSSI',
      },
      {
        question: 'Have you applied with SSN?',
        answerPath: 'basicdata[0].pastApplication.haveAppliedWithSSN',
      },
      {
        question: 'Have you applied for additional benefits?',
        answerPath: 'basicdata[0].pastApplication.appliedForAdditionalBenefits',
      },
      {
        question: 'What programs are you currently participating in?',
        answerPath:
          'basicdata[0].pastApplication.currentlyparticipatingPrograms',
      },
      // Array
      {
        iterable: 'benefitAppliedSSNDetail',
        question:
          'who’s social security number (SSN) for Benefits were applied to',
        answerPath: 'basicdata[0].pastApplication.benefitAppliedSSNDetail',
      },
      // Array
      {
        iterable: 'participateProgramDetails',
        question: 'What programs do you participate in?',
        answerPath: 'basicdata[0].pastApplication.participateProgramDetails',
      },
    ],
  },
  Family_Details: {
    Current_Significant: [
      {
        question: 'Are you currently legally married?',
        answerPath: 'familydata[0].isLegallyMarried',
      },
      {
        question: "What is your spouse's full name?",
        answerPath: 'familydata[0].currentSignificantOther',
      },
      // {
      //   question: "What is your spouse's last name?",
      //   answerPath: 'familydata[0].currentSignificantOther.spouseLastName',
      // },
      {
        question: "What is your spouse's date of birth?",
        answerPath: 'familydata[0].currentSignificantOther.spouseDateOfBirth',
      },
      {
        question: "What is your spouse's SSN?",
        answerPath: 'familydata[0].currentSignificantOther.spouseSSN',
      },
      {
        question: 'What type of marriage do you have?',
        answerPath: 'familydata[0].currentSignificantOther.typeOfMarriage',
      },
      {
        question: 'What is the date of your marriage?',
        answerPath: 'familydata[0].currentSignificantOther.dateOfMarriage',
      },
      {
        question: 'Where do you get married?',
        answerPath: 'familydata[0].currentSignificantOther.placeOfMarriage',
      },
      {
        question: 'Other details',
        answerPath:
          'familydata[0].currentSignificantOther.placeOfMarriage.other',
      },
    ],
    Past_Significant: [
      {
        question: 'do you have any prior marriages?',
        answerPath: 'familydata[0].havePriorMarriages',
      },
      {
        question: 'Did any of your prior marriages last 10 years or more?',
        answerPath: 'familydata[0].pastMarriage10Years',
      },
      {
        question:
          "Did any of your prior marriages end due to your spouse's death?",
        answerPath: 'familydata[0].isPriorMarriageEndDuetoSpouseDeath',
      },
      // Array
      {
        iterable: 'pastSignificantOthers',
        question: 'Past Significant Others',
        answerPath: 'familydata[0].pastSignificantOthers',
      },
    ],
    Dependents: [
      {
        question: 'do you have any children?',
        answerPath: 'familydata[0].haveAnyChildern',
      },
      {
        question: 'number of children',
        answerPath: 'familydata[0].numberOfChildern',
      },
      // Array
      {
        iterable: 'dependents',
        question: 'Dependent Information:',
        answerPath: 'familydata[0].dependents',
      },
    ],
    Parents: [
      {
        question:
          'do you have a parent who receives one-half support from you?',
        answerPath: 'familydata[0].isParentReceiveOneHalfSupport',
      },
      // {
      //   question: "What is your first parent's suffix?",
      //   answerPath: 'familydata[0].firstParent.suffix',
      // },
      {
        question: "What is your full name of your first parent's?",
        answerPath: 'familydata[0].firstParent',
      },
      // {
      //   question: "What is your first parent's middle name?",
      //   answerPath: 'familydata[0].firstParent.middleName',
      // },
      // {
      //   question: "What is your first parent's last name?",
      //   answerPath: 'familydata[0].firstParent.lastName',
      // },
      {
        question: "What is your first parent's address?",
        answerPath: 'familydata[0].firstParent.address',
      },
      {
        question:
          'do you have a second parent who receives one-half support from you?',
        answerPath: 'familydata[0].isSecondParentReceiveOneHalfSupport',
      },
      // {
      //   question: "What is your second parent's suffix?",
      //   answerPath: 'familydata[0].secondParent.suffix',
      // },
      {
        question: "What is your full name of your second parent's?",
        answerPath: 'familydata[0].secondParent',
      },
      // {
      //   question: "What is your second parent's middle name?",
      //   answerPath: 'familydata[0].secondParent.middleName',
      // },
      // {
      //   question: "What is your second parent's last name?",
      //   answerPath: 'familydata[0].secondParent.lastName',
      // },
      {
        question: "What is your second parent's address?",
        answerPath: 'familydata[0].secondParent.address',
      },
    ],
  },
  Legal_Details: {
    legalNames: [
      {
        question: 'Have you used any other name?',
        answerPath: 'legalData[0].haveUsedOtherName',
      },
      // Array
      {
        iterable: 'LegalNames',
        question: 'Please provide these names:',
        answerPath: 'legalData[0].legalNames',
      },
    ],
    identificationNumber: [
      {
        question: 'What is your social security number?',
        answerPath: 'legalData[0].identificationNumber.socialSecurityNumber',
      },
      {
        question: 'Have you used any other social security number?',
        answerPath: 'legalData[0].identificationNumber.isUsingOtherSSN',
      },
      // Array
      {
        iterable: 'otherSocialSecurityNumbers',
        question: 'Please enter these social security numbers.',
        answerPath:
          'legalData[0].identificationNumber.otherSocialSecurityNumbers',
      },
    ],
    receivingDisabilityBenefitPayment: [
      {
        question:
          'do you have a bank account that you can use to receive direct deposit payments?',
        answerPath: 'legalData[0].isHavingBankAccount',
      },
      {
        question: 'What is your account type?',
        answerPath:
          'legalData[0].receivingDisabilityBenefitPayment.accountType',
      },
      {
        question: 'What is your routing number?',
        answerPath:
          'legalData[0].receivingDisabilityBenefitPayment.routingNumber',
      },
      {
        question: 'What is your account number?',
        answerPath:
          'legalData[0].receivingDisabilityBenefitPayment.accountNumber',
      },
    ],
  },
  Disability_Details: {
    disabilityBackground: [
      {
        question:
          'Are you currently able to work regardless of your illness, injury, or condition?',
        answerPath: 'disabilitydetails[0].disabilityBackground.isAbleToWork',
      },
      {
        question: 'Is your disability expected to last 1 year or longer?',
        answerPath:
          'disabilitydetails[0].disabilityBackground.isDisabilityExpected1YearOrLonger',
      },
      {
        question: 'What is the start date of your disability?',
        answerPath:
          'disabilitydetails[0].disabilityBackground.disabilityStartDate',
      },
      {
        question: 'Does your disability end in death?',
        answerPath:
          'disabilitydetails[0].disabilityBackground.disabilityEndInDeath',
      },
      {
        question: 'Are you able to perform your usual job?',
        answerPath:
          'disabilitydetails[0].disabilityBackground.isAbleToPerformUsualJob',
      },
      {
        question: 'Have you been unable to do your job for 14 months?',
        answerPath:
          'disabilitydetails[0].disabilityBackground.is14MonthsUnableToDoJob',
      },
      {
        question: 'Is your disability related to work?',
        answerPath:
          'disabilitydetails[0].disabilityBackground.disabilityRelatedToWork',
      },
    ],
    disabilityEmployment: [
      {
        question:
          'What details can you provide about how your disability affects your employment?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.affectingEmploymentDetail',
      },
      {
        question: 'What were you employed in during the last 2 years?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.employedDuringLast2YearDetail',
      },
      {
        question: 'What was the reason you stopped working?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.reasonToStoppedWorking',
      },
      {
        question: 'Explain why you stopped working?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.explainStoppedWorking',
      },
      {
        question: 'What is the date you stopped working?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.dateOfStoppedWorking',
      },
      {
        question: 'do you require changes to your work?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.isRequireWorkChanges',
      },
      {
        question:
          'Have you made more money in any month since your disability?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.haveMadeMoreMoneyInAnyMonth',
      },
      {
        question: 'What is the start date of your work changes?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.workChangeStartDate',
      },
      {
        question: 'What date did your disability keep you from working?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.disabilityKeepFromWorkingDate',
      },
      {
        question: 'do you intend to work next year?',
        answerPath:
          'disabilitydetails[0].disabilityEmployment.intendToWorkNextYear',
      },
    ],
    payoutsAndSettlements: [
      {
        question:
          'Have you received money from your employer on or after the date that you became unable to work?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.haveReceiveMoneyAfterDisability',
      },
      {
        question: 'What types of income have you received?',
        answerPath: 'disabilitydetails[0].payoutsAndSettlements.typeOfPays',
      },
      {
        question: 'Can you explain other income received?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.otherPayExplain',
      },
      {
        question:
          'do you expect to receive any money from your employer in the future?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.isExpectMoneyInFuture',
      },
      {
        question: 'What is the approximate amount you expect to receive?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.approxAmountExpected',
      },
      {
        question: 'What types of future income do you expect?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.typeOfFuturePay',
      },
      {
        question: 'Can you explain the potential future payments?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.futureOtherPayExplain',
      },
      {
        question:
          'What is the approximate amount of future payouts you expect?',
        answerPath:
          'disabilitydetails[0].payoutsAndSettlements.approxAmountFuturePayout',
      },
    ],
  },
  Education_Employment_Details: {
    education: [
      {
        question:
          'What Is the highest level of education that you have completed?',
        answerPath: 'educationdetails[0].education.highestGrade',
      },
      {
        question: 'What is the name of your school?',
        answerPath: 'educationdetails[0].education.schoolName',
      },
      {
        question: 'In which city is your school located?',
        answerPath: 'educationdetails[0].education.city',
      },
      {
        question: 'In which state is your school located?',
        answerPath: 'educationdetails[0].education.state',
      },
      {
        question: 'In which country is your school located?',
        answerPath: 'educationdetails[0].education.country',
      },
      {
        question: 'When do you complete your education?',
        answerPath: 'educationdetails[0].education.dateEducationCompleted',
      },
    ],
    specialEducation: [
      {
        question:
          'Have you ever received special education through an Individualized Education Plan (IEP) or similar program?',
        answerPath: 'educationdetails[0].haveReceivedSpecialEducation',
      },
      {
        question: 'Please provide the dates on which you started this program.',
        answerPath: 'educationdetails[0].specialEducation.startDate',
      },
      {
        question: 'Please provide the dates on which you ended this program.',
        answerPath: 'educationdetails[0].specialEducation.endDate',
      },
      {
        question:
          'Select the last grade that you received special education:        ',
        answerPath: 'educationdetails[0].specialEducation.lastGrade',
      },
      {
        question: 'What was the reason for your special education?',
        answerPath:
          'educationdetails[0].specialEducation.reasonForSpecialEducation',
      },
      {
        question: 'What is the name of the school for your special education?',
        answerPath: 'educationdetails[0].specialEducation.schoolName',
      },
      {
        question: 'In which city is your special education school located?',
        answerPath: 'educationdetails[0].specialEducation.city',
      },
      {
        question: 'In which state is your special education school located?',
        answerPath: 'educationdetails[0].specialEducation.state',
      },
      {
        question: 'In which country is your special education school located?',
        answerPath: 'educationdetails[0].specialEducation.country',
      },
    ],
    specializedTradeEducationAndTraining: [
      {
        question:
          'Specialized education is quite popular, and we do like to understand if this applies to you. Have you completed any type of specialized job training, including trade or vocational school?',
        answerPath: 'educationdetails[0].haveSpecializedTraining',
      },
      {
        question: 'What type of specialized training do you receive?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.typeOfSpecializedTraining',
      },
      {
        question: 'Can you describe your specialized training?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.description',
      },
      {
        question: 'What was the start date of your specialized training?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.startDate',
      },
      {
        question: 'What was the end date of your specialized training?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.endDate',
      },
      {
        question:
          'What is the name of the school for your specialized training?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.schoolName',
      },
      {
        question: 'In which city is your specialized training school located?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.city',
      },
      {
        question: 'In which state is your specialized training school located?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.state',
      },
      {
        question:
          'In which country is your specialized training school located?',
        answerPath:
          'educationdetails[0].specializedTradeEducationAndTraining.country',
      },
    ],
    employerDetail: [
      // Array
      {
        iterable: 'employerDetail',
        question: 'What is your employer?',
        answerPath: 'educationdetails[0].employerDetail',
      },
    ],
    jobDetail: [
      {
        question: 'Have you held one or more jobs in the last 15 years?',
        answerPath: 'educationdetails[0].jobDetail.oneOrMoreJobsIn15Years',
      },
      {
        question: 'Do you lead or mentor others?',
        answerPath: 'educationdetails[0].jobDetail.didLeadOrMentorOthers',
      },
      {
        question:
          'Can you describe your job experience over the last 15 years?',
        answerPath: 'educationdetails[0].jobDetail.jobDescriptionOf15Years',
      },
      {
        question: 'What applicable jobs do you hold?',
        answerPath: 'educationdetails[0].jobDetail.applicableJobs',
      },
      {
        question: 'How many hours do you typically walk during your job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.walk',
      },
      {
        question: 'How many hours do you typically stand during your job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.stand',
      },
      {
        question: 'How many hours do you typically sit during your job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.sit',
      },
      {
        question: 'How many hours do you typically climb during your job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.climb',
      },
      {
        question: 'How many hours do you typically bend during your job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.bend',
      },
      {
        question: 'How many hours do you typically kneel during your job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.kneel',
      },
      {
        question: 'How many hours do you typically crouch during your job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.crounch',
      },
      {
        question: 'How many hours do you typically crawl during your job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.crawl',
      },
      {
        question:
          'How many hours do you typically write or type during your job?',
        answerPath: 'educationdetails[0].jobDetail.hoursToPerform.writeOrType',
      },
      {
        question:
          'How many hours do you typically handle large objects during your job?',
        answerPath:
          'educationdetails[0].jobDetail.hoursToPerform.handleLargeObject',
      },
      {
        question:
          'How many hours do you typically reach for things during your job?',
        answerPath:
          'educationdetails[0].jobDetail.hoursToPerform.reachToThings',
      },
      {
        question:
          'How many hours do you typically handle small objects during your job?',
        answerPath:
          'educationdetails[0].jobDetail.hoursToPerform.handleSmallObjects',
      },
      {
        question: 'Is lifting a part of your job?',
        answerPath: 'educationdetails[0].jobDetail.isLifitingInJob',
      },
      {
        question: 'How far do you typically carry items?',
        answerPath: 'educationdetails[0].jobDetail.howFarCarryItems',
      },
      {
        question: 'What is the heaviest weight you‘ve  lifted?',
        answerPath: 'educationdetails[0].jobDetail.heaviestWeightLifted',
      },
      {
        question: 'How much weight do you lift per day?',
        answerPath: 'educationdetails[0].jobDetail.howMuchWeightLiftedPerDay',
      },
      {
        question: 'Do you supervise others in your job?',
        answerPath: 'educationdetails[0].jobDetail.didSuperviseOther',
      },
      {
        question: 'What percentage of your time was spent supervising?',
        answerPath:
          'educationdetails[0].jobDetail.percentageOfTimeSpentSupervising',
      },
      {
        question: 'How many people do you supervise?',
        answerPath: 'educationdetails[0].jobDetail.howManyPeopleSupervise',
      },
      {
        question: 'Did you‘ve  the authority to hire or fire people?',
        answerPath: 'educationdetails[0].jobDetail.didHireOrFirePeople',
      },
      {
        question: 'What do you lift and how often?',
        answerPath: 'educationdetails[0].jobDetail.whatLiftedAndHowOften',
      },
    ],
    childcare: [
      {
        question:
          'Have you cared for children under the age of three (3) full-time?',
        answerPath:
          'educationdetails[0].childcare.fulltimeChildcareUnder3Years',
      },
      {
        question: 'Year Started Caring for Children Under 3:',
        answerPath: 'educationdetails[0].childcare.fulltimeChildcareStartYear',
      },
      {
        question: 'Year Ended Caring for Children Under 3:',
        answerPath: 'educationdetails[0].childcare.fulltimeChildcareEndYear',
      },
    ],
    selfEmployment: [
      {
        question: 'Are you self-employed?',
        answerPath: 'educationdetails[0].selfEmployment.isSelfEmployed',
      },
      {
        question: 'Were you self-employed during the last year?',
        answerPath:
          'educationdetails[0].selfEmployment.isSelfEmployedDuringLastYear',
      },
      {
        question: 'What type of business do you run last year?',
        answerPath: 'educationdetails[0].selfEmployment.typeOfLastYearBusiness',
      },
      {
        question: 'Do you earn more profit last year?',
        answerPath:
          'educationdetails[0].selfEmployment.profitMoreAmountLastYear',
      },
      {
        question: 'What was your total earned amount last year?',
        answerPath:
          'educationdetails[0].selfEmployment.totalEarnedAmountLastYear',
      },
      {
        question: 'Are you self-employed during the current year?',
        answerPath:
          'educationdetails[0].selfEmployment.isSelfEmployedDuringCurrentYear',
      },
      {
        question: 'What type of business are you running this year?',
        answerPath:
          'educationdetails[0].selfEmployment.typeOfCurrentYearBusiness',
      },
      {
        question: 'Do you earn more profit this year?',
        answerPath:
          'educationdetails[0].selfEmployment.profitMoreAmountCurrentYear',
      },
      {
        question: 'What was your total earned amount this year?',
        answerPath:
          'educationdetails[0].selfEmployment.totalEarnedAmountCurrentYear',
      },
      {
        question: 'Will you be self-employed next year?',
        answerPath: 'educationdetails[0].selfEmployment.isSelfEmployedNextYear',
      },
      {
        question: 'What type of business will you run next year?',
        answerPath: 'educationdetails[0].selfEmployment.typeOfNextYearBusiness',
      },
      {
        question: 'Will you earn more profit next year?',
        answerPath:
          'educationdetails[0].selfEmployment.profitMoreAmountNextYear',
      },
    ],
    foreignWork: [
      {
        question: 'Have you worked outside the USA?',
        answerPath: 'educationdetails[0].foreignWork.haveWorkedOutSideUSA',
      },
      {
        question:
          'Is your current year income from salary, wage, or self-employment?',
        answerPath:
          'educationdetails[0].foreignWork.isSalaryWageOrCurrentYearSelfEmployement',
      },
      {
        question: 'What is your total earned amount for the current year?',
        answerPath:
          'educationdetails[0].foreignWork.totalEarnedAmountCurrentYear',
      },
      {
        question:
          'Is your current year earning shown in the Social Security statement?',
        answerPath:
          'educationdetails[0].foreignWork.earningShowInSocialSecutiryStatementCurrentYear',
      },
      {
        question:
          'Is your last year income from salary, wage, or self-employment?',
        answerPath:
          'educationdetails[0].foreignWork.isSalaryWageOrLastYearSelfEmployement',
      },
      {
        question: 'What is your total earned amount for last year?',
        answerPath: 'educationdetails[0].foreignWork.totalEarnedAmountLastYear',
      },
      {
        question:
          'Is your last year earning shown in the Social Security statement?',
        answerPath:
          'educationdetails[0].foreignWork.earningShowInSocialSecutiryStatementLastYear',
      },
      {
        question:
          'Are you eligible for benefits under a foreign social security system?',
        answerPath:
          'educationdetails[0].foreignWork.eligibleForBenefitsUnderForeignSocialSecuritySystem',
      },
      {
        question:
          'Were USA taxes taken out of your income for the current year?',
        answerPath:
          'educationdetails[0].foreignWork.usaTaxesTakenOutCurrentYear',
      },
      {
        question: 'In which months were USA taxes not taken out this year?',
        answerPath:
          'educationdetails[0].foreignWork.usaTaxesNotTakenOutCurrentYearMonths',
      },
      {
        question: 'Were USA taxes taken out of your income for the past year?',
        answerPath: 'educationdetails[0].foreignWork.usaTaxesTakenOutPastYear',
      },
      {
        question: 'In which months were USA taxes not taken out last year?',
        answerPath:
          'educationdetails[0].foreignWork.usaTaxesNotTakenOutPastYearMonths',
      },
    ],
    workTaxDetail: [
      {
        question: 'Do you make payments for another year?',
        answerPath:
          'educationdetails[0].workTaxDetail.didPaymentPaidAnotherYear',
      },
      {
        question: 'Have you worked where taxes were not deducted?',
        answerPath:
          'educationdetails[0].workTaxDetail.haveWorkedWhereTaxesNotDeducted',
      },
      {
        question: 'Are you receiving a pension or annuity?',
        answerPath:
          'educationdetails[0].workTaxDetail.isReceivingPensionOrAnnuity',
      },
      {
        question: 'Is your pension or annuity based on government employment?',
        answerPath:
          'educationdetails[0].workTaxDetail.isPensionOrAnnuityBasedOnGovernmentEmployment',
      },
      {
        question:
          'do you expect to receive a pension or annuity in the future?',
        answerPath:
          'educationdetails[0].workTaxDetail.isExpectPensionOrAnnuityInFuture',
      },
      {
        question: 'Is your pension or annuity related to railroad work?',
        answerPath:
          'educationdetails[0].workTaxDetail.pensionAnnuityRailroadWork',
      },
      {
        question:
          'Is your pension or annuity related to future government work?',
        answerPath:
          'educationdetails[0].workTaxDetail.pensionAnnuityFutureGovtWork',
      },
      {
        question:
          'What is the expected start date for your future pension or annuity?',
        answerPath:
          'educationdetails[0].workTaxDetail.pensionAnnuityFutureStartDate',
      },
      {
        question: 'Do you receive a lump sum with no taxes deducted?',
        answerPath: 'educationdetails[0].workTaxDetail.lumpSumNoTaxes',
      },
    ],
  },
  Medical_Details: {
    GeneralHealth: [
      {
        question: 'What is your date of birth?',
        answerPath: 'medicaldetails[0].generalHealth.dateOfBirth',
      },
      {
        question: 'What is your gender?',
        answerPath: 'medicaldetails[0].generalHealth.gender',
      },
      {
        question: 'How tall are you?',
        answerPath: 'medicaldetails[0].generalHealth.physique',
      },
      {
        question: 'How much do you weigh (lbs)?',
        answerPath: 'medicaldetails[0].generalHealth.physique.weight',
      },
      // {
      //   question: 'What is your body build?',
      //   answerPath: 'medicaldetails[0].generalHealth.physique.build',
      // },
    ],
    VisionAndHearing: [
      {
        question: 'do you have 20/200 or less vision?',
        answerPath: 'medicaldetails[0].visionAndHearing.zeroOrLessVision2020',
      },
      {
        question: 'Please tell us whether you use any vision correctors.',
        answerPath: 'medicaldetails[0].visionAndHearing.visionCorrectorDetail',
      },
      {
        question:
          'do you still experience impaired vision even with glasses or contacts?',
        answerPath: 'medicaldetails[0].visionAndHearing.isImpairedVision',
      },
      {
        question:
          'Tell us whether you experience any of the following vision-related symptoms.',
        answerPath: 'medicaldetails[0].visionAndHearing.visionRelatedSymptoms',
      },
      {
        question: 'do you have any additional vision-related symptoms?',
        answerPath:
          'medicaldetails[0].visionAndHearing.additionalVisionRelatedSymptoms',
      },
      {
        question:
          'help us understand your hearing situation. Are you partially or totally deaf?',
        answerPath: 'medicaldetails[0].visionAndHearing.isPartialOrTotalDeaf',
      },
      {
        question:
          'do you still experience impaired hearing, even with hearing aids?',
        answerPath: 'medicaldetails[0].visionAndHearing.isUsingHearingAids',
      },
      {
        question: 'do you have impaired hearing?',
        answerPath: 'medicaldetails[0].visionAndHearing.isImpairedHearing',
      },
      {
        question: 'What are your hearing-related symptoms?',
        answerPath: 'medicaldetails[0].visionAndHearing.hearingSymptoms',
      },
      {
        question: 'do you have any additional hearing-related symptoms?',
        answerPath:
          'medicaldetails[0].visionAndHearing.additionalHearingRelatedSymptoms',
      },
      {
        question:
          'Please select one of your physical and mental illnesses, injuries, and conditions that limit your ability to work.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.limitedAbilityToWorkCondition',
      },
    ],
    IllnessesInjuriesAndConditions: [
      {
        question:
          "We'd like to understand if you is experiencing any muscle or mobility-related symptoms due to your illness, injury, or condition. Please check all that apply.",
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.muscleOrMemoryRelatedSymptoms',
      },
      {
        question:
          "We'd like to understand whether you experience any energy or sleep-related symptoms. Please check all that apply.",
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.energyOrSleepRelatedSymptoms',
      },
      {
        question:
          'help us understand if you experience any fever or skin-related symptoms. Please check all that apply.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.feverOrSkinRelatedSymptoms',
      },
      {
        question:
          "We'd like to understand if you experience any breathing or heart-related symptoms. Please check all that apply.",
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.breathingOrHeartRelatedSymptoms',
      },
      {
        question:
          'help us understand if you experience any speech-related symptoms due to your illness, injury, or condition. Please check all that apply.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.speechRelatedSymptoms',
      },
      {
        question: 'How long do your longest speech-related symptoms last?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.speechSymptomsDuration',
      },
      {
        question:
          'we do like to understand if you experience any memory-related symptoms. Please check all that apply.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.memoryRelatedSymptoms',
      },
      {
        question:
          'help us understand if you experience any mental health-related symptoms. Please check all that apply.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.mentalHealthRelatedSymptoms',
      },
      {
        question:
          'we do like to understand if you experience any urinary symptoms. Please check all that apply.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.urinarySymptoms',
      },
      {
        question: 'What are your dizziness or headache related symptoms?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.dizzinessOrHeadacheRelatedSymptoms',
      },
      {
        question:
          'help us understand if you experience any dizziness or headache-related symptoms. Please check all that apply.',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.isSeizureRelatedSymptoms',
      },
      {
        question: 'do you or have you previously experienced seizures?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.seizureRelatedSymptoms',
      },
      {
        question: 'What symptoms do you experience during a seizure?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.seizureSymptoms',
      },
      {
        question: 'do you have any other symptoms?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.anotherSymptoms',
      },
      {
        question: 'How long do your symptoms last?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.howLongSymptomLast',
      },
      {
        question: 'do you experience after effects from seizures?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.isAfterEffectSeizureExperience',
      },
      {
        question: 'What are your seizure after effect experiences?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.seizureAfterEffectExperience',
      },
      {
        question: 'do you have any emotional or learning problems?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.emotionalOrLearningProblems',
      },
      {
        question:
          'Which of the following migraine or headache symptoms do you experience?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.headacheMigrainesSymptoms',
      },
      {
        question: 'How long do your longest migraine-related symptoms last?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.headacheMigrainesSymptomDuration',
      },
      {
        question: 'How long do your seizure after effect symptoms last?',
        answerPath:
          'medicaldetails[0].illnessesInjuriesAndConditions.seizureAfterEffectSymptomsDuration',
      },
    ],
    MedicalProfessionals: [
      {
        question:
          'Have you seen a doctor or been treated for any mental illnesses, injuries, or conditions since you‘ve  been unable to work? Mental illnesses or conditions refer to those that impact the brain.',
        answerPath: 'medicaldetails[0].medicalProfessionals.isMentalIllness',
      },
      // Array
      {
        iterable: 'mentalIllnessMedicalProfessionals',
        question:
          'Now, we need to gather information on all of the medical professionals who have evaluated, treated, ordered testing, or prescribed medication to you for your conditions.',
        answerPath:
          'medicaldetails[0].medicalProfessionals.mentalIllnessMedicalProfessionals',
      },
      {
        question:
          'Have you seen at least one doctor or been treated for any physical illnesses, injuries, or conditions since you‘ve  been unable to work? Physical conditions, illnesses, or injuries refer to those that impact one or more body systems.',
        answerPath: 'medicaldetails[0].medicalProfessionals.isPhysicalIllness',
      },
      // Array
      {
        iterable: 'physicalIllnessMedicalProfessionals',
        question:
          'Now, we need to gather information on all of the medical professionals who have evaluated, treated, ordered testing, or prescribed medication to you for your conditions.',
        answerPath:
          'medicaldetails[0].medicalProfessionals.physicalIllnessMedicalProfessionals',
      },
    ],
    Hospital_Or_Medical_Facility_Care: [
      {
        question:
          'Have you seen a medical professional or been to a medical facility for an emergency room visit?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveEmergencyRoomVisit',
      },
      // Array
      {
        iterable: 'haveEmergencyRoomVisitMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated you for your emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveEmergencyRoomVisitMedicalFacility',
      },
      {
        question:
          'Have you seen a medical professional or been to a medical facility for any medical tests or medications ordered by this facility?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveMedicalFacility',
      },
      // Array
      {
        iterable: 'haveMedicalFacilityMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated you for your emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveMedicalFacilityMedicalFacility',
      },
      {
        question:
          'Have you seen a medical professional or been to a medical facility for any mental health treatment or evaluations?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveMentalHeathTreatment',
      },
      // Array
      {
        iterable: 'haveMentalHeathTreatmentMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated you for your emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveMentalHeathTreatmentMedicalFacility',
      },
      {
        question:
          'Have you seen a medical professional or been to a medical facility for any inpatient or overnight stays?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveInpatientOrOverNightStay',
      },
      // Array
      {
        iterable: 'haveInpatientOrOverNightStayMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated you for your emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveInpatientOrOverNightStayMedicalFacility',
      },
      {
        question:
          'Have you seen a medical professional or been to a medical facility for any outpatient or same-day visits?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveOutpatientOrSamedayVisit',
      },
      // Array
      {
        iterable: 'haveOutpatientOrSamedayVisitMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated you for your emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveOutpatientOrSamedayVisitMedicalFacility',
      },
      {
        question:
          'Have you seen a medical professional or been to a medical facility for any surgeries?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveSurgeries',
      },
      // Array
      {
        iterable: 'haveSurgeriesMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated you for your emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveSurgeriesMedicalFacility',
      },
      {
        question:
          'Have you seen a medical professional or been to a medical facility for any physical therapy?',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.havePhysicalTherapy',
      },
      // Array
      {
        iterable: 'havePhysicalTherapyMedicalFacility',
        question:
          'Please share the details of the hospital, urgent care, clinic, or medical facility that treated you for your emergency room visit.',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.havePhysicalTherapyMedicalFacility',
      },
      // org
      {
        question: 'Doctors in a prison or jail:',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.isDoctorInJail',
      },
      // Array
      {
        iterable: 'jailDoctorDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.jailDoctorDetail',
      },
      {
        question: 'Public welfare:',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.isPublicWelfare',
      },
      // Array
      {
        iterable: 'welfareDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.welfareDetail',
      },
      {
        question: 'Records held by an attorney/lawyer:',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.isRecordHeldByAttorney',
      },
      // Array
      {
        iterable: 'attorneyDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.attorneyDetail',
      },
      {
        question:
          'Short-term or long-term disability insurance (Excluding private health insurance):',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.haveDisabilityInsurance',
      },
      // Array
      {
        iterable: 'insuranceDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.insuranceDetail',
      },
      {
        question: 'Vocational rehabilitation services:',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.isVocationalRehabilitationService',
      },
      // Array
      {
        iterable: 'rehabilitationOrganizationDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.rehabilitationOrganizationDetail',
      },
      {
        question: 'Workers compensation:',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.isWorkersCompensation',
      },
      // Array
      {
        iterable: 'workersCompensationDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.workersCompensationDetail',
      },
      {
        question: 'Other, please explain (Excluding private health insurance):',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.isOtherHaveMedicalRecords',
      },
      // Array
      {
        iterable: 'otherOrganizationDetail',
        question: 'Add Organization Details',
        answerPath:
          'medicaldetails[0].hospitalAndMedicalFacilityCare.otherOrganizationDetail',
      },
    ],
    Medical_Test: [
      {
        question:
          'Have you had any medical tests completed related to your illnesses, injuries, or conditions?',
        answerPath: 'medicaldetails[0].medicalTest.isMedicalTestCompleted',
      },
      // Array
      {
        iterable: 'medicalTest',
        question:
          'Please share the details related to this medical test and the medical professional who ordered it.',
        answerPath: 'medicaldetails[0].medicalTest.medicalTest',
      },
    ],
    Medicine: [
      {
        question:
          'Are you taking any medication (i.e., prescribed or over-the-counter) for your illnesses, injuries, or conditions?',
        answerPath: 'medicaldetails[0].medicine.isMedication',
      },
      // Array
      {
        iterable: 'medication',
        question:
          'Please share the details related to this medication and the medical professional who prescribed or recommended it.',
        answerPath: 'medicaldetails[0].medicine.medication',
      },
    ],
    AdditionalInformation: [
      {
        question: 'Additional Information',
        answerPath: 'medicaldetails[0].additionalInformation',
      },
    ],
  },
}

export default ApplicationData
