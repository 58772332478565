import { Box, Button, InputLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  ApplicationIntroBox,
  Calander,
  CommonDropdown,
  Name,
  StarText,
} from 'src/Components/Fields'
import Address from 'src/Components/Fields/Address'
import { EducationType } from 'src/Redux/Services/Types/applicationType'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../index.module.scss'
import { setEducation } from 'src/Redux/Slices/ApplicationStore/EducationAndEmployement'
import { setEducationAndEmployementId } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import {
  AddEducation,
  EditEducation,
} from 'src/Redux/Slices/ApplicationSlices/EducationAndEmploymentSlice'
import { GradeList } from '..'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import { HasAssistant } from '../..'

const Education = ({
  chatForm,
  handleNext,
  handleBack,
}: {
  chatForm: any
  handleNext: () => void
  handleBack: () => void
}) => {
  const dispatch = useAppDispatch()

  const storeValues = useAppSelector(
    (state) => state?.applicationStore?.EducationAndEmployement?.Education,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const educationId = useAppSelector(
    (state) =>
      state?.applicationStore?.ApplicationId?.EducationAndEmployementId,
  )
  const [formValues, setFormValues] = useState<EducationType>({
    applicationId,
    educationId,
    highestGrade: '',
    schoolName: '',
    city: '',
    state: '',
    country: '',
    dateEducationCompleted: '',
  })
  const [errors, setErrors] = useState<EducationType>({})

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({ ...storeValues, applicationId, educationId })
    setErrors({})
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: { ...formValues },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])
  useEffect(() => {
    setErrors({ ...errors, state: '', country: '' })
  }, [formValues.state, formValues.country])

  const handleChange = (data: { name: string; value?: string | number }) => {
    setFormValues({ ...formValues, [data.name]: data.value })
    setErrors({ ...errors, [data.name]: '' })
  }

  const validation = () => {
    let isValid = true
    const newErrors: EducationType = {}
    const currentDate = new Date().toISOString().split('T')[0]
    if (!formValues.highestGrade) {
      newErrors.highestGrade = 'Please select an option'
      isValid = false
    }
    if (!formValues.schoolName) {
      newErrors.schoolName = 'Please enter school name'
      isValid = false
    }
    if (
      formValues.schoolName &&
      !/^[a-zA-Z0-9 &\s]+$/.test(formValues.schoolName)
    ) {
      newErrors.schoolName = 'Special characters are not allowed'
      isValid = false
    }
    if (!formValues.dateEducationCompleted) {
      newErrors.dateEducationCompleted = 'Please select date'
      isValid = false
    }
    if (formValues.dateEducationCompleted > currentDate) {
      newErrors.dateEducationCompleted = 'Please select valid date'
      isValid = false
    }
    if (!formValues.city) {
      newErrors.city = 'Please enter city'
      isValid = false
    }
    if (!formValues.state) {
      newErrors.state = 'Please enter state'
      isValid = false
    }
    if (!formValues.country) {
      newErrors.country = 'Please enter country'
      isValid = false
    }
    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (validation()) {
      dispatch(setEducation(formValues))
      if (educationId)
        dispatch(EditEducation(formValues)).then((res) => {
          if (res?.payload?.success) {
            handleNext()
          }
        })
      else
        dispatch(AddEducation(formValues)).then((res) => {
          const data = res?.payload?.data
          if (res?.payload?.success) {
            dispatch(setEducationAndEmployementId(data?.educationId))
            handleNext()
          }
        })
    }
  }

  return (
    <>
      <Box className={Style.DialogContentBox}>
        <Box className={Style.GridBox}>
          <form className={Style.Form} onSubmit={handleSubmit}>
            <ApplicationIntroBox
              heading={`In this section, we'll work through questions related to education, training, and employment. Let's go!`}
            />
            <InputLabel className={Style.label}>
              What's the highest level of education that $
              {HasAssistant() ? `applicant` : `you`} have completed?
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <CommonDropdown
              handleChange={handleChange}
              label='Highest Grade of School Completed'
              menulist={GradeList}
              name='highestGrade'
              value={formValues.highestGrade ?? ''}
              error={errors?.highestGrade}
            />
            {formValues.highestGrade && (
              <>
                <StarText
                  text={`Great! Now, let's gather a few details about the school ${HasAssistant() ? `applicant` : `you`} selected above.`}
                />
                <Name
                  title='School Name'
                  value={formValues.schoolName}
                  name='schoolName'
                  handleChange={handleChange}
                  error={errors?.schoolName}
                />
                <Address
                  errors={errors}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  handleChange={handleChange}
                />
                <Calander
                  title={'Date Completed'}
                  value={formValues.dateEducationCompleted}
                  name={'dateEducationCompleted'}
                  error={errors?.dateEducationCompleted}
                  handleChange={handleChange}
                />
              </>
            )}
            <Box className={Style.AddButton}>
              <div></div>
              <Button
                variant='contained'
                type='submit'
                className={Style.ButtonTwo}
              >
                Next
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default Education
