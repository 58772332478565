import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import {
  Button,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import star from 'src/Assets/Icons/star.svg'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import {
  ApplicationIntroBox,
  CommonDropdown,
  MultiSelectDropDown,
} from 'src/Components/Fields'
import { IllnessesInjuriesAndConditionsType } from 'src/Redux/Services/Types/applicationType'
import { AddIllnessesInjuriesAndConditions } from 'src/Redux/Slices/ApplicationSlices/MedicalDetailsSlice'
import { setIllnessesInjuriesAndConditions } from 'src/Redux/Slices/ApplicationStore/MedicalDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import {
  DigestiveAndExcretoryOptions,
  EyeOptions,
  FatigueOptions,
  FeverOptions,
  IllnessesInjuriesCondition,
  MemoryAndCognitionOptions,
  MentalHealthOptions,
  NeurologicalOptions,
  RespiratoryOptions,
  seizureAfterEffectExperience,
  SeizureOptions,
  SpeechDurationOptions,
  SpeechOptions,
  SymptomsOptions,
  visionIssueOptions,
} from 'src/Utils/Constants'
import Style from './index.module.scss'

// const StyledSelect = styled(Select)({
//   borderRadius: '10px',
// })

const IllnessesInjuriesConditions = ({
  handleEditToggle,
  chatForm,
}: {
  chatForm: any
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  const MedicalDetailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.MedicalDetailId,
  )

  const IllnessesInjuriesConditionsState = useAppSelector(
    (state) =>
      state?.applicationStore?.MedicalDetails?.IllnessesInjuriesAndConditions,
  )

  const [formValues, setFormValues] =
    useState<IllnessesInjuriesAndConditionsType>({
      medicalDetailId: MedicalDetailId,
      muscleOrMemoryRelatedSymptoms: [],
      energyOrSleepRelatedSymptoms: [],
      feverOrSkinRelatedSymptoms: [],
      breathingOrHeartRelatedSymptoms: [],
      speechRelatedSymptoms: [],
      memoryRelatedSymptoms: [],
      mentalHealthRelatedSymptoms: [],
      urinarySymptoms: [],
      dizzinessOrHeadacheRelatedSymptoms: [],
      isSeizureRelatedSymptoms: undefined,
      seizureRelatedSymptoms: [],
      anotherSymptoms: '',
      howLongSymptomLast: '',
      isAfterEffectSeizureExperience: undefined,
      seizureAfterEffectExperience: [],
      emotionalOrLearningProblems: [],
      speechSymptomsDuration: '',
      headachemigrainesymptoms: [],
      headachemigrainesymptomduration: '',
      seizureaftereffectsymptomsduration: '', // boolean
    })

  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )

  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            isSeizureRelatedSymptoms:
              formValues?.isSeizureRelatedSymptoms === undefined
                ? 'Boolean'
                : formValues?.isSeizureRelatedSymptoms,
            isAfterEffectSeizureExperience:
              formValues?.isAfterEffectSeizureExperience === undefined
                ? 'Boolean'
                : formValues?.isAfterEffectSeizureExperience,
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  useEffect(() => {
    setFormValues({
      ...IllnessesInjuriesConditionsState,
      medicalDetailId: MedicalDetailId,
    })
  }, [])
  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    if (formValues?.isSeizureRelatedSymptoms === undefined) {
      newErrors.isSeizureRelatedSymptoms = 'please select option'
      valid = false
    }
    if (formValues?.isSeizureRelatedSymptoms) {
      if (formValues.seizureRelatedSymptoms?.length === 0) {
        newErrors.seizureRelatedSymptoms = 'please select option'
        valid = false
      }
      if (formValues.seizureRelatedSymptoms.includes('Other')) {
        if (!formValues.anotherSymptoms?.trim()) {
          newErrors.anotherSymptoms = 'please enter description'
          valid = false
        }
      }

      if (!formValues.howLongSymptomLast?.trim()) {
        newErrors.howLongSymptomLast = 'please select option'
        valid = false
      }
      if (formValues?.isAfterEffectSeizureExperience === undefined) {
        newErrors.isAfterEffectSeizureExperience = 'please select option'
        valid = false
      }

      if (formValues.isAfterEffectSeizureExperience) {
        if (formValues.seizureAfterEffectExperience?.length === 0) {
          newErrors.seizureAfterEffectExperience = 'please select option'
          valid = false
        }
        if (!formValues.seizureaftereffectsymptomsduration?.trim()) {
          newErrors.seizureaftereffectsymptomsduration =
            'please enter description'
          valid = false
        }
      }
    }
    // if (!formValues.speechSymptomsDuration?.trim()) {
    //   newErrors.speechSymptomsDuration = 'please enter description'
    //   valid = false
    // }
    // if (!formValues.headachemigrainesymptomduration?.trim()) {
    //   newErrors.headachemigrainesymptomduration = 'please enter description'
    //   valid = false
    // }

    if (formValues.muscleOrMemoryRelatedSymptoms?.length === 0) {
      newErrors.muscleOrMemoryRelatedSymptoms = 'please select option'
      valid = false
    }

    if (formValues.energyOrSleepRelatedSymptoms?.length === 0) {
      newErrors.energyOrSleepRelatedSymptoms = 'please select option'
      valid = false
    }

    if (formValues.muscleOrMemoryRelatedSymptoms?.length === 0) {
      newErrors.muscleOrMemoryRelatedSymptoms = 'please select option'
      valid = false
    }

    if (formValues.feverOrSkinRelatedSymptoms?.length === 0) {
      newErrors.feverOrSkinRelatedSymptoms = 'please select option'
      valid = false
    }

    if (formValues.breathingOrHeartRelatedSymptoms?.length === 0) {
      newErrors.breathingOrHeartRelatedSymptoms = 'please select option'
      valid = false
    }

    if (formValues.speechRelatedSymptoms?.length === 0) {
      newErrors.speechRelatedSymptoms = 'please select option'
      valid = false
    }

    if (formValues.memoryRelatedSymptoms?.length === 0) {
      newErrors.memoryRelatedSymptoms = 'please select option'
      valid = false
    }

    if (formValues.mentalHealthRelatedSymptoms?.length === 0) {
      newErrors.mentalHealthRelatedSymptoms = 'please select option'
      valid = false
    }

    if (formValues.urinarySymptoms?.length === 0) {
      newErrors.urinarySymptoms = 'please select option'
      valid = false
    }

    if (formValues.memoryRelatedSymptoms?.length === 0) {
      newErrors.memoryRelatedSymptoms = 'please select option'
      valid = false
    }

    if (formValues.dizzinessOrHeadacheRelatedSymptoms?.length === 0) {
      newErrors.dizzinessOrHeadacheRelatedSymptoms = 'please select option'
      valid = false
    }

    if (formValues.emotionalOrLearningProblems?.length === 0) {
      newErrors.emotionalOrLearningProblems = 'please select option'
      valid = false
    }

    // if (formValues.headachemigrainesymptoms?.length === 0) {
    //   newErrors.headachemigrainesymptoms =
    //     'Please select at least one country for citizenship'
    //   valid = false
    // }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(AddIllnessesInjuriesAndConditions(formValues)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setIllnessesInjuriesAndConditions(formValues))
            handleEditToggle()
          }
        },
      )
    }
  }

  const handleSelectChange = (name: string[], label: string) => {
    if (name?.find((i) => i === 'None of the above')) {
      setFormValues((prev) => ({
        ...prev,
        [label]: ['None of the above'],
      }))
    } else
      setFormValues({
        ...formValues,
        [label]: name.filter((i) => i !== 'None of the above'),
      })
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox heading="You're on a roll! Next up: medical illnesses, injuries, and conditions." />

          <Box my={'20px'}>
            <MultiSelectDropDown
              name={'muscleOrMemoryRelatedSymptoms'}
              value={formValues.muscleOrMemoryRelatedSymptoms ?? []}
              error={errors.muscleOrMemoryRelatedSymptoms}
              label={
                "First, we'd like to understand if applicant is experiencing any muscle or mobility-related symptoms due to applicant‘s illness, injury, or condition. Please check all that apply"
              }
              menulist={SymptomsOptions}
              handleSelectChange={(name: string[]) => {
                handleSelectChange(name, 'muscleOrMemoryRelatedSymptoms')
              }}
            />
          </Box>

          <Box my={'20px'}>
            <MultiSelectDropDown
              name={'energyOrSleepRelatedSymptoms'}
              value={formValues.energyOrSleepRelatedSymptoms ?? []}
              error={errors.energyOrSleepRelatedSymptoms}
              label={
                "Next, we'd like to understand whether applicant experience any energy or sleep-related symptoms. Please check all that apply."
              }
              menulist={FatigueOptions}
              handleSelectChange={(name: string[]) => {
                handleSelectChange(name, 'energyOrSleepRelatedSymptoms')
              }}
            />
          </Box>

          <Box my={'20px'}>
            <MultiSelectDropDown
              name={'feverOrSkinRelatedSymptoms'}
              value={formValues.feverOrSkinRelatedSymptoms ?? []}
              error={errors.feverOrSkinRelatedSymptoms}
              label={
                'Next, help us understand if applicant experience any fever or skin-related symptoms. Please check all that apply.'
              }
              menulist={FeverOptions}
              handleSelectChange={(name: string[]) => {
                handleSelectChange(name, 'feverOrSkinRelatedSymptoms')
              }}
            />
          </Box>

          <Box my={'20px'}>
            <MultiSelectDropDown
              name={'breathingOrHeartRelatedSymptoms'}
              value={formValues.breathingOrHeartRelatedSymptoms ?? []}
              error={errors.breathingOrHeartRelatedSymptoms}
              label={
                "Next, we'd like to understand if applicant experience any breathing or heart-related symptoms. Please check all that apply."
              }
              menulist={RespiratoryOptions}
              handleSelectChange={(name: string[]) => {
                handleSelectChange(name, 'breathingOrHeartRelatedSymptoms')
              }}
            />
          </Box>

          <Box my={'20px'}>
            <MultiSelectDropDown
              name={'speechRelatedSymptoms'}
              value={formValues.speechRelatedSymptoms ?? []}
              error={errors.speechRelatedSymptoms}
              label={
                'Next, help us understand if applicant experience any speech-related symptoms due to applicant‘s illness, injury, or condition. Please check all that apply.'
              }
              menulist={SpeechOptions}
              handleSelectChange={(name: string[]) => {
                handleSelectChange(name, 'speechRelatedSymptoms')
              }}
            />
          </Box>
          {!formValues.speechRelatedSymptoms.includes('None of the above') && (
            <CommonDropdown
              handleChange={({ name, value }) => {
                handleFieldChange({ target: { name, value } })
              }}
              label='How long do applicant‘s longest speech-related symptoms last?'
              menulist={SpeechDurationOptions}
              name='speechSymptomsDuration'
              value={formValues.speechSymptomsDuration ?? ''}
              error={errors.speechSymptomsDuration}
            />
          )}

          <Box my={'20px'}>
            <MultiSelectDropDown
              name={'memoryRelatedSymptoms'}
              value={formValues.memoryRelatedSymptoms ?? []}
              error={errors.memoryRelatedSymptoms}
              label={
                "Next, we'd like to understand if applicant experience any memory-related symptoms. Please check all that apply."
              }
              menulist={MemoryAndCognitionOptions}
              handleSelectChange={(name: string[]) => {
                handleSelectChange(name, 'memoryRelatedSymptoms')
              }}
            />
          </Box>

          <Box my={'20px'}>
            <MultiSelectDropDown
              name={'mentalHealthRelatedSymptoms'}
              value={formValues.mentalHealthRelatedSymptoms ?? []}
              error={errors.mentalHealthRelatedSymptoms}
              label={
                'Next, help us understand if applicant experience any mental health-related symptoms. Please check all that apply.'
              }
              menulist={MentalHealthOptions}
              handleSelectChange={(name: string[]) => {
                handleSelectChange(name, 'mentalHealthRelatedSymptoms')
              }}
            />
          </Box>

          <Box my={'20px'}>
            <MultiSelectDropDown
              name={'urinarySymptoms'}
              value={formValues.urinarySymptoms ?? []}
              error={errors.urinarySymptoms}
              label={
                "Next, we'd like to understand if applicant experience any urinary symptoms. Please check all that apply."
              }
              menulist={DigestiveAndExcretoryOptions}
              handleSelectChange={(name: string[]) => {
                handleSelectChange(name, 'urinarySymptoms')
              }}
            />
          </Box>

          <Box my={'20px'}>
            <MultiSelectDropDown
              name={'dizzinessOrHeadacheRelatedSymptoms'}
              value={formValues.dizzinessOrHeadacheRelatedSymptoms ?? []}
              error={errors.dizzinessOrHeadacheRelatedSymptoms}
              label={
                'Next, help us understand if applicant experience any dizziness or headache-related symptoms. Please check all that apply.'
              }
              menulist={NeurologicalOptions}
              handleSelectChange={(name: string[]) => {
                handleSelectChange(name, 'dizzinessOrHeadacheRelatedSymptoms')
              }}
            />
          </Box>
          {!formValues.dizzinessOrHeadacheRelatedSymptoms.includes(
            'None of the above',
          ) && (
            <>
              <MultiSelectDropDown
                name={'headachemigrainesymptoms'}
                value={formValues.headachemigrainesymptoms ?? []}
                error={errors.headachemigrainesymptoms}
                label='Which of the following migraine or headache symptoms does applicant experience?'
                menulist={EyeOptions}
                handleSelectChange={(name: string[]) => {
                  handleSelectChange(name, 'headachemigrainesymptoms')
                }}
              />
              <CommonDropdown
                handleChange={({ name, value }) => {
                  handleFieldChange({ target: { name, value } })
                }}
                label='How long do applicant‘s longest migraine-related symptoms last?'
                menulist={SpeechDurationOptions}
                name='headachemigrainesymptomduration'
                value={formValues.headachemigrainesymptomduration ?? ''}
                error={errors.headachemigrainesymptomduration}
              />
            </>
          )}

          {/* Common radio */}
          <Box>
            <CommonRadioBox
              error={errors.isSeizureRelatedSymptoms}
              title='Next, help us understand if applicant experience any seizure-related symptoms due to applicant‘s illness, injury, or condition. Does applicant or has applicant previously experienced seizures?'
              commonState={formValues?.isSeizureRelatedSymptoms}
              setcommonState={(value: boolean) => {
                setFormValues({
                  ...formValues,
                  isSeizureRelatedSymptoms: value,
                })
              }}
              YesTitle='I have previously experienced seizures'
              NoTitle='I have not experienced seizures previously'
            />
          </Box>

          {formValues?.isSeizureRelatedSymptoms ? (
            <>
              <Box my={'20px'}>
                <MultiSelectDropDown
                  name={'seizureRelatedSymptoms'}
                  value={formValues.seizureRelatedSymptoms ?? []}
                  error={errors.seizureRelatedSymptoms}
                  label={
                    'What symptoms does applicant experience during a seizure?'
                  }
                  menulist={SeizureOptions}
                  handleSelectChange={(name: string[]) => {
                    handleSelectChange(name, 'seizureRelatedSymptoms')
                  }}
                />
              </Box>
              {formValues.seizureRelatedSymptoms.includes('Other') ? (
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      If there is another symptom, please explain.
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='anotherSymptoms'
                      name='anotherSymptoms'
                      multiline
                      rows={4}
                      className={Style.AnsTxt}
                      placeholder='Other'
                      value={formValues?.anotherSymptoms}
                      onChange={handleFieldChange}
                      error={!!errors.anotherSymptoms}
                      helperText={errors.anotherSymptoms}
                    />
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              <Grid spacing={2} xs={12} my={'30px'}>
                <InputLabel className={Style.label}>
                  How long do applicant‘s symptoms last?
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Box sx={{ display: 'flex' }} gap={2}>
                  <Grid item>
                    <Button
                      onClick={() => {
                        setFormValues({
                          ...formValues,
                          howLongSymptomLast: '1-5 minutes',
                        })
                      }}
                      className={
                        formValues.howLongSymptomLast === '1-5 minutes'
                          ? Style.theActiveBtn
                          : Style.theBtn
                      }
                    >
                      1-5 minutes
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        setFormValues({
                          ...formValues,
                          howLongSymptomLast: '5-10 minutes',
                        })
                      }}
                      className={
                        formValues.howLongSymptomLast === '5-10 minutes'
                          ? Style.theActiveBtn
                          : Style.theBtn
                      }
                    >
                      5-10 minutes
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        setFormValues({
                          ...formValues,
                          howLongSymptomLast: '10+ minutes',
                        })
                      }}
                      className={
                        formValues.howLongSymptomLast === '10+ minutes'
                          ? Style.theActiveBtn
                          : Style.theBtn
                      }
                    >
                      10+ minutes
                    </Button>
                  </Grid>
                </Box>
                <FormHelperText>{errors?.howLongSymptomLast}</FormHelperText>
              </Grid>

              {/* Common radio */}
              <Box>
                <CommonRadioBox
                  error={errors?.isAfterEffectSeizureExperience}
                  title='Does applicant have after-effects following a seizure?'
                  commonState={formValues?.isAfterEffectSeizureExperience}
                  setcommonState={(value: boolean) => {
                    setFormValues({
                      ...formValues,
                      isAfterEffectSeizureExperience: value,
                    })
                  }}
                  YesTitle='I have after-effects following a seizure'
                  NoTitle='I do not have after-effects following a seizure'
                />
              </Box>

              {/* <Box my={'20px'}>
            <MultiSelectDropDown
              name={'seizureAfterEffectExperience'}
              value={
                formValues.seizureAfterEffectExperience ??
                []
              }
              error={
                errors.seizureAfterEffectExperience
              }
              label={
                "Which of the following seizure after-effects does applicant experience?"
              }
              menulist={visionIssueOptions}
              handleSelectChange={(name: string) => {
                handleSelectChange(
                  name,
                  'seizureAfterEffectExperience',
                )
              }}
            />
          </Box> */}

              {formValues?.isAfterEffectSeizureExperience ? (
                <>
                  <Box my={'20px'}>
                    <MultiSelectDropDown
                      name={'seizureAfterEffectExperience'}
                      value={formValues.seizureAfterEffectExperience ?? []}
                      error={errors.seizureAfterEffectExperience}
                      label={
                        'Which of the following seizure after-effects does applicant experience?'
                      }
                      menulist={seizureAfterEffectExperience}
                      handleSelectChange={(name: string[]) => {
                        handleSelectChange(name, 'seizureAfterEffectExperience')
                      }}
                    />
                  </Box>
                  <Grid spacing={2} xs={12} my={'30px'}>
                    <InputLabel className={Style.label}>
                      How long do applicant‘s longest seizure-related symptoms
                      last?
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <Box sx={{ display: 'flex' }} gap={2}>
                      <Grid item>
                        <Button
                          onClick={() => {
                            setFormValues({
                              ...formValues,
                              seizureaftereffectsymptomsduration:
                                'Less than an hour',
                            })
                          }}
                          className={
                            formValues.seizureaftereffectsymptomsduration ===
                            'Less than an hour'
                              ? Style.theActiveBtn
                              : Style.theBtn
                          }
                        >
                          Less than an hour
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => {
                            setFormValues({
                              ...formValues,
                              seizureaftereffectsymptomsduration: '2-4 hours',
                            })
                          }}
                          className={
                            formValues.seizureaftereffectsymptomsduration ===
                            '2-4 hours'
                              ? Style.theActiveBtn
                              : Style.theBtn
                          }
                        >
                          2-4 hours
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => {
                            setFormValues({
                              ...formValues,
                              seizureaftereffectsymptomsduration: '5-7 hours',
                            })
                          }}
                          className={
                            formValues.seizureaftereffectsymptomsduration ===
                            '5-7 hours'
                              ? Style.theActiveBtn
                              : Style.theBtn
                          }
                        >
                          5-7 hours
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => {
                            setFormValues({
                              ...formValues,
                              seizureaftereffectsymptomsduration: '8+ hours',
                            })
                          }}
                          className={
                            formValues.seizureaftereffectsymptomsduration ===
                            '8+ hours'
                              ? Style.theActiveBtn
                              : Style.theBtn
                          }
                        >
                          8+ hours
                        </Button>
                      </Grid>
                    </Box>
                    <FormHelperText>
                      {errors?.seizureaftereffectsymptomsduration}
                    </FormHelperText>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          <Box my={'20px'}>
            <MultiSelectDropDown
              name={'emotionalOrLearningProblems'}
              value={formValues.emotionalOrLearningProblems ?? []}
              error={errors.emotionalOrLearningProblems}
              label={
                "You're doing great. To help us understand the extent of applicant‘s situation, please select all of applicant‘s physical and mental illnesses, injuries, and conditions from the dropdown that limit applicant‘s ability to work."
              }
              menulist={IllnessesInjuriesCondition}
              handleSelectChange={(name: string[]) => {
                handleSelectChange(name, 'emotionalOrLearningProblems')
              }}
            />
          </Box>

          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              p: 2,
              my: 4,
              backgroundColor: '#ECF6FF',
              borderRadius: '10px',
            }}
          >
            <img src={star} className={Style.star} alt='' />
            <Typography>
              <i>Remember to include emotional or learning problems.</i>
            </Typography>
          </Box>

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                handleEditToggle()
              }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default IllnessesInjuriesConditions
