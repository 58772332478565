import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Box, Button, Typography } from '@mui/material'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import {
  AddButton,
  ApplicationIntroBox,
  CommonDropdown,
  StarText,
} from 'src/Components/Fields'
import { MilitaryServiceType } from 'src/Redux/Services/Types/applicationType'
import { AddMilitaryService } from 'src/Redux/Slices/ApplicationSlices/BasicDetailsSlice'
import { setMilitaryService } from 'src/Redux/Slices/ApplicationStore/BasicDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../index.module.scss'
import ProfileBox from './ProfileBox'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import { HasAssistant } from '../..'

const MilitaryService = ({
  chatForm,
  handleNext,
  handleBack,
}: {
  chatForm: any
  handleBack: () => void
  handleNext: () => void
}) => {
  const dispatch = useAppDispatch()

  const MilitaryServiceState = useAppSelector(
    (state) => state?.applicationStore?.BasicDetails?.MilitaryService,
  )
  const detailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.basicId,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const [selectedDate, setSelectedDate] = useState<
    Date | null | undefined | any
  >(null)
  const [endDate, setEndDate] = useState<Date | null | undefined>(null)
  const [isChecked, setIsChecked] = useState<boolean[]>([false]) // Initialize the state variable

  const [formValues, setFormValues] = useState<MilitaryServiceType>({
    basicDetailId: detailId,
    isServedInMilitary: undefined,
    isReceivingFederalAgencyBenefit: undefined,
    typeOfBenefit: '',
    serviceInformation: [
      {
        typeOfDuty: '',
        branchOfService: '',
        startDate: '',
        endDate: '',
      },
    ],
  })

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
    setEndDate(date)
  }

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({
      ...MilitaryServiceState,
      basicDetailId: detailId,
    })
    setIsChecked(
      MilitaryServiceState?.serviceInformation?.map(
        (item: {
          typeOfDuty: string
          branchOfService: string
          startDate: string
          endDate: string
        }) => !item.endDate,
      ),
    )
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            isServedInMilitary:
              formValues?.isServedInMilitary === undefined
                ? 'Boolean'
                : formValues?.isServedInMilitary,
            isReceivingFederalAgencyBenefit:
              formValues?.isReceivingFederalAgencyBenefit === undefined
                ? 'Boolean'
                : formValues?.isReceivingFederalAgencyBenefit,
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  const [errors, setErrors] = useState<
    Record<string, string | Array<Record<string, string>>>
  >({ serviceInformation: [{}] })

  // useEffect(() => {
  //   if (isChecked) {
  //     const newErrors: typeof errors = {
  //       serviceInformation: [
  //         {
  //           endDate: '',
  //         },
  //       ],
  //     }
  //     setErrors(newErrors)
  //   }
  // }, [isChecked])

  const handleFieldChange = (data: {
    name: string
    value: string | number | boolean
  }) => {
    const { name, value } = data
    setFormValues({ ...formValues, [name]: value })
  }

  const validateForm = () => {
    let valid = true
    const newErrors: typeof errors = {
      serviceInformation: [{}],
    }
    const currentDate = new Date().toISOString().split('T')[0]
    if (formValues?.isServedInMilitary?.valueOf() === undefined) {
      newErrors.isServedInMilitary = 'Please select an option'
      valid = false
    }
    if (formValues.isServedInMilitary === true) {
      for (let i = 0; i < formValues?.serviceInformation?.length; i++) {
        if (!formValues?.serviceInformation[i].typeOfDuty.trim()) {
          newErrors.serviceInformation = (
            newErrors.serviceInformation as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return { ...error, typeOfDuty: 'Type of Duty is required' }
            return error
          })
          valid = false
        }
        if (!formValues?.serviceInformation[i].branchOfService.trim()) {
          newErrors.serviceInformation = (
            newErrors.serviceInformation as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return {
                ...error,
                branchOfService: 'Branch of Service is required',
              }
            return error
          })
          valid = false
        }

        if (!formValues?.serviceInformation[i].startDate) {
          newErrors.serviceInformation = (
            newErrors.serviceInformation as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return { ...error, startDate: 'Start Date is required' }
            return error
          })
          valid = false
        }
        if (formValues?.serviceInformation[i].startDate > currentDate) {
          newErrors.serviceInformation = (
            newErrors.serviceInformation as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return {
                ...error,
                startDate: 'Start Date cannot be in the future',
              }
            return error
          })
          valid = false
        }
        if (formValues?.serviceInformation[i].endDate > currentDate) {
          newErrors.serviceInformation = (
            newErrors.serviceInformation as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return {
                ...error,
                endDate: 'End Date cannot be in the future',
              }
            return error
          })
          valid = false
        }

        if (!isChecked[i]) {
          if (!formValues?.serviceInformation[i].endDate) {
            newErrors.serviceInformation = (
              newErrors.serviceInformation as Array<Record<string, string>>
            )?.map((error, index) => {
              if (i === index)
                return { ...error, endDate: 'End Date is required' }
              return error
            })
            valid = false
          }
        }

        ;(newErrors.serviceInformation as Array<Record<string, string>>).push(
          {},
        )
      }
    }
    if (formValues?.isReceivingFederalAgencyBenefit === true) {
      if (!formValues.typeOfBenefit?.trim()) {
        newErrors.typeOfBenefit = 'Type of Benefit is required'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(setMilitaryService(formValues))
      const formValuesToSubmit: typeof formValues = {}
      formValuesToSubmit.basicDetailId = detailId
      formValuesToSubmit.isServedInMilitary = formValues.isServedInMilitary
      if (formValues.isServedInMilitary) {
        formValuesToSubmit.serviceInformation = formValues.serviceInformation
        formValuesToSubmit.isReceivingFederalAgencyBenefit =
          formValues.isReceivingFederalAgencyBenefit
        if (formValues.isReceivingFederalAgencyBenefit) {
          formValuesToSubmit.typeOfBenefit = formValues.typeOfBenefit
        }
      }
      if (formValues.isServedInMilitary === false) {
        formValuesToSubmit.basicDetailId = detailId
        formValuesToSubmit.isServedInMilitary = formValues.isServedInMilitary
      }
      dispatch(AddMilitaryService(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            handleNext()
          }
        },
      )
    }
  }

  const handleValuesChange = (
    index: number,
    data: {
      name: string
      value: string | number | boolean
    },
  ) => {
    const { name, value } = data
    let newProfileForms = [...formValues.serviceInformation]
    newProfileForms = newProfileForms.map(
      (
        item: {
          typeOfDuty: string
          branchOfService: string
          startDate: string
          endDate: string
        },
        i: number,
      ) => {
        if (i === index) {
          return {
            ...item,
            [name]: value,
          }
        }
        return item
      },
    )
    // newProfileForms[`${index}`][`${data.name}`] = data.value
    setFormValues({ ...formValues, serviceInformation: newProfileForms })
  }

  const handleRemove = (index: number) => {
    const newProfileForms = [...formValues.serviceInformation]
    newProfileForms.splice(index, 1)
    setFormValues({ ...formValues, serviceInformation: newProfileForms })
    setIsChecked([...isChecked.slice(0, index), ...isChecked.slice(index + 1)])
  }

  const addProfileBox = () => {
    setFormValues({
      ...formValues,
      serviceInformation: [
        ...formValues.serviceInformation,
        {
          typeOfDuty: '',
          branchOfService: '',
          startDate: '',
          endDate: '',
        },
      ],
    })
    setIsChecked([...isChecked, false])
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox heading='Good work! Next topic: military service.' />

          <CommonRadioBox
            error={errors?.isServedInMilitary as string}
            title={
              HasAssistant()
                ? 'Did applicant serve or is applicant serving in the U.S. military?'
                : 'Did you serve or are you serving in the U.S. military?'
            }
            commonState={formValues.isServedInMilitary}
            setcommonState={(value: boolean) => {
              handleFieldChange({
                name: 'isServedInMilitary',
                value,
              })
            }}
            YesTitle='I served or am serving in the U.S. military.'
            NoTitle='I did not serve, nor am I serving in the U.S. military.'
          />

          {formValues.isServedInMilitary === true ? (
            <>
              <StarText
                text={`Thank ${HasAssistant() ? `applicant` : `you`} for ${HasAssistant() ? `their` : `your`} service! Please tell us a little more.`}
              />

              <Box display={'flex'} flexDirection={'column'}>
                <Typography className={Style.label}>
                  Military Service Information
                </Typography>

                {formValues.serviceInformation.map((form, index) => (
                  <ProfileBox
                    selectedDate={selectedDate}
                    endDate={endDate}
                    handleDateChange={handleDateChange}
                    errors={
                      errors.serviceInformation[index] as Record<string, string>
                    }
                    key={index}
                    handleRemove={() => {
                      handleRemove(index)
                    }}
                    index={index}
                    formValues={form}
                    changeHandler={(data) => {
                      handleValuesChange(index, data)
                    }}
                    isChecked={isChecked[index]}
                    setIsChecked={(value) => {
                      setIsChecked(
                        isChecked.map((val, i) => (i === index ? value : val)),
                      )
                    }}
                  />
                ))}

                <Box height={'30px'} width={'100%'} />

                <AddButton
                  title='Service, Past and Present'
                  subTitle={`For comprehensive representation of ${HasAssistant() ? "applicant's" : 'your'} situation, It is important that all service be shown below`}
                  button='Add Military Service'
                  onClick={addProfileBox}
                />
              </Box>

              <CommonRadioBox
                error={errors?.isReceivingFederalAgencyBenefit as string}
                title={
                  HasAssistant()
                    ? 'Is applicant receiving or eligible to receive a military or civilian federal agency benefit?'
                    : 'Are you receiving or eligible to receive a military or civilian federal agency benefit?'
                }
                commonState={formValues.isReceivingFederalAgencyBenefit}
                setcommonState={(value: boolean) => {
                  handleFieldChange({
                    name: 'isReceivingFederalAgencyBenefit',
                    value,
                  })
                }}
                YesTitle='I am receiving or eligible to receive a military or civilian federal agency benefit'
                NoTitle='I am not receiving or eligible to receive a military or civilian federal agency benefit'
              />

              {formValues.isReceivingFederalAgencyBenefit === true ? (
                <>
                  <CommonDropdown
                    name='typeOfBenefit'
                    value={formValues.typeOfBenefit ?? ''}
                    label='Got it. What type of benefit is it?'
                    menulist={[
                      { name: 'Military', value: 'Military' },
                      { name: 'Civilian', value: 'Civilian' },
                      { name: 'Both', value: 'Both' },
                    ]}
                    handleChange={handleFieldChange}
                    error={errors.typeOfBenefit as string}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleBack}
            >
              Previous
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
              // onClick={handleNext}
            >
              Next
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default MilitaryService
