import React from 'react'

import DisabilityBackground from './DisabilityBackground/index'
import DisabilityEmployment from './DisabilityEmployment/index'
import PayoutSettlement from './PayoutSettlements/index'

const DisabilityDetails: React.FunctionComponent = ({
  chatForm,
}: {
  chatForm?: any
}) => {
  return (
    <>
      <DisabilityBackground chatForm={chatForm} />
      <DisabilityEmployment chatForm={chatForm} />
      <PayoutSettlement chatForm={chatForm} />
    </>
  )
}

export default DisabilityDetails
