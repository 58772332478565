import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { ApplicationIntroBox, Calander, StarText } from 'src/Components/Fields'
import { DisabilityBackgroundType } from 'src/Redux/Services/Types/applicationType'
import {
  AddDisabilityBackground,
  EditDisabilityBackground,
} from 'src/Redux/Slices/ApplicationSlices/DisabilityDetailsSlice'
import {
  setDisabilityId,
  setDisqualification,
  setIsDisqualified,
} from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { setDisabilityBackground } from 'src/Redux/Slices/ApplicationStore/DisabilityDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from './index.module.scss'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import { HasAssistant } from '../..'

// const StyledSelect = styled(Select)({
//   borderRadius: '10px',
// })

const DisabilityBackground = ({
  chatForm,
  handleNext,
  handleBack,
}: {
  chatForm?: any
  setLegalId?: (value: string) => void
  handleBack: () => void
  handleNext: () => void
}) => {
  const dispatch = useAppDispatch()
  // const navigate = useNavigate()

  const ReceivingDisabilityState = useAppSelector(
    (state) => state?.applicationStore?.DisabilityDetails?.DisabilityBackground,
  )

  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const disabilityId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.disabilityId,
  )
  // const [dateOfBirth, setDateOfBirth] = useState<Date | null | string>()
  const [formValues, setFormValues] = useState<DisabilityBackgroundType>({
    applicationId,
    isAbleToWork: undefined,
    isAbleToPerformUsualJob: undefined,
    is14MonthsUnableToDoJob: undefined,
    disabilityStartDate: '',
    isDisabilityExpected1YearOrLonger: undefined,
    disabilityEndInDeath: undefined,
    disabilityRelatedToWork: undefined,
  })

  // useEffect(() => {
  //   setFormValues((prevState) => ({
  //     ...prevState,
  //     legalId,
  //     isHavingBankAccount: bankAccount,
  //   }))
  // }, [legalId, bankAccount])

  // useEffect(() => {
  //   if (formValues?.haveMadeMoreMoneyInAnyMonth !== undefined) {
  //     dispatch(
  //       setDisqualification({
  //         haveMadeMoreMoneyInAnyMonth: formValues?.haveMadeMoreMoneyInAnyMonth,
  //       }),
  //     )
  //     dispatch(setIsDisqualified(formValues?.haveMadeMoreMoneyInAnyMonth))
  //   }
  // }, [formValues?.haveMadeMoreMoneyInAnyMonth])

  useEffect(() => {
    if (
      formValues?.isDisabilityExpected1YearOrLonger !== undefined ||
      formValues?.isDisabilityExpected1YearOrLonger !== 'Boolean'
    ) {
      if (formValues?.isDisabilityExpected1YearOrLonger === false) {
        dispatch(
          setDisqualification({
            isDisabilityExpected1YearOrLonger: false,
          }),
        )
        dispatch(setIsDisqualified(true))
      }
      if (formValues?.isDisabilityExpected1YearOrLonger === true) {
        dispatch(
          setDisqualification({
            isDisabilityExpected1YearOrLonger: true,
          }),
        )
        dispatch(setIsDisqualified(false))
      }
    }
  }, [formValues?.isDisabilityExpected1YearOrLonger, chatForm])

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({
      ...ReceivingDisabilityState,
      applicationId,
      disabilityId,
    })
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            isAbleToWork:
              formValues?.isAbleToWork === undefined
                ? 'Boolean'
                : formValues?.isAbleToWork,
            isAbleToPerformUsualJob:
              formValues?.isAbleToPerformUsualJob === undefined
                ? 'Boolean'
                : formValues?.isAbleToPerformUsualJob,
            is14MonthsUnableToDoJob:
              formValues?.is14MonthsUnableToDoJob === undefined
                ? 'Boolean'
                : formValues?.is14MonthsUnableToDoJob,
            disabilityRelatedToWork:
              formValues?.disabilityRelatedToWork === undefined
                ? 'Boolean'
                : formValues?.disabilityRelatedToWork,
            isDisabilityExpected1YearOrLonger:
              formValues?.isDisabilityExpected1YearOrLonger === undefined
                ? 'Boolean'
                : formValues?.isDisabilityExpected1YearOrLonger,
            disabilityEndInDeath:
              formValues?.disabilityEndInDeath === undefined
                ? 'Boolean'
                : formValues?.disabilityEndInDeath,
          },
        }),
      )
  }, [isBotOpen, disabilityId])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])
  // const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target
  //   setFormValues({ ...formValues, [name]: value })
  //   // You may perform validation here and update errors state accordingly
  // }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    if (formValues?.isAbleToWork === undefined) {
      newErrors.isAbleToWork = 'Please select an option'
      valid = false
    }
    if (formValues?.disabilityRelatedToWork === undefined) {
      newErrors.disabilityRelatedToWork = 'Please select an option'
      valid = false
    }
    if (formValues?.isAbleToWork) {
      if (formValues?.isAbleToPerformUsualJob === undefined) {
        newErrors.isAbleToPerformUsualJob = 'Please select an option'
        valid = false
      }
      if (formValues?.isAbleToPerformUsualJob) {
        if (formValues?.is14MonthsUnableToDoJob === undefined) {
          newErrors.is14MonthsUnableToDoJob = 'Please select an option'
          valid = false
        }
        if (formValues?.isDisabilityExpected1YearOrLonger === undefined) {
          newErrors.isDisabilityExpected1YearOrLonger =
            'Please select an option'
          valid = false
        }
      } else if (formValues?.isAbleToPerformUsualJob === false) {
        if (formValues?.isDisabilityExpected1YearOrLonger === undefined) {
          newErrors.isDisabilityExpected1YearOrLonger =
            'Please select an option'
          valid = false
        }
        if (formValues?.disabilityStartDate === '') {
          newErrors.disabilityStartDate = 'Please select Date'
          valid = false
        }
        const currentDate = new Date().toISOString().split('T')[0]
        if (formValues?.disabilityStartDate > currentDate) {
          newErrors.disabilityStartDate = 'Please select valid Date'
          valid = false
        }
        if (formValues?.disabilityEndInDeath === undefined) {
          newErrors.disabilityEndInDeath = 'Please select an option'
          valid = false
        }
      }
    }
    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(setDisabilityBackground(formValues))

      const formValuesToSubmit: typeof formValues = {}
      formValuesToSubmit.applicationId = applicationId
      formValuesToSubmit.isAbleToWork = formValues?.isAbleToWork
      formValuesToSubmit.disabilityRelatedToWork =
        formValues?.disabilityRelatedToWork
      if (formValues?.isAbleToWork) {
        formValuesToSubmit.isAbleToPerformUsualJob =
          formValues?.isAbleToPerformUsualJob
        if (formValues?.isAbleToPerformUsualJob) {
          formValuesToSubmit.is14MonthsUnableToDoJob =
            formValues?.is14MonthsUnableToDoJob
          formValuesToSubmit.isDisabilityExpected1YearOrLonger =
            formValues?.isDisabilityExpected1YearOrLonger
          // if (formValues?.is14MonthsUnableToDoJob) {
          //   formValuesToSubmit.isDisabilityExpected1YearOrLonger =
          //     formValues?.isDisabilityExpected1YearOrLonger
          // }
        } else if (formValues?.isAbleToPerformUsualJob === false) {
          formValuesToSubmit.isDisabilityExpected1YearOrLonger =
            formValues?.isDisabilityExpected1YearOrLonger
          formValuesToSubmit.disabilityStartDate =
            formValues?.disabilityStartDate
          formValuesToSubmit.disabilityEndInDeath =
            formValues?.disabilityEndInDeath
        }
      }
      //  else {
      //   formValuesToSubmit.isDisabilityExpected1YearOrLonger =
      //     formValues?.isDisabilityExpected1YearOrLonger
      //   formValuesToSubmit.disabilityStartDate = formValues?.disabilityStartDate
      //   formValuesToSubmit.disabilityEndInDeath =
      //     formValues?.disabilityEndInDeath
      // }

      if (!disabilityId) {
        dispatch(AddDisabilityBackground(formValuesToSubmit)).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            const data = res?.payload?.data
            if (res?.payload?.success) {
              dispatch(setDisabilityId(data?.disabilityId))
              handleNext()
            }
          },
        )
      } else if (disabilityId) {
        dispatch(
          EditDisabilityBackground({ ...formValuesToSubmit, disabilityId }),
        ).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            const data = res?.payload?.data
            if (res?.payload?.success) {
              // dispatch(setDisabilityId(data?.disabilityId))
              handleNext()
            }
          },
        )
      }
    }
  }

  return (
    <>
      {process.env.REACT_APP_MILESTONE_4 ? (
        <>
          <Box className={Style.DialogContentBox}>
            <Box className={Style.GridBox}>
              <form className={Style.Form} onSubmit={handleSubmit}>
                <ApplicationIntroBox
                  heading={`Hi there! This section will work through questions related
                    to ${HasAssistant() ? `applicant's` : `your`} disability and its impact on ${HasAssistant() ? `applicant's` : `your`} job. This
                    information helps us better understand ${HasAssistant() ? `applicant's` : `your`} work status and
                    the role that ${HasAssistant() ? `applicant's` : `your`} disability is playing.`}
                />

                <Box>
                  <CommonRadioBox
                    error={errors?.isAbleToWork}
                    title={
                      HasAssistant()
                        ? "Is applicant currently able to work regardless of applicant's illness, injury, or condition?"
                        : 'Are you currently able to work regardless of your illness, injury, or condition?'
                    }
                    commonState={formValues?.isAbleToWork}
                    setcommonState={(value: boolean) => {
                      setFormValues({ ...formValues, isAbleToWork: value })
                    }}
                    YesTitle='I am currently able to work regardless of my illness, injury, or condition'
                    NoTitle='I am not currently able to work regardless of my illness, injury, or condition'
                  />
                </Box>

                {formValues?.isAbleToWork ? (
                  <>
                    <Box>
                      <CommonRadioBox
                        error={errors?.isAbleToPerformUsualJob}
                        title={
                          HasAssistant()
                            ? 'Currently, is applicant able to perform their usual job responsibilities regardless of their illness, injury, or condition?'
                            : 'Currently, are you able to perform your usual job responsibilities regardless of your illness, injury, or condition?'
                        }
                        commonState={formValues?.isAbleToPerformUsualJob}
                        setcommonState={(value: boolean) => {
                          setFormValues({
                            ...formValues,
                            isAbleToPerformUsualJob: value,
                          })
                        }}
                        YesTitle='I am able to perform usual job responsibilities'
                        NoTitle='I am not able to perform usual job responsibilities'
                      />
                    </Box>

                    {formValues?.isAbleToPerformUsualJob ? (
                      <>
                        <Box>
                          <CommonRadioBox
                            error={errors?.is14MonthsUnableToDoJob}
                            title={
                              HasAssistant()
                                ? 'During the last 14 months, has applicant been unable to work or perform their usual job duties?'
                                : 'During the last 14 months, have you been unable to work or perform your usual job duties?'
                            }
                            commonState={formValues?.is14MonthsUnableToDoJob}
                            setcommonState={(value: boolean) => {
                              setFormValues({
                                ...formValues,
                                is14MonthsUnableToDoJob: value,
                              })
                            }}
                            YesTitle='During the last 14 months, I have been unable to work or perform your usual job duties'
                            NoTitle='During the last 14 months, I have been able to work or perform your usual job duties'
                          />
                        </Box>

                        <Box>
                          <CommonRadioBox
                            error={errors?.isDisabilityExpected1YearOrLonger}
                            title='Is the illness, injury, or condition expected to last one (1) year or longer?'
                            commonState={
                              formValues?.isDisabilityExpected1YearOrLonger
                            }
                            setcommonState={(value: boolean) => {
                              setFormValues({
                                ...formValues,
                                isDisabilityExpected1YearOrLonger: value,
                              })
                            }}
                            YesTitle='The illness, injury, or condition expected to last one (1) year or longer'
                            NoTitle='The illness, injury, or condition not expected to last one (1) year or longer'
                          />
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* {formValues?.is14MonthsUnableToDoJob === false ? (
                  <></>
                ) : (
                  <>
                    <Box>
                      <CommonRadioBox
                        error={errors?.isDisabilityExpected1YearOrLonger}
                        title='Is the illness, injury, or condition expected to last one (1) year or longer?'
                        commonState={
                          formValues?.isDisabilityExpected1YearOrLonger
                        }
                        setcommonState={(value: boolean) => {
                          setFormValues({
                            ...formValues,
                            isDisabilityExpected1YearOrLonger: value,
                          })
                        }}
                        YesTitle='The illness, injury, or condition expected to last one (1) year or longer'
                        NoTitle='The illness, injury, or condition not expected to last one (1) year or longer'
                      />
                    </Box>
                  </>
                )} */}

                    {formValues?.isAbleToPerformUsualJob === false ? (
                      <>
                        <Box>
                          <CommonRadioBox
                            error={errors?.isDisabilityExpected1YearOrLonger}
                            title='Is the illness, injury, or condition expected to last one (1) year or longer?'
                            commonState={
                              formValues?.isDisabilityExpected1YearOrLonger
                            }
                            setcommonState={(value: boolean) => {
                              setFormValues({
                                ...formValues,
                                isDisabilityExpected1YearOrLonger: value,
                              })
                            }}
                            YesTitle='The illness, injury, or condition expected to last one (1) year or longer'
                            NoTitle='The illness, injury, or condition not expected to last one (1) year or longer'
                          />
                        </Box>

                        {formValues?.isDisabilityExpected1YearOrLonger && (
                          <StarText
                            text={`We're sorry to hear this. What was the date ${HasAssistant() ? `applicant` : `you`} were no longer physically able to work?`}
                          />
                        )}

                        <Calander
                          title={'Injury, illness or condition start date'}
                          value={formValues.disabilityStartDate ?? ''}
                          name={'disabilityStartDate'}
                          error={errors?.disabilityStartDate}
                          handleChange={({ value }) => {
                            setFormValues({
                              ...formValues,
                              disabilityStartDate: value,
                            })
                          }}
                        />

                        <Box my={'30px'}>
                          <CommonRadioBox
                            error={errors?.disabilityEndInDeath}
                            title={
                              HasAssistant()
                                ? "Has a medical professional described any of applicant's specific conditions as one thats expected to end in death?"
                                : 'Has a medical professional described any of your specific conditions as one thats expected to end in death?'
                            }
                            commonState={formValues?.disabilityEndInDeath}
                            setcommonState={(value: boolean) => {
                              setFormValues({
                                ...formValues,
                                disabilityEndInDeath: value,
                              })
                            }}
                            YesTitle='A medical professional has described any of my specific conditions as one thats expected to end in death.'
                            NoTitle='A medical professional has not described any of my specific conditions as one thats expected to end in death.'
                          />
                        </Box>
                        {formValues?.disabilityEndInDeath && (
                          <StarText
                            text={
                              "We're so sorry to hear this. Our hearts go out to them, and we promise to offer all of the support that we can during this difficult time."
                            }
                          />
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <Box>
                  <CommonRadioBox
                    error={errors?.disabilityRelatedToWork}
                    title={
                      HasAssistant()
                        ? "Is applicant's illness(es), injuries, or condition(s) related to work in any way?"
                        : 'Are your illness(es), injuries, or condition(s) related to work in any way?'
                    }
                    commonState={formValues?.disabilityRelatedToWork}
                    setcommonState={(value: boolean) => {
                      setFormValues({
                        ...formValues,
                        disabilityRelatedToWork: value,
                      })
                    }}
                    YesTitle='Yes, my illness(es), injury(s), or condition(s) are related to work in any way'
                    NoTitle='No, my illness(es), injury(s), or condition(s) are not related to work in any way'
                  />
                </Box>

                <Box className={Style.AddButton}>
                  <Button
                    sx={{ visibility: 'hidden' }}
                    variant='contained'
                    className={Style.ButtonOne}
                    onClick={handleBack}
                  >
                    Previous
                  </Button>
                  <Button
                    variant='contained'
                    type='submit'
                    className={Style.ButtonTwo}
                    disabled={
                      formValues?.isDisabilityExpected1YearOrLonger === false
                    }
                  >
                    Next
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default DisabilityBackground
