import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import {
  ApplicationIntroBox,
  CommonDropdown,
  MultiSelectDropDown,
  Name,
} from 'src/Components/Fields'
import { ForeignWorkType } from 'src/Redux/Services/Types/applicationType'
import { EditForeignWork } from 'src/Redux/Slices/ApplicationSlices/EducationAndEmploymentSlice'
import { setForeignWork } from 'src/Redux/Slices/ApplicationStore/EducationAndEmployement'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../index.module.scss'
import {
  setDisqualification,
  setIsDisqualified,
} from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import { currentYear, previousYear } from 'src/Utils/Constants'
import { HasAssistant } from '../..'

const ForeignWork = ({
  chatForm,
  handleNext,
  handleBack,
}: {
  chatForm?: JsonData
  handleNext: () => void
  handleBack: () => void
}) => {
  const dispatch = useAppDispatch()
  const monthList = [
    { name: 'January', value: 'January' },
    { name: 'February', value: 'February' },
    { name: 'March', value: 'March' },
    { name: 'April', value: 'April' },
    { name: 'May', value: 'May' },
    { name: 'June', value: 'June' },
    { name: 'July', value: 'July' },
    { name: 'August', value: 'August' },
    { name: 'September', value: 'September' },
    { name: 'October', value: 'October' },
    { name: 'November', value: 'November' },
    { name: 'December', value: 'December' },
  ]
  const storeValues = useAppSelector(
    (state) => state?.applicationStore?.EducationAndEmployement?.ForeignWork,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const educationId = useAppSelector(
    (state) =>
      state?.applicationStore?.ApplicationId?.EducationAndEmployementId,
  )
  const [formValues, setFormValues] = useState<ForeignWorkType>({
    educationId,
    haveWorkedOutSideUSA: undefined,
    isSalaryWageOrCurrentYearSelfEmployement: undefined,
    totalEarnedAmountCurrentYear: '',
    earningShowInSocialSecutiryStatementCurrentYear: undefined,
    usaTaxesTakenOutCurrentYear: undefined,
    usaTaxesNotTakenOutCurrentYearMonths: [''],
    isSalaryWageOrLastYearSelfEmployement: undefined,
    totalEarnedAmountLastYear: '',
    earningShowInSocialSecutiryStatementLastYear: undefined,
    usaTaxesTakenOutPastYear: undefined,
    usaTaxesNotTakenOutPastYearMonths: [''],
    eligibleForBenefitsUnderForeignSocialSecuritySystem: undefined,
  })
  const [errors, setErrors] = useState<Record<string, string>>({})

  useEffect(() => {
    if (
      formValues.eligibleForBenefitsUnderForeignSocialSecuritySystem !==
      undefined
    ) {
      dispatch(
        setDisqualification({
          eligibleForBenefitsUnderForeignSocialSecuritySystem:
            formValues.eligibleForBenefitsUnderForeignSocialSecuritySystem,
        }),
      )
      dispatch(
        setIsDisqualified(
          formValues?.eligibleForBenefitsUnderForeignSocialSecuritySystem,
        ),
      )
    }
  }, [formValues.eligibleForBenefitsUnderForeignSocialSecuritySystem])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])
  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({ ...storeValues, educationId })
    setErrors({})
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            haveWorkedOutSideUSA:
              formValues?.haveWorkedOutSideUSA === undefined
                ? 'Boolean'
                : formValues?.haveWorkedOutSideUSA,
            isSalaryWageOrCurrentYearSelfEmployement:
              formValues?.isSalaryWageOrCurrentYearSelfEmployement === undefined
                ? 'Boolean'
                : formValues?.isSalaryWageOrCurrentYearSelfEmployement,
            earningShowInSocialSecutiryStatementCurrentYear:
              formValues?.earningShowInSocialSecutiryStatementCurrentYear ===
              undefined
                ? 'Boolean'
                : formValues?.earningShowInSocialSecutiryStatementCurrentYear,
            usaTaxesTakenOutCurrentYear:
              formValues?.usaTaxesTakenOutCurrentYear === undefined
                ? 'Boolean'
                : formValues?.usaTaxesTakenOutCurrentYear,
            isSalaryWageOrLastYearSelfEmployement:
              formValues?.isSalaryWageOrLastYearSelfEmployement === undefined
                ? 'Boolean'
                : formValues?.isSalaryWageOrLastYearSelfEmployement,
            earningShowInSocialSecutiryStatementLastYear:
              formValues?.earningShowInSocialSecutiryStatementLastYear ===
              undefined
                ? 'Boolean'
                : formValues?.earningShowInSocialSecutiryStatementLastYear,
            usaTaxesTakenOutPastYear:
              formValues?.usaTaxesTakenOutPastYear === undefined
                ? 'Boolean'
                : formValues?.usaTaxesTakenOutPastYear,
            eligibleForBenefitsUnderForeignSocialSecuritySystem:
              formValues?.eligibleForBenefitsUnderForeignSocialSecuritySystem ===
              undefined
                ? 'Boolean'
                : formValues?.eligibleForBenefitsUnderForeignSocialSecuritySystem,
          },
        }),
      )
  }, [isBotOpen])
  // useEffect(() => {
  //   setErrors({ ...errors, state: '', country: '' })
  // }, [formValues.state, formValues.country])

  const handleChange = (data: {
    name: string
    value?: string | boolean | number
  }) => {
    setFormValues({ ...formValues, [data.name]: data.value })
    setErrors({ ...errors, [data.name]: '' })
  }

  const handleSelectChange = (
    name: string[],
    label:
      | 'usaTaxesNotTakenOutCurrentYearMonths'
      | 'usaTaxesNotTakenOutPastYearMonths',
  ) => {
    setFormValues((prevValues: ForeignWorkType) => {
      return {
        ...prevValues,
        [`${label}`]: [...name],
      }
    })
    setErrors({ ...errors, [label]: '' })
  }

  const validation = () => {
    let isValid = true
    const newErrors: Record<string, string> = {}

    if (formValues.haveWorkedOutSideUSA === undefined) {
      newErrors.haveWorkedOutSideUSA = 'Please select an option'
      isValid = false
    }
    if (formValues.haveWorkedOutSideUSA) {
      if (formValues.isSalaryWageOrCurrentYearSelfEmployement === undefined) {
        newErrors.isSalaryWageOrCurrentYearSelfEmployement =
          'Please select an option'
        isValid = false
      }
      if (formValues.isSalaryWageOrCurrentYearSelfEmployement) {
        if (!formValues.totalEarnedAmountCurrentYear?.trim()) {
          newErrors.totalEarnedAmountCurrentYear = 'Please enter amount'
          isValid = false
        }
        if (
          formValues.earningShowInSocialSecutiryStatementCurrentYear ===
          undefined
        ) {
          newErrors.earningShowInSocialSecutiryStatementCurrentYear =
            'Please select an option'
          isValid = false
        }
        if (
          formValues.earningShowInSocialSecutiryStatementCurrentYear === 'Yes'
        ) {
          if (formValues.usaTaxesTakenOutCurrentYear === undefined) {
            newErrors.usaTaxesTakenOutCurrentYear = 'Please select an option'
            isValid = false
          }
          if (formValues.usaTaxesTakenOutCurrentYear === false) {
            if (!formValues.usaTaxesNotTakenOutCurrentYearMonths?.[0]?.length) {
              newErrors.usaTaxesNotTakenOutCurrentYearMonths =
                'Please select an option'
              isValid = false
            }
          }
        }
      }
      if (formValues.isSalaryWageOrLastYearSelfEmployement === undefined) {
        newErrors.isSalaryWageOrLastYearSelfEmployement =
          'Please select an option'
        isValid = false
      }
      if (formValues.isSalaryWageOrLastYearSelfEmployement) {
        if (!formValues.totalEarnedAmountLastYear?.trim()) {
          newErrors.totalEarnedAmountLastYear = 'Please enter amount'
          isValid = false
        }
        if (
          formValues.earningShowInSocialSecutiryStatementLastYear === undefined
        ) {
          newErrors.earningShowInSocialSecutiryStatementLastYear =
            'Please select an option'
          isValid = false
        }
        if (formValues.earningShowInSocialSecutiryStatementLastYear === 'Yes') {
          if (formValues.usaTaxesTakenOutPastYear === undefined) {
            newErrors.usaTaxesTakenOutPastYear = 'Please select an option'
            isValid = false
          }
          if (formValues.usaTaxesTakenOutPastYear === false) {
            if (!formValues.usaTaxesNotTakenOutPastYearMonths?.[0]?.length) {
              newErrors.usaTaxesNotTakenOutPastYearMonths =
                'Please select an option'
              isValid = false
            }
          }
        }
      }
    }
    if (
      formValues.eligibleForBenefitsUnderForeignSocialSecuritySystem ===
      undefined
    ) {
      newErrors.eligibleForBenefitsUnderForeignSocialSecuritySystem =
        'Please select an option'
      isValid = false
    }
    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (validation()) {
      const payload: ForeignWorkType = {}
      payload.educationId = formValues.educationId

      payload.haveWorkedOutSideUSA = formValues.haveWorkedOutSideUSA
      if (formValues.haveWorkedOutSideUSA) {
        payload.isSalaryWageOrCurrentYearSelfEmployement =
          formValues.isSalaryWageOrCurrentYearSelfEmployement
        if (formValues.isSalaryWageOrCurrentYearSelfEmployement) {
          payload.totalEarnedAmountCurrentYear =
            formValues.totalEarnedAmountCurrentYear
          payload.earningShowInSocialSecutiryStatementCurrentYear =
            formValues.earningShowInSocialSecutiryStatementCurrentYear
          if (
            formValues.earningShowInSocialSecutiryStatementCurrentYear === 'Yes'
          ) {
            payload.usaTaxesTakenOutCurrentYear =
              formValues.usaTaxesTakenOutCurrentYear
            if (formValues.usaTaxesTakenOutCurrentYear === false) {
              payload.usaTaxesNotTakenOutCurrentYearMonths =
                formValues.usaTaxesNotTakenOutCurrentYearMonths
            }
          }
        }
        payload.isSalaryWageOrLastYearSelfEmployement =
          formValues.isSalaryWageOrLastYearSelfEmployement
        if (formValues.isSalaryWageOrLastYearSelfEmployement) {
          payload.totalEarnedAmountLastYear =
            formValues.totalEarnedAmountLastYear
          payload.earningShowInSocialSecutiryStatementLastYear =
            formValues.earningShowInSocialSecutiryStatementLastYear
          if (
            formValues.earningShowInSocialSecutiryStatementLastYear === 'Yes'
          ) {
            payload.usaTaxesTakenOutPastYear =
              formValues.usaTaxesTakenOutPastYear
            if (formValues.usaTaxesTakenOutPastYear === false) {
              payload.usaTaxesNotTakenOutPastYearMonths =
                formValues.usaTaxesNotTakenOutPastYearMonths
            }
          }
        }
      }
      payload.eligibleForBenefitsUnderForeignSocialSecuritySystem =
        formValues.eligibleForBenefitsUnderForeignSocialSecuritySystem
      dispatch(setForeignWork(formValues))
      dispatch(EditForeignWork(payload)).then((res) => {
        if (res?.payload?.success) {
          handleNext()
        }
      })
    }
  }

  return (
    <>
      <Box className={Style.DialogContentBox}>
        <Box className={Style.GridBox}>
          <form className={Style.Form} onSubmit={handleSubmit}>
            <ApplicationIntroBox heading="Nice! Now, we're looking to understand international employment." />
            <CommonRadioBox
              title={
                HasAssistant()
                  ? 'Has applicant ever worked outside of the U.S.?'
                  : 'Have you ever worked outside of the U.S.?'
              }
              YesTitle='I have worked outside of the U.S.'
              NoTitle='I have never worked outside of the U.S.'
              commonState={formValues.haveWorkedOutSideUSA}
              setcommonState={(value) => {
                handleChange({
                  name: 'haveWorkedOutSideUSA',
                  value,
                })
              }}
              error={errors.haveWorkedOutSideUSA}
            />
            {formValues.haveWorkedOutSideUSA ? (
              <>
                {/* from here */}
                <CommonRadioBox
                  title={
                    HasAssistant()
                      ? `Did applicant work outside of the U.S. for salary, wages, or self-employment in ${currentYear}?`
                      : `Did you work outside of the U.S. for salary, wages, or self-employment in ${currentYear}?`
                  }
                  YesTitle={`I worked outside of the U.S. for salary, wages, or self-employment in ${currentYear}`}
                  NoTitle={`I did not work outside of the U.S. for salary, wages, or self-employment in ${currentYear}`}
                  commonState={
                    formValues.isSalaryWageOrCurrentYearSelfEmployement
                  }
                  setcommonState={(value) => {
                    handleChange({
                      name: 'isSalaryWageOrCurrentYearSelfEmployement',
                      value,
                    })
                  }}
                  error={errors.isSalaryWageOrCurrentYearSelfEmployement}
                />
                {formValues.isSalaryWageOrCurrentYearSelfEmployement ? (
                  <>
                    <Name
                      handleChange={handleChange}
                      name='totalEarnedAmountCurrentYear'
                      value={formValues?.totalEarnedAmountCurrentYear}
                      title={`What was the total of all wages and tips earned in ${currentYear} outside of the U.S. (in US dollars)?`}
                      error={errors?.totalEarnedAmountCurrentYear}
                      preInput='$'
                      number
                    />
                    <CommonDropdown
                      handleChange={handleChange}
                      label={`Are the earnings shown on ${HasAssistant() ? `applicant's` : `your`} Social Security statement accurate in ${currentYear}?`}
                      menulist={[
                        {
                          name: 'Yes',
                          value: 'Yes',
                        },
                        {
                          name: 'No',
                          value: 'No',
                        },
                        {
                          name: "I don't know",
                          value: "I don't know",
                        },
                      ]}
                      name='earningShowInSocialSecutiryStatementCurrentYear'
                      value={
                        formValues.earningShowInSocialSecutiryStatementCurrentYear ??
                        ''
                      }
                      error={
                        errors?.earningShowInSocialSecutiryStatementCurrentYear
                      }
                    />
                    {formValues.earningShowInSocialSecutiryStatementCurrentYear ===
                    'Yes' ? (
                      <>
                        <CommonRadioBox
                          title={
                            HasAssistant()
                              ? `Were all of applicant's earnings subject to U.S. Social Security tax in ${currentYear}?`
                              : `Were all of your earnings subject to U.S. Social Security tax in ${currentYear}?`
                          }
                          YesTitle={`All of my earnings subject to U.S. Social Security tax in ${currentYear}`}
                          NoTitle={`Not all of my earnings subject to U.S. Social Security tax in ${currentYear}`}
                          commonState={formValues.usaTaxesTakenOutCurrentYear}
                          setcommonState={(value) => {
                            handleChange({
                              name: 'usaTaxesTakenOutCurrentYear',
                              value,
                            })
                          }}
                          error={errors.usaTaxesTakenOutCurrentYear}
                        />
                        {formValues.usaTaxesTakenOutCurrentYear === false ? (
                          <>
                            <MultiSelectDropDown
                              name={'usaTaxesNotTakenOutCurrentYearMonths'}
                              value={
                                formValues.usaTaxesNotTakenOutCurrentYearMonths ??
                                []
                              }
                              error={
                                errors.usaTaxesNotTakenOutCurrentYearMonths
                              }
                              label={
                                'Please select all of the months in which 45 hours or more were worked.'
                              }
                              menulist={monthList}
                              handleSelectChange={(name) => {
                                handleSelectChange(
                                  name,
                                  'usaTaxesNotTakenOutCurrentYearMonths',
                                )
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                <CommonRadioBox
                  title={
                    HasAssistant()
                      ? `Did applicant work outside of the U.S. for salary, wages, or self-employment in ${previousYear}?`
                      : `Did you work outside of the U.S. for salary, wages, or self-employment in ${previousYear}?`
                  }
                  YesTitle={`I worked outside of the U.S. for salary, wages, or self-employment in ${previousYear}`}
                  NoTitle={`I did not work outside of the U.S. for salary, wages, or self-employment in ${previousYear}`}
                  commonState={formValues.isSalaryWageOrLastYearSelfEmployement}
                  setcommonState={(value) => {
                    handleChange({
                      name: 'isSalaryWageOrLastYearSelfEmployement',
                      value,
                    })
                  }}
                  error={errors.isSalaryWageOrLastYearSelfEmployement}
                />
                {formValues.isSalaryWageOrLastYearSelfEmployement ? (
                  <>
                    <Name
                      handleChange={handleChange}
                      name='totalEarnedAmountLastYear'
                      value={formValues?.totalEarnedAmountLastYear}
                      title={`What was the total of all wages and tips earned in ${previousYear} outside of the U.S. (in US dollars)?`}
                      error={errors?.totalEarnedAmountLastYear}
                      preInput='$'
                      number
                    />
                    <CommonDropdown
                      handleChange={handleChange}
                      label={`Are the earnings shown on ${HasAssistant() ? `applicant's` : `your`} Social Security statement accurate in ${previousYear}?`}
                      menulist={[
                        {
                          name: 'Yes',
                          value: 'Yes',
                        },
                        {
                          name: 'No',
                          value: 'No',
                        },
                        {
                          name: "I don't know",
                          value: "I don't know",
                        },
                      ]}
                      name='earningShowInSocialSecutiryStatementLastYear'
                      value={
                        formValues.earningShowInSocialSecutiryStatementLastYear ??
                        ''
                      }
                      error={
                        errors?.earningShowInSocialSecutiryStatementLastYear
                      }
                    />
                    {formValues.earningShowInSocialSecutiryStatementLastYear ===
                    'Yes' ? (
                      <>
                        <CommonRadioBox
                          title={
                            HasAssistant()
                              ? `Were all of applicantr earnings subject to U.S. Social Security tax in ${previousYear}?`
                              : `Were all of your earnings subject to U.S. Social Security tax in ${previousYear}?`
                          }
                          YesTitle={`All of my earnings subject to U.S. Social Security tax in ${previousYear}`}
                          NoTitle={`Not all of my earnings subject to U.S. Social Security tax in ${previousYear}`}
                          commonState={formValues.usaTaxesTakenOutPastYear}
                          setcommonState={(value) => {
                            handleChange({
                              name: 'usaTaxesTakenOutPastYear',
                              value,
                            })
                          }}
                          error={errors.usaTaxesTakenOutPastYear}
                        />
                        {formValues.usaTaxesTakenOutPastYear === false ? (
                          <>
                            <MultiSelectDropDown
                              name={'usaTaxesNotTakenOutPastYearMonths'}
                              value={
                                formValues.usaTaxesNotTakenOutPastYearMonths ??
                                []
                              }
                              error={errors.usaTaxesNotTakenOutPastYearMonths}
                              label={
                                'Please select all of the months in which 45 hours or more were worked.'
                              }
                              menulist={monthList}
                              handleSelectChange={(name) => {
                                handleSelectChange(
                                  name,
                                  'usaTaxesNotTakenOutPastYearMonths',
                                )
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            <CommonRadioBox
              title={
                HasAssistant()
                  ? "Is applicant, applicant's spouse, or prior spouse eligible for benefits under a foreign Social Security system?"
                  : 'Are you, your spouse, or prior spouse eligible for benefits under a foreign Social Security system?'
              }
              YesTitle='I am eligible for benefits under a foreign Social Security system'
              NoTitle='I am not eligible for benefits under a foreign Social Security system'
              commonState={
                formValues.eligibleForBenefitsUnderForeignSocialSecuritySystem
              }
              setcommonState={(value) => {
                handleChange({
                  name: 'eligibleForBenefitsUnderForeignSocialSecuritySystem',
                  value,
                })
              }}
              error={errors.eligibleForBenefitsUnderForeignSocialSecuritySystem}
            />
            <Box className={Style.AddButton}>
              <Button
                variant='contained'
                className={Style.ButtonOne}
                onClick={handleBack}
              >
                Previous
              </Button>
              <Button
                disabled={
                  !!formValues.eligibleForBenefitsUnderForeignSocialSecuritySystem
                }
                variant='contained'
                type='submit'
                className={Style.ButtonTwo}
              >
                Next
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default ForeignWork
