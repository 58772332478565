import React from 'react'

import Education from './Education'
import EmployerDetails from './EmployerDetails'
import ForeignWork from './ForeignWork'
import JobDetails from './JobDetails'
import ChildCare from './ChildCare'
import SelfEmployment from './SelfEmployment'
import SpecialEducation from './SpecialEducation'
import SpecializedTradeEducationTraining from './SpecializedTradeEducationTraining'
import WorkTaxDetails from './WorkTaxDetails'

const EducationDetails: React.FunctionComponent = ({
  chatForm,
}: {
  chatForm?: any
}) => {
  return (
    <>
      <Education chatForm={chatForm} />
      <SpecialEducation chatForm={chatForm} />
      <SpecializedTradeEducationTraining chatForm={chatForm} />
      <EmployerDetails chatForm={chatForm} />
      <JobDetails chatForm={chatForm} />
      <ChildCare chatForm={chatForm} />
      <SelfEmployment chatForm={chatForm} />
      <ForeignWork chatForm={chatForm} />
      <WorkTaxDetails chatForm={chatForm} />
    </>
  )
}

export default EducationDetails
