import React, { useRef } from 'react'
import '../index.scss'
import styled from 'styled-components'
import {
  TableCell,
  tableCellClasses,
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  TableContainer,
  Table,
  TableRow,
  Paper,
} from '@mui/material'
import PastSignificantForm from '../EditForms/FamilyDetails/PastSignificant'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import { convertISOToDate, hasEmpty } from 'src/Utils/Constants'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import Tooltip from '@mui/material/Tooltip'
import error from 'src/Assets/Icons/error.svg'
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: '16px',
  },
}))
const ErrorImage = styled('img')({
  marginRight: '8px', // Adjust margin as needed
  marginBottom: '-8px', // Adjust margin as needed
  paddingRight: '10px',
  paddingLeft: '10px',
  // position: 'absolute',
})
const PastSignificant: React.FC = ({ chatForm }: { chatForm?: any }) => {
  const [editMode, setEditMode] = React.useState(false)

  const flagData = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.FamilyDetails?.familyFlagData?.PassSignificant,
  )
  const pastSignificantRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    pastSignificantRef?.current?.scrollIntoView()
  }
  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.FamilyDetails?.PassSignificant,
  )
  const pastSignificantOthersSectionId = useAppSelector(
    (state) =>
      state?.steps?.FamilyApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Past Significant Other(s)',
      )?.sectionId,
  )
  return (
    <div id={pastSignificantOthersSectionId} ref={pastSignificantRef}>
      <>
        {!editMode ? (
          <>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Past Significant Other(s)
                </Typography>
              </Grid>
              <Grid lg={2} sx={{ float: 'right' }}>
                <Box
                  sx={{
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#6f737a',
                    padding: '6px 15px',
                    fontSize: '16px',
                    lineHeight: 1.5,
                  }}
                >
                  <Button
                    disabled={hasEmpty(data)}
                    className='EditBtnStyle'
                    onClick={handleEditToggle}
                  >
                    Edit
                  </Button>
                </Box>
              </Grid>
              <Divider sx={{ width: '97%' }} />
            </Grid>
            {!hasEmpty(data) ? (
              <>
                <Grid container m={2}>
                  <TableContainer>
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: 'none',
                          width: '50%',
                        },
                      }}
                    >
                      <TableRow>
                        <StyledTableCell
                          variant='head'
                          width={'50%'}
                          className='grayFonts'
                        >
                          Have any prior marriages
                        </StyledTableCell>
                        <StyledTableCell
                          className={
                            flagData?.havePriorMarriages?.length > 0
                              ? 'redFlagFonts'
                              : 'MediumBlackFonts'
                          }
                        >
                          {data?.havePriorMarriages ? 'Yes' : 'No'}
                          {flagData?.havePriorMarriages?.length > 0 ? (
                            <>
                              <Tooltip title={flagData?.havePriorMarriages}>
                                <ErrorImage src={error} alt='Error' />
                              </Tooltip>
                            </>
                          ) : null}
                        </StyledTableCell>
                      </TableRow>
                      {data?.havePriorMarriages ? (
                        <>
                          <TableRow>
                            <StyledTableCell
                              variant='head'
                              width={'50%'}
                              className='grayFonts'
                            >
                              Prior marriages last 10 years or more
                            </StyledTableCell>
                            <StyledTableCell
                              className={
                                flagData?.pastMarriage10Years?.length > 0
                                  ? 'redFlagFonts'
                                  : 'MediumBlackFonts'
                              }
                            >
                              {data?.pastMarriage10Years ? 'Yes' : 'No'}
                              {flagData?.pastMarriage10Years?.length > 0 ? (
                                <>
                                  <Tooltip
                                    title={flagData?.pastMarriage10Years}
                                  >
                                    <ErrorImage src={error} alt='Error' />
                                  </Tooltip>
                                </>
                              ) : null}
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              variant='head'
                              width={'50%'}
                              className='grayFonts'
                            >
                              Prior marriages end due to applicant‘s spouse's
                              death
                            </StyledTableCell>
                            <StyledTableCell
                              className={
                                flagData?.isPriorMarriageEndDuetoSpouseDeath
                                  ?.length > 0
                                  ? 'redFlagFonts'
                                  : 'MediumBlackFonts'
                              }
                            >
                              {data?.isPriorMarriageEndDuetoSpouseDeath
                                ? 'Yes'
                                : 'No'}
                              {flagData?.isPriorMarriageEndDuetoSpouseDeath
                                ?.length > 0 ? (
                                <>
                                  <Tooltip
                                    title={
                                      flagData?.isPriorMarriageEndDuetoSpouseDeath
                                    }
                                  >
                                    <ErrorImage src={error} alt='Error' />
                                  </Tooltip>
                                </>
                              ) : null}
                            </StyledTableCell>
                          </TableRow>
                        </>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Grid>
                {data?.havePriorMarriages &&
                (data?.pastMarriage10Years ||
                  data?.isPriorMarriageEndDuetoSpouseDeath) ? (
                  <>
                    <Grid m={2}>
                      <Grid container m={2}>
                        {data?.pastSignificantOthers?.map(
                          (
                            item: {
                              spouseFirstName: any
                              spouseLastName: any
                              spouseDateOfBirth: string | number | Date
                              spouseSSN:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<
                                    any,
                                    string | React.JSXElementConstructor<any>
                                  >
                                | Iterable<React.ReactNode>
                                | React.ReactPortal
                                | null
                                | undefined
                              typeOfMarriage:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<
                                    any,
                                    string | React.JSXElementConstructor<any>
                                  >
                                | Iterable<React.ReactNode>
                                | React.ReactPortal
                                | null
                                | undefined
                              dateOfMarriage: string | number | Date
                              placeOfMarriage: {
                                city:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined
                                state:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined
                                country:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined
                                other:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined
                              }
                              pastSODeathDate: string | number | Date
                              pastSOEndInDeath: boolean
                              marriageEndDate: string | number | Date
                              placeOfMarriageEnded: {
                                city:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined
                                state:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined
                                country:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined
                                other:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined
                              }
                              howMarriageEnded:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<
                                    any,
                                    string | React.JSXElementConstructor<any>
                                  >
                                | Iterable<React.ReactNode>
                                | React.ReactPortal
                                | null
                                | undefined
                            },
                            index: any,
                          ) => (
                            <>
                              <Grid lg={12} pr={6}>
                                <Typography
                                  variant='h3'
                                  className='FontBold'
                                  mb={2}
                                >
                                  Spouse Details
                                </Typography>
                                <Paper
                                  sx={{
                                    p: 2,
                                    bgcolor: 'transparent',
                                    border: '1px solid #E1E4EB',
                                    boxShadow: 0,
                                  }}
                                >
                                  <Grid container>
                                    <Grid py={1} container>
                                      <Grid lg={4} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Prior Spouse's Name
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ]?.spouseFirstName?.length > 0 ||
                                            flagData?.pastSignificantOthers[
                                              index
                                            ]?.spouseLastName?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {`${item?.spouseFirstName} ${item?.spouseLastName}`}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ]?.spouseFirstName?.length > 0 ||
                                          flagData?.pastSignificantOthers[index]
                                            ?.spouseLastName?.length > 0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].spouseFirstName ||
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].spouseLastName
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={4} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Prior Spouse's Date of Birth
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].spouseDateOfBirth?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {convertISOToDate(
                                            item?.spouseDateOfBirth,
                                          )}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].spouseDateOfBirth?.length > 0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].spouseDateOfBirth
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={4} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Prior Spouse's Social Security Number
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].spouseSSN?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {item?.spouseSSN}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].spouseSSN?.length > 0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].spouseSSN
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                    </Grid>

                                    <Grid py={1} container>
                                      <Grid lg={4} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Type of Marriage
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].typeOfMarriage?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {item?.typeOfMarriage}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].typeOfMarriage?.length > 0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].typeOfMarriage
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={4} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Date Marriage Began
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].dateOfMarriage?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {convertISOToDate(
                                            item?.dateOfMarriage,
                                          )}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].dateOfMarriage?.length > 0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].dateOfMarriage
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Divider className='BoxDevider' />
                                    <Grid py={1} container>
                                      <Grid lg={12} my={2}>
                                        Place of marriage
                                      </Grid>
                                      <Grid lg={4} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          City
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].placeOfMarriage?.city?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {item?.placeOfMarriage?.city}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].placeOfMarriage?.city?.length >
                                          0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].placeOfMarriage?.city
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={4} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          State/Territory
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].placeOfMarriage?.state?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {item?.placeOfMarriage?.state}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].placeOfMarriage?.state?.length >
                                          0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].placeOfMarriage?.state
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={4} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Country
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].placeOfMarriage?.country?.length >
                                            0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {item?.placeOfMarriage?.country}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].placeOfMarriage?.country?.length >
                                          0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].placeOfMarriage?.country
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={12} my={2}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Other
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].placeOfMarriage?.other?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {item?.placeOfMarriage?.other}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].placeOfMarriage?.other?.length >
                                          0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].placeOfMarriage?.other
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={4} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Date Marriage Ended (estimate if
                                          unknown)
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].marriageEndDate?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {convertISOToDate(
                                            item?.marriageEndDate,
                                          )}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].marriageEndDate?.length > 0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData?.isLegallyMarried
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Divider className='BoxDevider' />
                                    <Grid py={1} container>
                                      <Grid lg={12} my={2}>
                                        Place of marriage Ended
                                      </Grid>
                                      <Grid lg={4} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          City
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].placeOfMarriageEnded?.city
                                              ?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {item?.placeOfMarriageEnded?.city}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].placeOfMarriageEnded?.city?.length >
                                          0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].placeOfMarriageEnded?.city
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={4} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          State/Territory
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].placeOfMarriageEnded?.state
                                              ?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {item?.placeOfMarriageEnded?.state}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].placeOfMarriageEnded?.state
                                            ?.length > 0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].placeOfMarriageEnded?.state
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={4} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Country
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].placeOfMarriageEnded?.country
                                              ?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {item?.placeOfMarriageEnded?.country}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].placeOfMarriageEnded?.country
                                            ?.length > 0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].placeOfMarriageEnded
                                                    ?.country
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={12} my={2}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Other
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].placeOfMarriageEnded?.other
                                              ?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {item?.placeOfMarriageEnded?.other}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].placeOfMarriageEnded?.other
                                            ?.length > 0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].placeOfMarriageEnded?.other
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={4} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Cause for the termination of the
                                          marriage
                                        </Typography>
                                        <Typography
                                          variant='h3'
                                          className={
                                            flagData?.pastSignificantOthers[
                                              index
                                            ].howMarriageEnded?.length > 0
                                              ? 'redFlagFonts'
                                              : 'MediumBlackFonts'
                                          }
                                        >
                                          {item?.howMarriageEnded}
                                          {flagData?.pastSignificantOthers[
                                            index
                                          ].howMarriageEnded?.length > 0 ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].howMarriageEnded
                                                }
                                              >
                                                <ErrorImage
                                                  src={error}
                                                  alt='Error'
                                                />
                                              </Tooltip>
                                            </>
                                          ) : null}
                                        </Typography>
                                      </Grid>
                                      {item?.howMarriageEnded !== 'Death' && (
                                        <>
                                          <Grid lg={4} my={1}>
                                            <Typography
                                              variant='h3'
                                              className='grayFonts'
                                            >
                                              Prior spouse deceased after the
                                              marriage ended
                                            </Typography>
                                            <Typography
                                              variant='h3'
                                              className={
                                                flagData?.pastSignificantOthers[
                                                  index
                                                ].pastSOEndInDeath?.length > 0
                                                  ? 'redFlagFonts'
                                                  : 'MediumBlackFonts'
                                              }
                                            >
                                              {item?.pastSOEndInDeath
                                                ? 'Yes'
                                                : 'No'}
                                              {flagData?.pastSignificantOthers[
                                                index
                                              ].pastSOEndInDeath?.length > 0 ? (
                                                <>
                                                  <Tooltip
                                                    title={
                                                      flagData
                                                        ?.pastSignificantOthers[
                                                        index
                                                      ].pastSOEndInDeath
                                                    }
                                                  >
                                                    <ErrorImage
                                                      src={error}
                                                      alt='Error'
                                                    />
                                                  </Tooltip>
                                                </>
                                              ) : null}
                                            </Typography>
                                          </Grid>
                                          {item?.pastSOEndInDeath && (
                                            <Grid lg={4} my={1}>
                                              <Typography
                                                variant='h3'
                                                className='grayFonts'
                                              >
                                                Date Death
                                              </Typography>
                                              <Typography
                                                variant='h3'
                                                className={
                                                  flagData
                                                    ?.pastSignificantOthers[
                                                    index
                                                  ].pastSODeathDate?.length > 0
                                                    ? 'redFlagFonts'
                                                    : 'MediumBlackFonts'
                                                }
                                              >
                                                {convertISOToDate(
                                                  item?.pastSODeathDate,
                                                )}
                                                {flagData
                                                  ?.pastSignificantOthers[index]
                                                  .pastSODeathDate?.length >
                                                0 ? (
                                                  <>
                                                    <Tooltip
                                                      title={
                                                        flagData?.isLegallyMarried
                                                      }
                                                    >
                                                      <ErrorImage
                                                        src={error}
                                                        alt='Error'
                                                      />
                                                    </Tooltip>
                                                  </>
                                                ) : null}
                                              </Typography>
                                            </Grid>
                                          )}
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </Grid>
                            </>
                          ),
                        )}
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </>
        ) : (
          <>
            <Grid m={2}>
              <Grid container m={2}>
                <Grid lg={10} className='alignItemCenter' display='flex'>
                  <Typography variant='h1' className='FontBold'>
                    Past Significant Other(s)
                  </Typography>
                </Grid>
                <Divider
                  sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
                />
                <Grid container m={2} ml={0}>
                  <>
                    <PastSignificantForm
                      chatForm={chatForm}
                      handleEditToggle={handleEditToggle}
                    />
                  </>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </div>
  )
}
export default PastSignificant
