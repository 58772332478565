import React, { useRef, useState } from 'react'
import '../index.scss'
import styled from 'styled-components'
import {
  TableCell,
  tableCellClasses,
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  TableContainer,
  Table,
  TableRow,
  Paper,
} from '@mui/material'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { hasEmpty } from 'src/Utils/Constants'
import Depedent from '../EditForms/FamilyDetails/Dependents/index'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: '16px',
  },
}))
const Dependents: React.FC = ({ chatForm }: { chatForm?: any }) => {
  const [editMode, setEditMode] = useState(false)
  const dependentsRef = useRef<HTMLDivElement>(null)
  const handleEditToggle = () => {
    setEditMode(!editMode)
    dependentsRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) => state?.applicationStore?.FamilyDetails?.Dependents,
  )
  const dependentsSectionId = useAppSelector(
    (state) =>
      state?.steps?.FamilyApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Dependents',
      )?.sectionId,
  )
  return (
    <div id={dependentsSectionId} ref={dependentsRef}>
      {!editMode ? (
        <>
          <Grid container m={2}>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Dependents
              </Typography>
            </Grid>
            <Grid lg={2} sx={{ float: 'right' }}>
              <Box
                sx={{
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#6f737a',
                  padding: '6px 15px',
                  fontSize: '16px',
                  lineHeight: 1.5,
                }}
              >
                <Button
                  className='EditBtnStyle'
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  Edit
                </Button>
              </Box>
            </Grid>
            <Divider sx={{ width: '97%' }} />
          </Grid>
          {!hasEmpty(data) ? (
            <>
              <Grid container m={2}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        width: '50%',
                      },
                    }}
                  >
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Have any children
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.haveAnyChildern ? 'Yes' : 'No'}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      {data?.haveAnyChildern ? (
                        <>
                          <StyledTableCell variant='head' className='grayFonts'>
                            Number of Children
                          </StyledTableCell>
                          <StyledTableCell className='MediumBlackFonts'>
                            {data?.numberOfChildern}
                          </StyledTableCell>
                        </>
                      ) : null}
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
              {data?.haveAnyChildern ? (
                <>
                  <Grid m={2}>
                    <Grid container m={2}>
                      {data?.dependents?.map(
                        (
                          item: {
                            firstName: any
                            lastName: any
                            isYoungerThan17: any
                            isChild1819SchoolUnmarried: any
                            isChildDisabledBeforeAge22: any
                            isChildMarried: any
                            isChildDisabled: any
                          },
                          index: React.Key | null | undefined,
                        ) => (
                          <>
                            <Grid lg={6} p={1}>
                              <Paper
                                key={index}
                                sx={{
                                  p: 2,
                                  bgcolor: 'transparent',
                                  border: '1px solid #E1E4EB',
                                  boxShadow: 0,
                                }}
                              >
                                <Grid lg={6} my={1}>
                                  <Typography
                                    variant='h3'
                                    className='grayFonts'
                                  >
                                    Child's Name
                                  </Typography>
                                  <Typography variant='h3'>{`${item?.firstName} ${item?.lastName}`}</Typography>
                                </Grid>
                                <Grid lg={6} my={1}>
                                  <Typography
                                    variant='h3'
                                    className='grayFonts'
                                  >
                                    Child younger than 17
                                  </Typography>
                                  <Typography variant='h3'>
                                    {' '}
                                    {item?.isYoungerThan17 ? 'Yes' : 'No'}
                                  </Typography>
                                </Grid>
                                <Grid pt={2} container>
                                  {item?.isYoungerThan17 ? (
                                    <>
                                      <Grid lg={6} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Child unmarried
                                        </Typography>
                                        <Typography variant='h3'>
                                          {' '}
                                          {item?.isChildMarried ? 'Yes' : 'No'}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={6} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Child disabled
                                        </Typography>
                                        <Typography variant='h3'>
                                          {' '}
                                          {item?.isChildDisabled ? 'Yes' : 'No'}
                                        </Typography>
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid lg={6} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          18 to 19 years of age, unmarried
                                        </Typography>
                                        <Typography variant='h3'>
                                          {' '}
                                          {item?.isChild1819SchoolUnmarried
                                            ? 'Yes'
                                            : 'No'}
                                        </Typography>
                                      </Grid>
                                      <Grid lg={6} my={1}>
                                        <Typography
                                          variant='h3'
                                          className='grayFonts'
                                        >
                                          Child disabled before age 22
                                        </Typography>
                                        <Typography variant='h3'>
                                          {' '}
                                          {item?.isChildDisabledBeforeAge22
                                            ? 'Yes'
                                            : 'No'}
                                        </Typography>
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              </Paper>
                            </Grid>
                          </>
                        ),
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </>
          ) : (
            <CustomAlert message='no data available.' />
          )}
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Dependents
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <Depedent
                  chatForm={chatForm}
                  handleEditToggle={handleEditToggle}
                />
                {/* <ContactInformation handleEditToggle={handleEditToggle} /> */}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}
export default Dependents
