import './index.scss'
import Contact from './Contact'
import Communication from './Communication'
import MillataryService from './MilataryService'
import PastApplication from './PastApplications'
import CitizenShip from './Citizenship'
import React from 'react'

const ApplicationContent = ({ chatForm }: { chatForm?: any }) => {
  return (
    <>
      <div>
        <Contact chatForm={chatForm} />
        <Communication chatForm={chatForm} />
        <CitizenShip chatForm={chatForm} />
        <MillataryService chatForm={chatForm} />
        <PastApplication chatForm={chatForm} />
      </div>
    </>
  )
}
export default ApplicationContent
