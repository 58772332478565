import { InputLabel, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import remove from 'src/Assets/Icons/remove.svg'
import { Calander, MultiSelectDropDown } from 'src/Components/Fields'
import { HospitalAndMedicalFacilityCareType } from 'src/Redux/Services/Types/applicationType'
import { Therapies, TreatmentOptions } from 'src/Utils/Constants'
import Address from '../AddressDetails'
import Style from './index.module.scss'

interface hospitalDetailsType {
  hospitalName: string
  address: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  }
  phoneNumber: string
  arrivedDate: string
  leftDate: string
  treatmentType: string[]
  typeOfTherapyDoctorProvided: string[]
  otherTypeOfTherapyDetails: string
  otherTreatment: string
  specialProcedureDetail: string
}

const HospitalDetailBox = ({
  formValues,
  realValue,
  setFormValues,
  changeHandler,
  handleRemove,
  index,
  errors,
  fieldName,
}: {
  formValues: hospitalDetailsType
  realValue: HospitalAndMedicalFacilityCareType
  setFormValues: React.Dispatch<
    React.SetStateAction<HospitalAndMedicalFacilityCareType>
  >
  changeHandler: (data: { name: string; value: string }) => void
  handleRemove: () => void
  index: number
  errors: Record<string, string>
  fieldName: string
}) => {
  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.label}>
            Medical Facility {index + 1}
          </Typography>
          <img
            src={remove}
            alt=''
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleRemove()
            }}
          />
        </Grid>

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Office or Clinic Name
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='hospitalName'
              name='hospitalName'
              placeholder='Contact person name'
              className={Style.AnsTxt}
              value={formValues?.hospitalName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.hospitalName}
              helperText={errors?.hospitalName}
            />
          </Grid>
        </Grid>

        <Address
          realValues={realValue}
          formValues={formValues}
          handleFieldChange={changeHandler}
          index={index}
          fieldName={fieldName}
          setFormValues={setFormValues}
          errors={errors}
        />

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Phone number<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              id='phoneNumber'
              name='phoneNumber'
              placeholder='Phone number'
              inputProps={{ maxLength: 10 }}
              className={Style.AnsTxt}
              value={formValues?.phoneNumber}
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  changeHandler({ name: e.target.name, value: e.target.value })
                }
              }}
              error={!!errors?.phoneNumber}
              helperText={errors?.phoneNumber}
            />
          </Grid>
        </Grid>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          {/* <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Estimated Date Left the Facility
            <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{
                  width: '100%',
                  fieldset: { borderColor: error ? '#d32f2f' : undefined },
                }}
                value={
                  formValues?.arrivedDate
                    ? dayjs(formValues?.arrivedDate)
                    : null
                } // Convert to Dayjs object
                onChange={(e) => {
                  changeHandler({
                    name: 'arrivedDate',
                    value: dayjs(e).format('YYYY-MM-DD'),
                  })
                }}
                maxDate={dayjs()} // Use Dayjs for maxDate as well
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ width: '100%' }}
                    id='arrivedDate'
                    name='arrivedDate'
                    placeholder='mm-DD-YYYY'
                    className={Style.AnsTxt}
                    InputProps={{
                      inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid> */}
          <Calander
            title={'Estimated Date Arrived at Facility'}
            value={formValues.arrivedDate}
            name={'arrivedDate'}
            error={errors?.arrivedDate}
            handleChange={changeHandler}
          />
          {/* <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Estimated Last Appointment Date
            <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{
                  width: '100%',
                  fieldset: { borderColor: errors ? '#d32f2f' : undefined },
                }}
                value={
                  formValues?.leftDate
                    ? dayjs(formValues?.leftDate)
                    : null
                } // Convert to Dayjs object
                onChange={(e) => {
                  changeHandler({
                    name: 'leftDate',
                    value: dayjs(e).format('YYYY-MM-DD'),
                  })
                }}
                maxDate={dayjs()} // Use Dayjs for maxDate as well
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ width: '100%' }}
                    id='leftDate'
                    name='leftDate'
                    placeholder='mm-DD-YYYY'
                    className={Style.AnsTxt}
                    InputProps={{
                      inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <FormHelperText>{errors?.leftDate}</FormHelperText>
          </Grid> */}
          <Calander
            minDate={formValues?.arrivedDate}
            title={'Estimated Date Left the Facility'}
            value={formValues.leftDate}
            name={'leftDate'}
            error={errors?.leftDate}
            handleChange={changeHandler}
          />
        </Grid>

        <Box>
          <MultiSelectDropDown
            name={'treatmentType'}
            value={formValues?.treatmentType ?? []}
            error={errors?.treatmentType}
            label={
              'Please tell us the type of treatment that applicant recieved from this medical professional'
            }
            menulist={TreatmentOptions}
            handleSelectChange={(name: string[]) => {
              // if (formValues.treatmentType?.includes(name)) {
              //   changeHandler({
              //     name: 'treatmentType',
              //     value: formValues?.treatmentType.filter((i) => i !== name),
              //   })
              // } else {
              //   changeHandler({
              //     name: 'treatmentType',
              //     value: [...formValues?.treatmentType, name],
              //   })
              // }
              changeHandler({
                name: 'treatmentType',
                value: name,
              })
            }}
          />
        </Box>

        {formValues.treatmentType?.find((i) => i === 'Therapy') ? (
          <Box>
            <MultiSelectDropDown
              name={'typeOfTherapyDoctorProvided'}
              value={formValues.typeOfTherapyDoctorProvided ?? []}
              error={errors.typeOfTherapyDoctorProvided}
              label={
                'Please tell us the type of therapy that applicant recieved from this medical professional'
              }
              menulist={Therapies}
              handleSelectChange={(name: string[]) => {
                // if (formValues.typeOfTherapyDoctorProvided?.includes(name)) {
                //   changeHandler({
                //     name: 'typeOfTherapyDoctorProvided',
                //     value: formValues.typeOfTherapyDoctorProvided.filter(
                //       (i) => i !== name,
                //     ),
                //   })
                // } else {
                //   changeHandler({
                //     name: 'typeOfTherapyDoctorProvided',
                //     value: [...formValues.typeOfTherapyDoctorProvided, name],
                //   })
                // }
                changeHandler({
                  name: 'typeOfTherapyDoctorProvided',
                  value: name,
                })
              }}
            />
          </Box>
        ) : (
          <></>
        )}

        {formValues.typeOfTherapyDoctorProvided?.find((i) => i === 'Other') ? (
          <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
            <Grid width={'100%'}>
              <InputLabel className={Style.label}>
                If applicant received any other type of therapy,please explain:
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                fullWidth
                type='text'
                id='otherTypeOfTherapyDetails'
                name='otherTypeOfTherapyDetails'
                multiline
                rows={4}
                className={Style.AnsTxt}
                placeholder='Other'
                value={formValues?.otherTypeOfTherapyDetails}
                onChange={(e) => {
                  changeHandler({ name: e.target.name, value: e.target.value })
                }}
                error={!!errors?.otherTypeOfTherapyDetails}
                helperText={errors?.otherTypeOfTherapyDetails}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        {formValues.treatmentType?.find((i) => i === 'Other') ? (
          <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
            <Grid width={'100%'}>
              <InputLabel className={Style.label}>
                If applicant received any other type of treatment,please
                explain:
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                fullWidth
                type='text'
                id='otherTreatment'
                name='otherTreatment'
                multiline
                rows={4}
                className={Style.AnsTxt}
                placeholder='Other'
                value={formValues?.otherTreatment}
                onChange={(e) => {
                  changeHandler({ name: e.target.name, value: e.target.value })
                }}
                error={!!errors?.otherTreatment}
                helperText={errors?.otherTreatment}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        {formValues.treatmentType?.find(
          (i) => i === 'Special procedures (e.g., steroid injections)',
        ) ? (
          <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
            <Grid width={'100%'}>
              <InputLabel className={Style.label}>
                If applicant received any other type of Special procedures
                (e.g., steroid injections),please explain:
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                fullWidth
                type='text'
                id='specialProcedureDetail'
                name='specialProcedureDetail'
                multiline
                rows={4}
                className={Style.AnsTxt}
                placeholder='Other'
                value={formValues?.specialProcedureDetail}
                onChange={(e) => {
                  changeHandler({ name: e.target.name, value: e.target.value })
                }}
                error={!!errors?.specialProcedureDetail}
                helperText={errors?.specialProcedureDetail}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    </>
  )
}
export default HospitalDetailBox
