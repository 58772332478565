import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider } from '@mui/material'
import Styles from '../index.module.scss'
import PayoutsSettlements from '../EditForms/PayoutsSettlements'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { hasEmpty } from 'src/Utils/Constants'
const DisabilityDetails: React.FunctionComponent = ({
  chatForm,
}: {
  chatForm?: any
}) => {
  const [editMode, setEditMode] = useState(false)
  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.DisabilityDetails?.PayoutsSettlements,
  )
  const payoutSettlementRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    payoutSettlementRef?.current?.scrollIntoView()
  }
  const payoutsSettlementsSectionId = useAppSelector(
    (state) =>
      state?.steps?.DisablilityApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Payouts & Settlements',
      )?.sectionId,
  )

  return (
    <div id={payoutsSettlementsSectionId} ref={payoutSettlementRef}>
      <>
        {!editMode ? (
          <>
            <Box className={Styles.MainBox}>
              <Box className={Styles.title}>
                <Box className={Styles.main}>
                  <Typography variant='h6' className={Styles.textWrapper}>
                    Payouts & Settlements
                  </Typography>
                  <Button
                    className={Styles.button}
                    disabled={hasEmpty(data)}
                    onClick={handleEditToggle}
                  >
                    <Typography className={Styles.div}>Edit</Typography>
                  </Button>
                </Box>
                <div className={Styles.dottedLine} />
              </Box>
              {!hasEmpty(data) ? (
                <>
                  {' '}
                  <Box className={Styles.div2}>
                    <Box className={Styles.element}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.p}>
                          Received payout or settlement on or after unable to
                          work?
                        </Typography>
                      </Box>
                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {data?.haveReceiveMoneyAfterDisability ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Box>
                    {data?.haveReceiveMoneyAfterDisability && (
                      <>
                        <Box className={Styles.element2}>
                          <Box className={Styles.question}>
                            <Typography className={Styles.textWrapper3}>
                              Types of Pay Expected
                            </Typography>
                          </Box>
                          <Box className={Styles.ans}>
                            {data?.typeOfPays.map((item: string) => (
                              <>
                                <Typography className={Styles.textWrapper4}>
                                  {item}
                                </Typography>
                              </>
                            ))}
                          </Box>
                        </Box>

                        {data?.typeOfPays.includes('Other') && (
                          <Box className={Styles.element2}>
                            <Box className={Styles.question}>
                              <Typography className={Styles.textWrapper3}>
                                Other types of pay expected
                              </Typography>
                            </Box>
                            <Box className={Styles.ans}>
                              <Typography className={Styles.textWrapper2}>
                                {data?.otherPayExplain}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        <Box className={Styles.element}>
                          <Box className={Styles.question}>
                            <Typography className={Styles.textWrapper3}>
                              Amount Expected From Applicant‘s Employer
                            </Typography>
                          </Box>
                          <Box className={Styles.ans}>
                            <Typography className={Styles.textWrapper2}>
                              {`$${data?.approxAmountExpected}`}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}

                    <Box className={Styles.element2}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.textWrapper3}>
                          Expect to receive any money from applicant‘s employer
                          in the future
                        </Typography>
                      </Box>
                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {data?.isExpectMoneyInFuture ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Box>
                    {data?.isExpectMoneyInFuture && (
                      <>
                        <Box className={Styles.element2}>
                          <Box className={Styles.question}>
                            <Typography className={Styles.textWrapper3}>
                              Types of Pay Expected
                            </Typography>
                          </Box>
                          <Box className={Styles.ans}>
                            {data?.typeOfFuturePay.map((item: string) => (
                              <>
                                <Typography className={Styles.textWrapper4}>
                                  {item}
                                </Typography>
                              </>
                            ))}
                          </Box>
                        </Box>
                        {data?.typeOfFuturePay.includes('Other') && (
                          <Box className={Styles.element}>
                            <Box className={Styles.question}>
                              <Typography className={Styles.textWrapper3}>
                                Other Types of Future Pay Expected
                              </Typography>
                            </Box>
                            <Box className={Styles.ans}>
                              <Typography className={Styles.textWrapper2}>
                                {data?.futureOtherPayExplain}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        <Box className={Styles.element}>
                          <Box className={Styles.question}>
                            <Typography className={Styles.textWrapper3}>
                              Amount Expected From Applicant‘s Employee
                            </Typography>
                          </Box>
                          <Box className={Styles.ans}>
                            <Typography className={Styles.textWrapper2}>
                              {`$${data?.approxAmountFuturePayout}`}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className={Styles.element2}>
                          <Box className={Styles.question}>
                            <Typography className={Styles.textWrapper3}>
                              Types of Future Pay Expected
                            </Typography>
                          </Box>
                          <Box className={Styles.ans}>
                            {data?.typeOfFuturePay.map(
                              (
                                item:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined,
                              ) => (
                                <>
                                  <Typography className={Styles.textWrapper4}>
                                    {item}
                                  </Typography>
                                </>
                              ),
                            )}
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              ) : (
                <CustomAlert message='no data available.' />
              )}
            </Box>
          </>
        ) : (
          <>
            <Grid m={2}>
              <Grid container m={2}>
                <Grid lg={10} className='alignItemCenter' display='flex'>
                  <Typography variant='h1' className='FontBold'>
                    Payouts & Settlements
                  </Typography>
                </Grid>
                <Divider
                  sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
                />
                <Grid container m={2} ml={0}>
                  <>
                    <PayoutsSettlements
                      chatForm={chatForm}
                      handleEditToggle={handleEditToggle}
                    />
                  </>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>

      {/* Payouts & Settlements */}
    </div>
  )
}

export default DisabilityDetails
