import React, { useRef, useState } from 'react'
import '../index.scss'
import styled from 'styled-components'
import {
  TableCell,
  tableCellClasses,
  Grid,
  TableContainer,
  Table,
  Box,
  Button,
  TableRow,
  Paper,
  Typography,
  Divider,
} from '@mui/material'
import ParentsForm from '../EditForms/FamilyDetails/Parents'
import { DynamicObj } from 'src/Pages/SignIn'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { hasEmpty } from 'src/Utils/Constants'

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: '16px',
  },
}))

const Parents: React.FC = ({ chatForm }: { chatForm?: any }) => {
  const [editMode, setEditMode] = useState(false)
  const data = useAppSelector(
    (state: RootState) => state?.applicationStore?.FamilyDetails?.Parent,
  )

  const parentRef = useRef<HTMLDivElement>(null)
  const handleEditToggle = () => {
    setEditMode(!editMode)
    parentRef?.current?.scrollIntoView()
  }

  function renderContactInfoPart(
    part: string | null | undefined,
    separator: string = ' ',
  ): string | null {
    return part ? `${part}${separator}` : ' ' // Concatenate the part with the separator
  }

  function renderContactInfo(data: DynamicObj) {
    const { firstName, middleName, lastName } = data

    const fullName = `${renderContactInfoPart(firstName)}${renderContactInfoPart(middleName)}${renderContactInfoPart(lastName)}`

    return fullName
  }

  const parentsSectionId = useAppSelector(
    (state) =>
      state?.steps?.FamilyApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Parents',
      )?.sectionId,
  )
  return (
    <div id={parentsSectionId} ref={parentRef}>
      {!editMode ? (
        <>
          <Grid container m={2}>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Parents
              </Typography>
            </Grid>
            <Grid lg={2} sx={{ float: 'right' }}>
              <Box
                sx={{
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#6f737a',
                  padding: '6px 15px',
                  fontSize: '16px',
                  lineHeight: 1.5,
                }}
              >
                <Button
                  className='EditBtnStyle'
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  Edit
                </Button>
              </Box>
            </Grid>
            <Divider sx={{ width: '97%' }} />
          </Grid>
          {!hasEmpty(data) ? (
            <>
              <Grid container m={2}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        width: '50%',
                      },
                    }}
                  >
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Has a parent who receives one half support from
                        applicant
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.isParentReceiveOneHalfSupport ? 'Yes' : 'No'}
                      </StyledTableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
              {data?.isParentReceiveOneHalfSupport ? (
                <>
                  <Grid m={2}>
                    <Grid container m={2}>
                      <Grid lg={12} pr={2}>
                        <Paper
                          sx={{
                            p: 2,
                            bgcolor: 'transparent',
                            border: '1px solid #E1E4EB',
                            boxShadow: 0,
                          }}
                        >
                          <Grid container>
                            <Grid lg={4} my={1}>
                              <Typography variant='h3' className='grayFonts'>
                                Name
                              </Typography>
                              <Typography variant='h3'>
                                {renderContactInfo(data?.firstParent)}
                              </Typography>
                            </Grid>
                            <Grid lg={4} my={1}>
                              <Typography variant='h3' className='grayFonts'>
                                Street Address
                              </Typography>
                              <Typography variant='h3'>
                                {' '}
                                {data?.firstParent?.address?.streetAddress}
                              </Typography>
                            </Grid>
                            <Grid lg={4} my={1}>
                              <Typography variant='h3' className='grayFonts'>
                                City/Town
                              </Typography>
                              <Typography variant='h3'>
                                {' '}
                                {data?.firstParent?.address?.city}
                              </Typography>
                            </Grid>
                            <Grid pt={2} container>
                              <Grid lg={4} my={1}>
                                <Typography variant='h3' className='grayFonts'>
                                  State
                                </Typography>
                                <Typography variant='h3'>
                                  {' '}
                                  {data?.firstParent?.address?.state}
                                </Typography>
                              </Grid>
                              <Grid lg={4} my={1}>
                                <Typography variant='h3' className='grayFonts'>
                                  Zip Code
                                </Typography>
                                <Typography variant='h3'>
                                  {' '}
                                  {data?.firstParent?.address?.postalCode}
                                </Typography>
                              </Grid>
                              <Grid lg={4} my={1}>
                                <Typography variant='h3' className='grayFonts'>
                                  Country
                                </Typography>
                                <Typography variant='h3'>
                                  {' '}
                                  {data?.firstParent?.address?.country}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : null}

              <Grid container m={2}>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        width: '50%',
                      },
                    }}
                  >
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Have a second parent who receives one half support from
                        you
                      </StyledTableCell>
                      <StyledTableCell className='MediumBlackFonts'>
                        {data?.isSecondParentReceiveOneHalfSupport
                          ? 'Yes'
                          : 'No'}
                      </StyledTableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
              {data?.isSecondParentReceiveOneHalfSupport ? (
                <>
                  <Grid m={2}>
                    <Grid container m={2}>
                      <Grid lg={12} pr={2}>
                        <Paper
                          sx={{
                            p: 2,
                            bgcolor: 'transparent',
                            border: '1px solid #E1E4EB',
                            boxShadow: 0,
                          }}
                        >
                          <Grid container>
                            <Grid lg={4} my={1}>
                              <Typography variant='h3' className='grayFonts'>
                                Name
                              </Typography>
                              <Typography variant='h3'>
                                {renderContactInfo(data?.secondParent)}
                              </Typography>
                            </Grid>
                            <Grid lg={4} my={1}>
                              <Typography variant='h3' className='grayFonts'>
                                Street Address
                              </Typography>
                              <Typography variant='h3'>
                                {' '}
                                {data?.secondParent?.address?.streetAddress}
                              </Typography>
                            </Grid>
                            <Grid lg={4} my={1}>
                              <Typography variant='h3' className='grayFonts'>
                                City/Town
                              </Typography>
                              <Typography variant='h3'>
                                {' '}
                                {data?.secondParent?.address?.city}
                              </Typography>
                            </Grid>
                            <Grid pt={2} container>
                              <Grid lg={4} my={1}>
                                <Typography variant='h3' className='grayFonts'>
                                  State
                                </Typography>
                                <Typography variant='h3'>
                                  {' '}
                                  {data?.secondParent?.address?.state}
                                </Typography>
                              </Grid>
                              <Grid lg={4} my={1}>
                                <Typography variant='h3' className='grayFonts'>
                                  Zip Code
                                </Typography>
                                <Typography variant='h3'>
                                  {' '}
                                  {data?.secondParent?.address?.postalCode}
                                </Typography>
                              </Grid>
                              <Grid lg={4} my={1}>
                                <Typography variant='h3' className='grayFonts'>
                                  Country
                                </Typography>
                                <Typography variant='h3'>
                                  {' '}
                                  {data?.secondParent?.address?.country}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </>
          ) : (
            <CustomAlert message='no data available.' />
          )}
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Parents
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <ParentsForm
                  chatForm={chatForm}
                  handleEditToggle={handleEditToggle}
                />
                {/* <ContactInformation handleEditToggle={handleEditToggle} /> */}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}
export default Parents
