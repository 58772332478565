import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Button, FormHelperText, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useEffect, useState } from 'react'
import quote from 'src/Assets/Icons/quots.svg'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { AddIdentificationNumbers } from 'src/Redux/Slices/ApplicationSlices/LegalDetailsSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import ProfileBox from './ProfileBox'
import Style from './index.module.scss'
import { setIdentificationNumbers } from 'src/Redux/Slices/ApplicationStore/LegalDetailsStore'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'

interface IdentificationNumbersType {
  legalId: string | undefined
  otherSocialSecurityNumbers: string[]
  socialSecurityNumber: string
  isUsingOtherSSN: boolean | undefined
}

const IdentificationNumbers = ({
  // legalId,
  handleEditToggle,
  chatForm,
}: {
  chatForm: any
  // legalId?: string
  setLegalId?: (value: string) => void
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  const IdentificationNumberState = useAppSelector(
    (state) => state?.applicationStore?.LegalDetails?.IdentificationNumbers,
  )
  const legalId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.legalId,
  )

  const [SSN, setSSN] = useState<string[]>(['', '', ''])
  const [otherSSN, setOtherSSN] = useState([''])

  const handleSSNChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.match(/^\d*$/)) {
        const newSSN = [...SSN]
        newSSN[index] = event.target.value
        setSSN(newSSN)
      }
    }

  const [formValues, setFormValues] = useState<IdentificationNumbersType>({
    legalId,
    socialSecurityNumber: SSN.join(''),
    otherSocialSecurityNumbers: [],
    isUsingOtherSSN: undefined,
  })

  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )

  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            isUsingOtherSSN: formValues.isUsingOtherSSN
              ? formValues.isUsingOtherSSN
              : 'Boolean',
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  useEffect(() => {
    setSSN([
      IdentificationNumberState.socialSecurityNumber.slice(0, 3),
      IdentificationNumberState.socialSecurityNumber.slice(3, 5),
      IdentificationNumberState.socialSecurityNumber.slice(5),
    ])
  }, [])
  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      legalId,
      otherSocialSecurityNumbers: otherSSN,
      socialSecurityNumber: SSN.join(''),
    }))
  }, [legalId, otherSSN, SSN])

  useEffect(() => {
    setFormValues({
      ...formValues,
      ...IdentificationNumberState,
    })
    setOtherSSN(IdentificationNumberState?.otherSocialSecurityNumbers)
  }, [])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const validateForm = () => {
    // eslint-disable-next-line no-debugger
    // debugger
    let valid = true
    const newErrors: Record<string, string> = {}

    if (formValues?.isUsingOtherSSN === undefined) {
      newErrors.isUsingOtherSSN = 'Please select an option'
      valid = false
    }
    // Validation logic for Full Name

    if (!formValues.socialSecurityNumber?.trim()) {
      newErrors.socialSecurityNumber = 'Social Security Number is required'
      valid = false
    }

    // Validation logic for Other Social Security Numbers
    if (formValues?.isUsingOtherSSN === true) {
      if (formValues.otherSocialSecurityNumbers.length > 0) {
        formValues.otherSocialSecurityNumbers.forEach((ssn, index) => {
          if (!ssn.trim()) {
            newErrors[`otherSocialSecurityNumbers.${index}`] =
              'Other Social Security Number is required'
            valid = false
          }
        })
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Conditionally construct the object to be passed based on haveUsedOtherName value
    const formValuesToSubmit =
      formValues?.isUsingOtherSSN === true
        ? formValues
        : {
            legalId,
            isUsingOtherSSN: formValues?.isUsingOtherSSN,
            socialSecurityNumber: formValues?.socialSecurityNumber,
          }

    // Handle form submission here, you can access formValues to submit data'
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(AddIdentificationNumbers(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setIdentificationNumbers(formValues))
            handleEditToggle()
          }
        },
      )
    }
  }

  const handleValuesChange = (index: number, data: string) => {
    const newProfileForms: string[] = [...formValues.otherSocialSecurityNumbers]
    newProfileForms[index] = data

    setFormValues({
      ...formValues,
      otherSocialSecurityNumbers: newProfileForms,
    })
  }

  const handleRemove = (index: number) => {
    const newProfileForms = [...formValues?.otherSocialSecurityNumbers]
    newProfileForms.splice(index, 1)
    setFormValues({
      ...formValues,
      otherSocialSecurityNumbers: newProfileForms,
    })
  }

  const addProfileBox = () => {
    setFormValues({
      ...formValues,
      otherSocialSecurityNumbers: [
        ...formValues?.otherSocialSecurityNumbers,
        '',
      ],
    })
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              py: 2,
              my: 3,
              borderRadius: '10px',
            }}
          >
            <img src={quote} className={Style.star} alt='' />
            <Typography className={Style.Heading}>
              Now, we'll be collecting identification documentation information
              that's necessary for applicant‘s case reviewer to properly
              evaluate applicant‘s disability benefits application. Please know
              that this information will not be shared and will be safeguarded
              24/7.
            </Typography>
          </Box>

          <Box my={'10px'}>
            <Typography className={Style.label}>
              Please enter applicant‘s social security numbers (SSN)
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
              <Grid width={'100%'}>
                <TextField
                  fullWidth
                  className={Style.AnsTxt}
                  value={SSN[0]}
                  onChange={handleSSNChange(0)}
                  placeholder='###'
                  inputProps={{ maxLength: 3 }}
                  error={!!errors.socialSecurityNumber}
                />
              </Grid>
              <Grid width={'100%'}>
                <TextField
                  fullWidth
                  className={Style.AnsTxt}
                  value={SSN[1]}
                  onChange={handleSSNChange(1)}
                  placeholder='##'
                  inputProps={{ maxLength: 2 }}
                  error={!!errors.socialSecurityNumber}
                />
              </Grid>
              <Grid width={'100%'}>
                <TextField
                  fullWidth
                  className={Style.AnsTxt}
                  value={SSN[2]}
                  onChange={handleSSNChange(2)}
                  placeholder='####'
                  inputProps={{ maxLength: 4 }}
                  error={!!errors.socialSecurityNumber}
                />
              </Grid>
            </Grid>
            <FormHelperText sx={{ mx: 1.5 }}>
              {errors.socialSecurityNumber}
            </FormHelperText>
          </Box>

          {/* Common radio */}
          <CommonRadioBox
            error={errors?.isUsingOtherSSN}
            title='Has applicant used any other social security number (SSN)?'
            commonState={formValues?.isUsingOtherSSN}
            setcommonState={(value: boolean) => {
              setFormValues({ ...formValues, isUsingOtherSSN: value })
            }}
            YesTitle='I have used another social security number (SSN)'
            NoTitle='I have not used any other social security number (SSN)'
          />
          <FormHelperText>{errors?.isUsingOtherSSN}</FormHelperText>

          {formValues?.isUsingOtherSSN ? (
            <>
              <Box
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography className={Style.label}>
                  Additional social security numbers:
                </Typography>

                {/* SSN */}
                {formValues?.otherSocialSecurityNumbers?.map((form, index) => (
                  <ProfileBox
                    errors={errors}
                    key={index}
                    handleRemove={() => {
                      handleRemove(index)
                    }}
                    index={index}
                    formValues={form}
                    changeHandler={(value: string) => {
                      handleValuesChange(index, value)
                    }}
                  />
                ))}

                <Grid xs={12} className={Style.cardAddInstance}>
                  <Box className='addNames'>
                    <Typography
                      className={Style.addNamesHeading}
                      variant='body1'
                    >
                      Additional Social Security Number (SSN)
                    </Typography>
                    <Typography className={Style.addNamesText} variant='body1'>
                      For comprehensive representation of applicant's situation,
                      it is important that all SSNs are included.
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      onClick={addProfileBox}
                      className={Style.addNamesButton}
                      variant='contained'
                    >
                      Add Social Security Number
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </>
          ) : (
            <></>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                handleEditToggle()
              }}
              // sx={{ visibility: 'hidden' }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
              // onClick={handleNext}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default IdentificationNumbers
