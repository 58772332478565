import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { Key, useEffect, useState } from 'react'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { AddButton, ApplicationIntroBox, StarText } from 'src/Components/Fields'
import { addMedicalTest } from 'src/Redux/Slices/ApplicationSlices/MedicalDetailsSlice'
import { setMedicalTest } from 'src/Redux/Slices/ApplicationStore/MedicalDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import DetailBox from './DetailsBox'
import Style from './index.module.scss'

const MedicalTests = ({
  handleEditToggle,
  chatForm,
}: {
  chatForm: any
  setDetailId?: React.Dispatch<React.SetStateAction<string>>
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  const MedicalDetailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.MedicalDetailId,
  )

  const MedicalTestState = useAppSelector(
    (state) => state?.applicationStore?.MedicalDetails?.MedicalTest,
  )

  const [formValues, setFormValues] = useState<any>({
    medicalDetailId: MedicalDetailId,
    isMedicalTestCompleted: undefined,
    medicalTest: [
      {
        medicalTestName: '',
        suffix: '',
        doctorFirstName: '',
        doctorLastName: '',
        testedBodyPart: [''],
        mostRecentDateTestPerformed: '',
        futureDateTestPerform: '',
      },
    ],
  })

  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )

  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            isMedicalTestCompleted:
              formValues?.isMedicalTestCompleted === undefined
                ? 'Boolean'
                : formValues?.isMedicalTestCompleted,
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  useEffect(() => {
    if (formValues?.isMedicalTestCompleted === false) {
      setFormValues((prevValues) => ({
        ...prevValues,
        medicalTest: [
          {
            medicalTestName: '',
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            testedBodyPart: [''],
            mostRecentDateTestPerformed: '',
            futureDateTestPerform: '',
          },
        ],
      }))
    }
  }, [formValues?.isMedicalTestCompleted])
  useEffect(() => {
    setFormValues({
      ...MedicalTestState,
      medicalDetailId: MedicalDetailId,
    })
  }, [])

  const [errors, setErrors] = useState<
    Record<string, string | Array<Record<string, string>>>
  >({
    medicalTest: [{}],
  })

  // const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target
  //   setFormValues({ ...formValues, [name]: value })
  //   // You may perform validation here and update errors state accordingly
  // }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string | Array<Record<string, string>>> = {
      medicalTest: [{}],
    }
    const currentDate = new Date().toISOString().split('T')[0]
    if (formValues?.isMedicalTestCompleted?.valueOf() === undefined) {
      newErrors.isMedicalTestCompleted = 'Please select an option'
      valid = false
    }

    if (formValues?.isMedicalTestCompleted) {
      for (let i = 0; i < formValues.medicalTest.length; i++) {
        if (!formValues.medicalTest[i].suffix.trim()) {
          newErrors.medicalTest = newErrors.medicalTest?.map(
            (error: any, index: number) => {
              if (i === index)
                return {
                  ...error,
                  suffix: 'Suffix is required',
                }
              return error
            },
          )
          valid = false
        }

        if (!formValues.medicalTest[i].doctorFirstName.trim()) {
          newErrors.medicalTest = newErrors.medicalTest?.map(
            (error: any, index: number) => {
              if (i === index)
                return { ...error, doctorFirstName: 'FirstName is required' }
              return error
            },
          )
          valid = false
        }

        if (!formValues.medicalTest[i].doctorLastName.trim()) {
          newErrors.medicalTest = newErrors.medicalTest?.map(
            (error: any, index: number) => {
              if (i === index)
                return { ...error, doctorLastName: 'LastName is required' }
              return error
            },
          )
          valid = false
        }

        if (!formValues.medicalTest[i].medicalTestName.trim()) {
          newErrors.medicalTest = newErrors.medicalTest?.map(
            (error: any, index: number) => {
              if (i === index)
                return {
                  ...error,
                  medicalTestName: 'Medical Test Name is required',
                }
              return error
            },
          )
          valid = false
        }

        if (formValues.medicalTest[i].testedBodyPart.length === 0) {
          newErrors.medicalTest = newErrors.medicalTest?.map(
            (error: any, index: number) => {
              if (i === index)
                return {
                  ...error,
                  testedBodyPart: 'Tested Body Part is required',
                }
              return error
            },
          )
          valid = false
        }

        if (!formValues.medicalTest[i].mostRecentDateTestPerformed.trim()) {
          newErrors.medicalTest = newErrors.medicalTest?.map(
            (error: any, index: number) => {
              if (i === index)
                return {
                  ...error,
                  mostRecentDateTestPerformed: 'Date is required',
                }
              return error
            },
          )
          valid = false
        }

        if (
          formValues.medicalTest[i].mostRecentDateTestPerformed > currentDate
        ) {
          newErrors.medicalTest = newErrors.medicalTest?.map(
            (error: any, index: number) => {
              if (i === index)
                return {
                  ...error,
                  mostRecentDateTestPerformed: 'Date cannot be in the future',
                }
              return error
            },
          )
          valid = false
        }

        if (!formValues.medicalTest[i].futureDateTestPerform.trim()) {
          newErrors.medicalTest = newErrors.medicalTest?.map(
            (error: any, index: number) => {
              if (i === index)
                return { ...error, futureDateTestPerform: 'Date is required' }
              return error
            },
          )
          valid = false
        }

        // if (formValues.medicalTest[i].futureDateTestPerform > currentDate) {
        //   newErrors.medicalTest = newErrors.medicalTest?.map(
        //     (error: any, index: number) => {
        //       if (i === index)
        //         return {
        //           ...error,
        //           futureDateTestPerform: 'Date cannot be in the future',
        //         }
        //       return error
        //     },
        //   )
        //   valid = false
        // }

        newErrors.medicalTest = [...newErrors.medicalTest, {}]
      }
    }
    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValuesToSubmit: typeof formValues = {}
    if (formValues.isMedicalTestCompleted === false) {
      formValuesToSubmit.medicalDetailId = formValues.medicalDetailId
      formValuesToSubmit.isMedicalTestCompleted =
        formValues.isMedicalTestCompleted
    } else {
      formValuesToSubmit.medicalDetailId = formValues?.medicalDetailId
      formValuesToSubmit.isMedicalTestCompleted =
        formValues?.isMedicalTestCompleted
      formValuesToSubmit.medicalTest = formValues?.medicalTest
    }
    // Handle form submission here, you can access formValues to submit data
    if (!validateForm()) {
      dispatch(
        showNotification({
          type: NotificationType.Error,
          message:
            'Some fields are incorrect or empty. Please review form again.',
        }),
      )
    } else {
      dispatch(addMedicalTest(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setMedicalTest(formValues))
            handleEditToggle()
          }
        },
      )
    }
  }

  const handleDetailBoxValuesChange = (
    index: number,
    data: { name: string; value: string },
  ) => {
    let newProfileForms = [...formValues.medicalTest]
    newProfileForms = newProfileForms?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [data.name]: data.value,
        }
      }
      return item
    })
    setFormValues({ ...formValues, medicalTest: newProfileForms })
  }

  const handleDetailBoxRemove = (index: number) => {
    const newProfileForms = [...formValues.medicalTest]
    newProfileForms.splice(index, 1)
    setFormValues({ ...formValues, medicalTest: newProfileForms })
  }

  const addDetailBox = () => {
    if (formValues.medicalTest?.length)
      setFormValues({
        ...formValues,
        medicalTest: [
          ...formValues.medicalTest,
          {
            medicalTestName: '',
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            testedBodyPart: [''],
            mostRecentDateTestPerformed: '',
            futureDateTestPerform: '',
          },
        ],
      })
    else
      setFormValues({
        ...formValues,
        medicalTest: [
          {
            medicalTestName: '',
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            testedBodyPart: [''],
            mostRecentDateTestPerformed: '',
            futureDateTestPerform: '',
          },
        ],
      })
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox heading="You're getting close to the finish line. Next up: medical tests and prescriptions. Let's roll!" />

          <CommonRadioBox
            error={errors?.isMedicalTestCompleted}
            title='Has applicant had any medical tests completed related to applicant‘s illnesses, injuries, or conditions?'
            commonState={formValues.isMedicalTestCompleted}
            setcommonState={(value: boolean) => {
              setFormValues({ ...formValues, isMedicalTestCompleted: value })
            }}
            YesTitle='I have had medical tests completed related to applicant‘s illnesses, injuries, or conditions'
            NoTitle='I have not had any  medical tests completed related to applicant‘s illnesses, injuries, or conditions'
          />

          {formValues?.isMedicalTestCompleted ? (
            <>
              {/* <StarText
                text={`Noted! We want to understand any medication that you are taking related to applicant‘s illness, injury, or condition. Use the dropdown to find and select the over-the-counter or prescribed medication that you are taking related to applicant‘s illness, injury, or condition. Repeat this step for each medication.`}
              /> */}

              <Box
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography className={Style.label}>
                  Got it. We want to understand any medical tests that applicant
                  has done. Use the dropdown below to find and select the
                  medical test that applicant has completed related to
                  applicant‘s illnesses, injuries, or conditions. Repeat this
                  step for each medical test applicant has completed.
                </Typography>

                {formValues?.medicalTest?.map(
                  (form, index: Key | null | undefined) => (
                    <DetailBox
                      key={index}
                      realValue={formValues}
                      handleRemove={() => {
                        handleDetailBoxRemove(index)
                      }}
                      errors={errors.medicalTest[index]}
                      index={index}
                      formValues={form}
                      changeHandler={(data: {
                        name: string
                        value: string
                      }) => {
                        handleDetailBoxValuesChange(index, data)
                      }}
                      setFormValues={setFormValues}
                    />
                  ),
                )}

                <AddButton
                  title='Provide More Information for Each Medical Test Here'
                  subTitle='For a comprehensive representation of applicant‘s situation, it is important that all medical tests be included.'
                  button='Add Medical Test'
                  onClick={addDetailBox}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                handleEditToggle()
              }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default MedicalTests
